import {Injectable} from '@angular/core';
import {
    AudioManagement,
    AudioManagementCordovaInterface,
    VolumeType,
    AudioMode,
    BatchStreamSetConfig
} from 'cordova-plugin-audio-management';
import {
    NativeAudio,
    NativeAudioEvent,
    NativeAudioEventType,
    NativeAudioCordovaInterface
} from 'cordova-plugin-nativeaudio';
import {Platform} from '@ionic/angular';
import {Vibration} from '@ionic-native/vibration/ngx';
import {Observable, Subject, filter, of, share, skipUntil, switchMap, timer} from 'rxjs';
import {getLogger} from '@hrs/logging';
import {isNumber} from 'lodash';
import {sleep} from 'src/app/utility/sleep';

export enum AudioRingType {
    BEEP = 'beep',
    RING = 'ring'
}

export enum AudioPlayType {
    ONCE = 0, // play clip 1x
    LOOP = 1, // play clip loop with set duration or infinite loop
    CUSTOM = 2 // play clip based on AudioLoopConfig settings
}

export interface AudioLoopConfig {
    repeatCount?: number, // how many times to play the ringtone: required for AudioPlayType.CUSTOM - ignored by AudioPlayType.LOOP
    interval?: number, // how long to wait between ringtone(s), in ms: required for AudioPlayType.CUSTOM - ignored by AudioPlayType.LOOP
    duration?: number //  duration of loop, in ms: optional for AudioPlayType.LOOP - ignored by AudioPlayType.CUSTOM
}

function isDoNotDisturbPermissionError(error: any): boolean {
    return (typeof error === 'string') && error.toLowerCase().includes('do not disturb');
}

@Injectable({
    providedIn: 'root',
})
export class AudioService {
    private readonly logger = getLogger('AudioService');
    static DISABLE_ALERTS: string = 'disable';
    static ENABLE_ALERTS: string = 'enable';
    static MAX_VOLUME: number = 100;
    static audioFile = {
        beep: {url: 'assets/audio/beep_beep_sms.mp3', length: 2000},
        ring: {url: 'assets/audio/classic_phone.mp3', length: 26000}
    };

    private audioReminderState: string = AudioService.ENABLE_ALERTS;
    private audioLoopInterval;
    private defaultVolume: number;
    private volumeLevel: number;
    private volumeTypes: VolumeType[];
    private stashedVolume: number | undefined;

    private readonly audioMgmt: AudioManagementCordovaInterface = AudioManagement;

    private readonly nativeAudio: NativeAudioCordovaInterface = NativeAudio;
    private readonly eventsSubject = new Subject<NativeAudioEvent>();
    private readonly nativeAudioEventProxy: (ev: NativeAudioEvent) => void;
    public readonly events$: Observable<NativeAudioEvent>;

    public volumeToggleDelayMs: number = 100;

    /**
     * When true, will lower volume down to 0 first, and then raise to the overridden volume value.
     * When false, `volumeToggleDelayMs` will be ignored.
     */
    public toggleVolumeOnOverride: boolean = true;

    /**
     * When true, keeps the first stashed volume and ignores subsequent overrides without undo, e.g.:
     * volume = 75
     * overrideVolume(50)
     * overrideVolume(45)
     * undoVolumeOverride() -> will restore to 75 and 45 will be ignored
     */
    public keepInitialStashedVolume: boolean = true;

    constructor(
        private platform: Platform,
        private vibration: Vibration
    ) {
        this.events$ = this.eventsSubject.asObservable().pipe(share());
        this.nativeAudioEventProxy = this.onNativeAudioEvent.bind(this);
        this.volumeTypes = [
            VolumeType.SYSTEM,
            VolumeType.MUSIC,
            VolumeType.RING,
            VolumeType.NOTIFICATION,
            VolumeType.VOICE_CALL
        ];
    }

    public get volumeTypeCount(): number {
        return this.volumeTypes.length;
    }

    public get audioReminders(): string {
        return this.audioReminderState;
    }

    public set audioReminders(reminder: string) {
        this.audioReminderState = reminder;
    }

    public get volume(): number {
        return !isNaN(this.volumeLevel) ? this.volumeLevel : this.defaultVolume;
    }

    public set volume(volume: number) {
        this.volumeLevel = volume;
    }

    private get hasStashedVolume(): boolean {
        return isNumber(this.stashedVolume);
    }

    public filterLoopEnd(audioId: string, duration: number): Observable<NativeAudioEvent> {
        // NativeAudio is not supported on iOS, so the event utilized to signal the end of a duration is not available on iOS
        // using a timer instead;
        if (this.platform.is('ios')) {
            return timer(duration).pipe(
                switchMap(() => {
                    const event: NativeAudioEvent = {
                        type: NativeAudioEventType.PLAYBACK_LOOP_ENDED,
                        data: {}
                    };
                    return of(event);
                })
            );
        } else {
            return this.events$.pipe(
                // ignore events until we are 10 seconds away from the actual duration
                // (prevents potential misfires earlier than the designated duration)
                skipUntil(timer(Math.max(0, duration - (10 * 1000)))),
                filter((ev) => ev?.type === NativeAudioEventType.PLAYBACK_LOOP_ENDED && ev?.data?.audioId === audioId)
            );
        }
    }

    /**
     * called from AppComponent() to set defaults for the service
     */
    public initialize(): void {
        this.logger.debug(`initialize()`);
        this.setDefaultVolume();
        try {
            this.nativeAudio.setSharedEventDelegate(this.nativeAudioEventProxy);
        } catch (e) {
            // Plugin is not reachable for whatever reason or cordova window object does not exist (yet)
            this.logger.error(`failed to set nativeaudio plugin event delegate! -> ${e}`, e);
        }
    }

    /**
     * Called from User::logout() for tablet to reset volume on the device to 100%, setting
     * the initial volume for the next episode of care to its maximum level.
     */
    public resetDefaults(): void {
        this.logger.debug(`resetDefaults()`);
        // reset to max volume & enable notifications
        this.defaultVolume = AudioService.MAX_VOLUME;
        this.audioReminderState = AudioService.ENABLE_ALERTS;
        // save max volume to all volume types on device
        this.volumeChange(this.defaultVolume);
    }

    /**
     * setDefaultVolume() will default the volume in the application to whatever the system level volume is on the device.
     * This level and the audioReminderState will get over-written from TabletSettingsService::getTabletSettings() which retrieves
     * the values stored on the patient profile. getTabletSettings() is called from HomePage on initialLoad(), pullToRefresh() and
     * on platform resume.
     * The only time the default level comes into play is if the tablet is offline on startup for an existing patient or for a
     * new episodes of care where the volume has not explicitly been set on the patient profile.
     */
    private setDefaultVolume(): void {
        this.logger.debug(`setDefaultVolume()`);
        this.audioMgmt.getVolume(VolumeType.SYSTEM).then((resp) => {
            this.logger.debug(`setDefaultVolume() got system volume response`, resp);
            this.defaultVolume = resp.scaledVolume;
        }).catch((err) => {
            this.defaultVolume = AudioService.MAX_VOLUME;
            this.logger.phic.error('setDefaultVolume() -> getVolume() error: ', err);
        });
    }

    public notificationsAreSilent(): boolean {
        return this.audioReminderState === AudioService.DISABLE_ALERTS;
    }

    public vibrate(pattern?: number[]): void {
        this.logger.debug(`vibrate()`, {pattern});
        // if an array of numbers are passed, the plugin will alternate from vibrating to waiting.
        const vibrationSetting = pattern || 1000;
        this.vibration.vibrate(vibrationSetting);
    }

    /**
     * startPlayback - will start playing the specified, preloaded audio clip
     * @param ringType - specify one of the supported AudioRingType values
     * @param playType - specify one of the support AudioPlayType values
     * @param loopConfig - if playType is AudioPlayType.CUSTOM, loopConfig is required to set up loop parameters
     *
     * loopConfig.repeatCount (the # of times to play the audio clip)
     *
     * loopConfig.interval (the length of time before loop repeats, in ms; length must be > the length of the audio clip)
     */
    public startPlayback(ringType: AudioRingType, playType: AudioPlayType, loopConfig?: AudioLoopConfig): void {
        this.logger.debug(`startPlayback()`, {ringType, playType, loopConfig});
        this.audioMgmt.getAudioMode().then((value) => {
            // AudioMode.NORMAL === audible
            // AudioMode.VIBRATE === vibrate
            // AudioModel.SILENT === do not disturb or volume level set to 0 on device
            this.logger.debug(`loaded current audio mode = ${value.audioMode}`);
            if (value.audioMode === AudioMode.NORMAL) { // NORMAL === audible ring
                this.logger.debug(`startPlayback() audioMode = NORMAL`);
                // audio clips need to be loaded prior to playback; 'already exists' errors are ignored
                let playClip: boolean = true;
                this.logger.debug(`startPlayback() -> NativeAudio.preloadComplex`);
                this.nativeAudio.preloadComplex(ringType, AudioService.audioFile[ringType].url, 1.0, 1, 0)
                    .catch((err) => {
                        if (!err.includes('already exists')) {
                            this.logger.phic.error('preloadComplex() error', err);
                            playClip = false;
                        }
                    })
                    .finally(() => {
                        this.logger.debug(`startPlayback() -> NativeAudio.preloadComplex -> finally`);
                        if (playClip) {
                            this.logger.debug(`startPlayback() -> playClip`, {playType});
                            if (playType === AudioPlayType.ONCE) {
                                this.nativeAudio.play(ringType)
                                    .catch((err) => {
                                        this.logger.phic.error('startPlayback() -> NativeAudio.play error', err);
                                    });
                            } else if (playType === AudioPlayType.LOOP) {
                                this.nativeAudio.loop(ringType, loopConfig?.duration ? loopConfig.duration : -1)
                                    .catch((err) => {
                                        this.logger.phic.error('startPlayback() -> NativeAudio.loop error', err);
                                    });
                            } else if (playType === AudioPlayType.CUSTOM) {
                                this.logger.debug(`startPlayback() -> playClip -> playType = CUSTOM`);
                                // use loopConfig to create a custom finite loop
                                const clipLength = AudioService.audioFile[ringType].length;

                                if (loopConfig && loopConfig.repeatCount >= 1 && loopConfig.interval > clipLength) {
                                    this.logger.debug(`startPlayback() use loopConfig to create a custom finite loop`);
                                    const playTimes = loopConfig.repeatCount;

                                    // play the audio clip 1x
                                    let count = 1;
                                    this.nativeAudio.play(ringType)
                                        .catch((err) => {
                                            this.logger.phic.error('startPlayback() -> NativeAudio.play CUSTOM error', err);
                                        });

                                    // loop on interval until clip is played playTimes number of times
                                    this.audioLoopInterval = setInterval(() => {
                                        this.logger.debug(`startPlayback() -> audioLoopInterval`, {count, playTimes});
                                        count++;
                                        if (count > playTimes) {
                                            this.clearAudioLoopInterval();
                                        } else {
                                            this.nativeAudio.play(ringType)
                                                .catch((err) => {
                                                    this.logger.phic.error('startPlayback() audioLoopInterval error', err);
                                                });
                                        }
                                    }, loopConfig.interval);
                                } else {
                                    this.logger.phic.debug('Failed to provide valid custom loop configuration to AudioService.startPlayback()');
                                }
                            }
                        }
                    });
            } else {
                this.logger.warn(`skipping audio playback for non-normal audio mode`);
            }
        }).catch((err) => {
            this.logger.phic.error('getAudioMode() error: ', err);
        });
    }

    private onNativeAudioEvent(ev: NativeAudioEvent): void {
        this.eventsSubject.next(ev);
        if (ev?.type === NativeAudioEventType.SCREEN_TURNED_OFF) {
            this.clearAudioLoopInterval();
        }
    }

    /**
     * stopPlayback - will stop playing the specified audio clip
     * this should only be called if clip was started with AudioPlayType.LOOP or AudioPlayType.CUSTOM
     * @param ringType - specify one of the supported AudioRingType values
     */
    public endPlayback(ringType): void {
        this.logger.debug(`endPlayback()`, {ringType});
        this.nativeAudio.stop(ringType)
            .catch((err) => {
                this.logger.phic.error('endPlayback() error', err);
            });

        this.clearAudioLoopInterval();
    }

    private clearAudioLoopInterval(): void {
        if (this.audioLoopInterval) {
            clearInterval(this.audioLoopInterval);
            this.audioLoopInterval = undefined;
        }
    }

    public overrideVolumeWithMax(): Promise<void> {
        this.logger.debug(`overrideVolumeWithMax()`);
        return this.overrideVolume(AudioService.MAX_VOLUME);
    }

    public async overrideVolume(volume: number): Promise<void> {
        this.logger.debug(`overrideVolume() volume = ${volume}`);
        const hasStashedVolume = this.hasStashedVolume;
        const overwriteStashedVolume = !this.keepInitialStashedVolume || !hasStashedVolume;

        if (hasStashedVolume && !this.keepInitialStashedVolume) {
            // If `stashedVolume` is set to a value, then `overrideVolume` was called twice without
            // calling `undoVolumeOverride` in-between the two calls.
            // This will effectively make the first stash value disappear into a black hole.
            this.logger.warn(`overriding previously stashed volume without restore -> ${this.stashedVolume}`);
        }

        if (overwriteStashedVolume) {
            this.stashedVolume = this.volume;
            this.logger.debug(`overwriteStashedVolume -> ${this.stashedVolume}`);
        }

        if (this.toggleVolumeOnOverride && this.stashedVolume > 0) {
            this.logger.debug(`toggling off volume before applying override`);
            await this.volumeChange(0);
            await sleep(this.volumeToggleDelayMs);
        }

        this.logger.debug(`applying volume override -> ${volume}`);
        await this.volumeChange(volume);
    }

    public async undoVolumeOverride(): Promise<boolean> {
        const currentVolume = this.volume;
        const stashedVolume = this.stashedVolume;
        const hasStashedVolume = this.hasStashedVolume;
        this.logger.debug(`undoVolumeOverride()` +
            ` currentVolume = ${currentVolume}` +
            `, stashedVolume = ${stashedVolume}` +
            `, hasStashedVolume = ${hasStashedVolume}`);

        if (hasStashedVolume) {
            this.stashedVolume = undefined;
            await this.volumeChange(stashedVolume);
        }

        return hasStashedVolume;
    }

    /**
     * Similar to volumeChange(), but accounts for if the volume is currently overidden.
     * This should be preferred over volumeChange() when the change does not
     * need to happen immediately (e.g. when server volume setting gets updated).
     */
    public async requestVolumeChange(volume: number): Promise<void> {
        const hasStashedVolume = this.hasStashedVolume;
        this.logger.debug(`requestVolumeChange() volume = ${volume}, hasStashedVolume = ${hasStashedVolume}`);

        if (!hasStashedVolume) {
            await this.volumeChange(volume);
            return;
        }

        if (this.stashedVolume === volume) {
            this.logger.debug(`stashed volume and requested volume match (${volume}), no further action needed`);
            return;
        }

        // Requested volume will be applied when the override is undone,
        // rather than immediately.
        // See DEV-15289 for more info.
        this.logger.warn(`overwriting stashed volume ${this.stashedVolume} with requested volume ${volume}`);
        this.stashedVolume = volume;
    }

    /**
     * Sets the volume at the typescript and plugin level immediately, and
     * disregards any overridden state.
     */
    public async volumeChange(volume: number): Promise<boolean> {
        this.logger.debug(`volumeChange() volume = ${volume}`);
        let success = true;

        const config: BatchStreamSetConfig = {
            streams: this.volumeTypes.map((type) => {
                let targetVolume = volume;
                if (this.notificationsAreSilent() && type === VolumeType.NOTIFICATION) {
                    targetVolume = 0;
                }
                return {
                    streamType: type,
                    volume: targetVolume
                };
            })
        };

        try {
            await this.audioMgmt.setVolumeBatch(config);
            this.logger.trace(`volumeChange() -> audioMgmt.setVolumeBatch() success! for volume ${volume}`);
        } catch (error) {
            this.logger.error(`volumeChange() error!`, error);
            let isDndPermissionError: boolean = true;

            if (Array.isArray(error?.errors)) {
                for (const streamError of error.errors) {
                    // If we find a non do-not-disturb permission error, elevate the error to the caller
                    if (!isDoNotDisturbPermissionError(streamError?.errorMessage)) {
                        isDndPermissionError = false;
                        break;
                    }
                }
            } else {
                isDndPermissionError = isDoNotDisturbPermissionError(error);
            }

            // Ignore "Do Not Disturb" permission errors since we can't do anything about them.
            // See DEV-15287 for more info.
            if (!isDndPermissionError) {
                success = false;
            }
        }

        this.logger.debug(`volumeChange() volume requests done! success = ${success}`);

        // For user driven volume change events user will see alert when PCM does not have Do Not Disturb permission
        // If volume change is a result of tablet-settings fetch then no need to alert the user which may confuse them
        if (success) {
            this.volume = volume;
        }

        return success;
    }
}
