import * as moment from 'moment';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, Optional} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {filter, finalize, first, Subject, Subscription, takeWhile, tap, timer} from 'rxjs';
import {BuildUtility} from '@hrs/utility';
import {ModalService} from '@hrs/providers';
import {
    AudioService,
    BloodPressureDeviceService,
    CareplanChangeService,
    DeviceService,
    EnvironmentService,
    EventService,
    GlucoseDeviceService,
    OverlayService,
    PulseOxDeviceService,
    TaskService,
    TaskTrackingService,
    TemperatureDeviceService,
    TextToSpeechService,
    WeightDeviceService,
} from '@patient/providers';
import {getLogger} from '@hrs/logging';
import {EncryptionService} from '../services/encryption/encryption.service';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {FooterClickDetails, FooterEvent, MainPageTypes} from '../main/main-config';
import {MetricEntryTypeEnum} from '../services/device/metric-entry-type.enum';
import {DeviceInstructionsUsagePage} from '../devices/instructions-usage/device-instructions-usage.page';
import {GenericMetricSchema, InputProperties} from './generic-metric-schema';
import {Task, TaskType} from '../services/tasks';
import {TaskMetaData} from '../services/tasks/task-metadata.interface';
import {DeviceProfileService} from '../hrs-tablet/device-profile/device-profile.service';
import {OverlayComponent, OverlayRef} from '../hrs-overlay';
import {PeripheralTypes} from '../devices/generic-device/types/template-devices';
import {ListDetail, ListLegend} from '@hrsui/core/dist/types/components/list/list.interface';
import {
    ActivityFacet,
    BloodPressureFacet,
    GenericMetricHistoricalData,
    GlucoseFacet,
    HistoricalData,
    OfflineFacet,
    OfflineHistoricalData,
    PulseOxFacet,
    TemperatureFacet,
    WeightFacet
} from '../services/historical-data/historical-data.interface';
import {MetricReadingDetail} from '@hrsui/core/dist/types/components/metrics/metrics.interface';
import {VideoPage} from '../communication/video/video.page';
import {VoicePage} from '../communication/voice/voice.page';
import {ILocalNotification, LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {HistoricalDataService} from '../services/historical-data/historical-data.service';
import {CareplanChangeAction} from '../enums';
import {
    HRSFirebaseAnalytics,
    HRSFirebaseEvents,
    HRSFirebaseParams
} from '../services/analytics/firebaseanalytics.service';
import {throttleClick} from '../utility/throttle-click';
import {flatten} from 'lodash';

@Component({
    selector: 'app-generic-metric',
    templateUrl: './generic-metric.page.html',
    styleUrls: ['./generic-metric.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericMetricPage {
    private readonly logger = getLogger('GenericMetricPage');

    private autosubmitCountdown: number = 12;
    private autosubmitTimeout: Subscription;
    private currentlyReading: string;
    private entryPoint: string;
    private entrySubscription: Subscription;
    private gettingHistoricalData: boolean = false;
    private historicalData: GenericMetricHistoricalData;
    private manualMetricEntry: boolean = false;
    private peripheral: any;
    private peripheralType: PeripheralTypes;
    private queryParamsSubscription: Subscription;
    private taskRemovedSubscription: Subscription;
    public deviceDetails: any;
    private task: Task;

    public readonly autosubmitText: Subject<string> = new Subject<string>();
    public content: ContentDetail[];
    public currentDay: string;
    public formGroup: UntypedFormGroup;
    public historicalDataEnabled: boolean = false;
    public hasPairedDevice: boolean = false;
    public imageUrl: string;
    public isHRSTablet: boolean = BuildUtility.isHRSTab();
    public isModal: boolean = true;
    public isReading: boolean = false;
    public listConfig: ListDetail[];
    public listInitialLoad: boolean = true;
    public placeholderText: string;
    public saving: boolean;
    public schema: Array<InputProperties>;
    public shouldAutosubmit: boolean = false;
    private toast: OverlayRef;
    private stopAudioButtonAnimation: Subscription;

    // for the metric entry card on tablet with more than 1 input field
    // ensure the submit button is in view when the last metric input field gets focus
    @HostListener('hrsFocus', ['$event'])
    handleInput({detail: value}) {
        const numInputs: number = this.schema ? this.schema.length : -1;
        if (this.isHRSTablet && !this.isModal && numInputs > 1 && value?.itemId) {
            const inputIndex = this.schema?.findIndex((input) => {
                return input.id === value.itemId;
            });
            // for the last input in the metric entry card
            if (inputIndex === numInputs - 1) {
                const metricInputCard: HTMLElement = document.querySelector('.device-unpaired-card--container');
                const submitButton: HTMLElement = document.querySelector('.device-unpaired-card--container #submitButton');
                const buttonPosition = submitButton?.getBoundingClientRect();
                // if the submit button is not fully in view
                if (!(buttonPosition?.top >= 0 && buttonPosition?.bottom <= window.innerHeight)) {
                    // scroll the bottom of the metric input card to the bottom of the view window, putting input & submit button in view
                    setTimeout(() => {
                        metricInputCard?.scrollIntoView({behavior: 'smooth', block: 'end'});
                    }, 100);
                }
            }
        }
    }

    constructor(
        @Optional() private deviceProfile: DeviceProfileService,
        @Optional() private overlayRef: OverlayRef,
        private audio: AudioService,
        private bpDeviceService: BloodPressureDeviceService,
        private changeRef: ChangeDetectorRef,
        private deviceService: DeviceService,
        private encryptionService: EncryptionService,
        private environmentService: EnvironmentService,
        private formBuilder: UntypedFormBuilder,
        private glucoseDeviceService: GlucoseDeviceService,
        private historicalDataService: HistoricalDataService,
        private modalService: ModalService,
        private navCtrl: NavController,
        private overlay: OverlayService,
        private platform: Platform,
        private pulseoxDeviceService: PulseOxDeviceService,
        private route: ActivatedRoute,
        private taskService: TaskService,
        private taskTrackingService: TaskTrackingService,
        private tempDeviceService: TemperatureDeviceService,
        private tts: TextToSpeechService,
        private translate: TranslateService,
        private weightDeviceService: WeightDeviceService,
        private zone: NgZone,
        private localNotifications: LocalNotifications,
        private careplanChangeService: CareplanChangeService,
        private fbAnalytics: HRSFirebaseAnalytics,
        private eventService: EventService
    ) {}

    public get canSave(): boolean {
        return this.formGroup.valid && !this.saving;
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    ngOnInit() {
        this.logger.debug(`ngOnInit() entryPoint = ${this.entryPoint}`);
        if (this.entryPoint) {
            // we're coming here from submission of BT data OR GenericMetricPage::launchGenericMetricModal()
            this.isModal = true;
            if (this.isNativePlatform) {
                this.logger.debug(`unsubscribePollingForBluetoothDevices`);
                this.deviceService.unsubscribePollingForBluetoothDevices();
            }
            this.modalService.setModalStatus('GenericMetricPage', true);
            // Need to know if it is coming from the manual metric in instructions or not
            this.manualMetricEntry = this.entryPoint && this.entryPoint === MetricEntryTypeEnum.ManualEntry;
            this.logger.debug('Is Manual entry ' + this.manualMetricEntry + ' peripheral ' + this.peripheral);
            this.shouldAutosubmit = !this.manualMetricEntry;
            this.generateMetricFields();
        } else {
            this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
                this.logger.debug(`route.queryParams`, params);
                if (params && params.taskType) {
                    this.isModal = false;
                    this.task = this.taskService.getTask(params.taskType);
                    this.deviceDetails = params.deviceDetails;
                    this.hasPairedDevice = !!this.deviceDetails;
                    if (this.hasPairedDevice && this.task) this.initDeviceInfo();
                    // generate metric entry input form controls - will be needed
                    // for non-paired device manual entry card
                    this.manualMetricEntry = true;
                    this.shouldAutosubmit = false;
                    this.generateMetricFields();
                    this.historicalDataEnabled = this.environmentService.hasHistoricalData();
                    if (this.historicalDataEnabled) {
                        this.logger.debug(`route.queryParams getting historical data`);
                        this.getHistoricalData();
                    }
                }
            });
            this.currentDay = moment().format('MM/DD/YYYY');
        }

        this.taskRemovedSubscription = this.careplanChangeService.careplanState$.pipe(
            tap((careplanState) => this.logger.debug(`received careplan state update ->`, careplanState, this.task)),
            filter((careplanState) => this.task && careplanState[this.task.type] === CareplanChangeAction.REMOVED),
            first()
        ).subscribe(() => {
            this.logger.info(`returning to home page after module remove!`);
            this.returnToHomePage();
        });

        this.stopAudioButtonAnimation = this.tts.ttsForceStopped$.subscribe(async () => {
            await this.stopReading();
        });
    }

    ngAfterViewInit() {
        this.logger.debug(`ngAfterViewInit()`);
        // set initial UI state for the Submit button
        if (this.isModal || !this.hasPairedDevice) {
            this.updateUIState();
        }
    }

    ngOnDestroy() {
        this.logger.debug(`ngOnDestroy()`);
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
            this.queryParamsSubscription = null;
        }
        if (this.entrySubscription) {
            this.entrySubscription.unsubscribe();
            this.entrySubscription = null;
        }
        if (this.taskRemovedSubscription) {
            this.taskRemovedSubscription.unsubscribe();
            this.taskRemovedSubscription = null;
        }
        if (this.isModal) {
            this.clearAutosubmitTimeout();
            this.modalService.setModalStatus('GenericMetricPage', false);
            this.modalService.setModalStatus('GenericMetricPageOverlay', false);
            this.deviceService.subscribePollingForBluetoothDevices();
        }
        if (this.isNativePlatform && this.isReading) {
            this.logger.debug(`stopping TTS...`);
            this.tts.stop();
        }
        if (this.stopAudioButtonAnimation) {
            this.stopAudioButtonAnimation.unsubscribe();
            this.stopAudioButtonAnimation = null;
        }
    }

    private generateMetricFields(): void {
        this.logger.debug(`generateMetricFields()`);
        this.placeholderText = this.translate.instant('GENERIC_METRIC_FIELD_PLACEHOLDER');
        // Dynamically generate the form view based on the schema for this metric
        if (GenericMetricSchema[this.task.type]) {
            this.schema = [...GenericMetricSchema[this.task.type]];
            this.logger.debug(`built schema`, this.schema);
            this.formGroup = this.buildForm();
            if (this.shouldAutosubmit) {
                this.logger.debug(`shouldAutosubmit`);
                this.checkBluetoothReading();
                this.showLoading();
            } else if (this.entryPoint === MetricEntryTypeEnum.BluetoothEntry && this.peripheral && this.task) { // it was an expected BT entry but could not populate the vital data fir display
                this.logger.phic.error('Could not show vitals on modal for ', this.peripheral);
            }
        }
    }

    private buildForm(): UntypedFormGroup {
        this.logger.debug(`buildForm()`);
        if (this.task.type === TaskType.Weight && !this.allowWeightEntry()) return;
        const formGroup = this.formBuilder.group({});

        for (let control of this.schema) {
            const newFormControl = new UntypedFormControl(control.value ? control.value : null);
            let validators: Array<ValidatorFn> = [];

            if (control.validators && control.validators.required) validators.push(Validators.required);

            this.setUIText(control);

            if (control.type === 'INPUT'){
                this.setInputOptions(control, validators);
                // JIR-10462 [PCM/T] "Save" button disabled on the auto-submit modal for "Temperature"
                // Only setting validators for Input fields as there is no select field in new UI
                if (validators.length) newFormControl.setValidators(validators);
            }
            formGroup.addControl(control.id, newFormControl);
            newFormControl.updateValueAndValidity(); // validate after adding to group so group gets proper validation state
        }

        if (this.task.type !== TaskType.Weight && this.task.type !== TaskType.Temperature && !this.shouldUseMillimoles()) {
            formGroup.setValidators([this.hasDecimalValidator]);
        }
        return formGroup;
    }

    private setUIText(control: InputProperties): void {
        this.logger.debug(`setUIText()`);
        let label: string = control.label;
        let placeholder: string = control.placeholder;
        let qaLabel: string = this.getQAName(control);

        if (this.shouldUseMillimoles()) {
            placeholder = 'mmol/L';
        }

        // Translate or fall back to the supplied values
        if (label) {
            let key = 'GENERIC_METRIC_FIELD_LABEL_' + control.label.toUpperCase();
            let translation = this.translate.instant(key);
            if (translation && translation !== key) {
                label = translation;
            }
            label += ':';
        }

        if (placeholder) {
            let key = 'GENERIC_METRIC_FIELD_PLACEHOLDER_' + control['placeholder'].toUpperCase();
            let translation = this.translate.instant(key);
            if (translation && translation !== key) {
                placeholder = translation;
            }
        }

        control.uiText = {
            label: label,
            placeholder: placeholder,
            qaLabel: qaLabel
        };

        this.logger.debug(`control.uiText`, control.uiText);
    }

    private getQAName(control: InputProperties): string {
        if (control && control.label) {
            return control.label
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .replace(/\s+/g, '_')
                .toLowerCase();
        }
    }

    private setInputOptions(control: InputProperties, validators: Array<ValidatorFn>): void {
        this.logger.debug(`setInputOptions()`);
        this.handleInputFieldsIfNonWesternNumberChars(control, this.translate.currentLang);

        if (control.validators) {
            const inputValidators = control.validators;
            if (!isNaN(inputValidators.max) && inputValidators.max !== null) validators.push(Validators.max(inputValidators.max));
            if (!isNaN(inputValidators.min) && inputValidators.min !== null) validators.push(Validators.min(inputValidators.min));
        }
    }

    /**
     * HTML5 input with type of number doesn't allow non-western number characters,
     * so in order to support certain languages that have non-western number characters
     * like hindi and arabic, we change the input type to text for those languages.
     */
    handleInputFieldsIfNonWesternNumberChars(control: InputProperties, lang: string): void {
        this.logger.debug(`handleInputFieldsIfNonWesternNumberChars()`);
        if (lang && (lang === 'ar' || lang === 'hi')) {
            control.inputType = 'text';
        }
    }

    /**
     * Custom validator to prevent passing of decimal in wrong metrics
     * @param control
     */
    public hasDecimalValidator(control: AbstractControl): ValidationErrors | null {
        if (control.root && control.value) {
            // eslint-disable-next-line guard-for-in
            for (let value in control.value) {
                if (/\./.test(control.value[value])) return {hasDecimal: true};
            }
        }
        return null;
    }

    /**
     * Unsubscribe to bluetooth metric notifications
     * Set Timeout before Metric Readings Autosubmit.
     * This is so users have a chance to look at readings and cancel.
     */
    private setAutosubmitTimeout(): void {
        this.logger.debug(`setAutosubmitTimeout()`);
        if (this.shouldAutosubmit) {
            let UIText: string = '';
            this.autosubmitCountdown = 12;
            UIText = this.translate.instant('GENERIC_METRIC.CANCEL_INSTRUCTIONS', {
                countdown: this.autosubmitCountdown
            });
            this.autosubmitText.next(UIText);

            // For PCMT and android timers were causing issues while submitting BT readings taken during an active maximised video call.
            // For android when a video call (zoom/vonage) is active and maximised, main app screen is paused and goes in background.
            // When user takes a BT reading, are metric modal is not visible, we directly send the data to CC server without starting the timers.
            if (this.shouldSubmitMetricWithoutTimer()) {
                this.logger.debug('Screen paused: Submit data to CC directly');
                // submit the metric directly without timer
                this.saveWhenScreenPaused();
                this.deviceService.disconnectBluetoothSerial();
            } else {
                // the code to disable the input fields used to live in ionViewDidEnter(), which fired for Ionic Modals (handled by ModalController in the Ionic framework)
                // when we switched to utilizing HrsModal through OverlayService.openModal(...) we lost Ionic lifecycle hooks
                // at this point in the autoSubmit flow, we can rely on the hrs-input elements being present in the DOM
                const inputs = document.querySelectorAll('.generic_metric--wrapper hrs-input');
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].setAttribute('disabled', 'true');
                }
                if (this.isHRSTablet && !this.audio.notificationsAreSilent()) {
                    this.readContent();
                }
                this.logger.debug('Screen active: Start autosubmit to submit data to CC');
                this.autosubmitTimeout = timer(1000, 1000)
                    .pipe(takeWhile(() => this.autosubmitCountdown > 0))
                    .subscribe(() => {
                        UIText = this.translate.instant('GENERIC_METRIC.CANCEL_INSTRUCTIONS', {
                            countdown: --this.autosubmitCountdown
                        });
                        this.zone.run(() => {
                            this.autosubmitText.next(UIText);
                        });
                        if (this.autosubmitCountdown === 0) {
                            this.save();
                            this.deviceService.disconnectBluetoothSerial();
                        }
                    });
            }
        }
    }

    private shouldSubmitMetricWithoutTimer(): boolean {
        return (this.platform.is('android') && this.eventService.screenPaused);
    }

    private clearAutosubmitTimeout(): void {
        this.logger.debug(`clearAutosubmitTimeout()`);
        if (this.autosubmitTimeout) {
            this.logger.debug(`clearing timeout...`);
            this.autosubmitTimeout.unsubscribe();
            this.autosubmitTimeout = null;
            this.autosubmitCountdown = 0;
        }
    }

    private checkBluetoothReading(): void {
        this.logger.debug(`checkBluetoothReading()`);
        // Need a timeout to allow for the peripheral to update the metric
        setTimeout(() => {
            this.logger.debug(`checkBluetoothReading() timeout`);
            Object.keys(this.formGroup.controls).forEach((key) => {
                const control = this.formGroup.controls[key];
                control.setValue(this.getBluetoothReadings(key));
                control.updateValueAndValidity();
            });
            this.hideLoading();
            this.setAutosubmitTimeout();
        }, 2000);
    }

    // Updating values by matching to id from the JSON; value is from Device Service update
    // This allows for the same form to be used and it can be manually updated as well if needed
    private getBluetoothReadings(controlId: string): any {
        this.logger.debug(`getBluetoothReadings()`);
        // Removed extras from here for now, will need to add in as devices are added
        if (controlId === 'hr') {
            return this.pulseoxDeviceService.heartRate;
        } else if (controlId === 'bloodsugar') {
            return this.glucoseDeviceService.glucose;
        } else if (controlId === 'spo2') {
            return this.pulseoxDeviceService.spo2;
        } else if (controlId === 'systolic') {
            return this.bpDeviceService.systolic;
        } else if (controlId === 'diastolic') {
            return this.bpDeviceService.diastolic;
        } else if (controlId === 'heartrate') {
            return this.bpDeviceService.heartRate;
        } else if (controlId === 'temperature') {
            return this.tempDeviceService.temperature;
        } else if (controlId === 'weight') {
            return this.weightDeviceService.weight;
        }
    }

    /**
     * We do not want UI to be altered when screen is paused. This method submits the metric and shows a
     * local notification to user whether metric was submitted successfully or not.
     */
    public async saveWhenScreenPaused(): Promise<void> {
        this.logger.debug(`saveWhenScreenPaused()`);
        let peripheralDisplayName = '';
        let peripheralRssi = '';
        if (this.peripheral) {
            peripheralDisplayName = this.fbAnalytics.getDisplayName(this.peripheral, this.task.type);
            peripheralRssi = this.peripheral.rssi;
        }
        if (this.autosubmitTimeout) this.clearAutosubmitTimeout();
        if (this.isNativePlatform) this.deviceService.subscribePollingForBluetoothDevices();

        const data = {
            ...this.getDataToSave(), // get the data recorded that we want to save
        };
        const metadata: TaskMetaData = {
            isBluetoothMetric: !this.manualMetricEntry, // set 'isBluetoothMetric' to false when a manual entry and true when a bluetooth entry
            recordedDate: moment().locale('en').format(), // get the date this metric was recorded in case the user is offline when they upload. // see note on language locale
        };
        this.logger.info('Submitting metric to CC for task - ' + this.task.type);
        this.taskService.submitTask(this.task, data, metadata).subscribe(
            {
                next: (res) => {
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.VITAL_SUBMISSION,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: this.task.type,
                            [HRSFirebaseParams.STATE]: 'SUCCESS', [HRSFirebaseParams.RSSI]: peripheralRssi});
                    // success scenario - task saved successfully
                    this.returnToHomePage();
                    if (this.isHRSTablet) this.deviceProfile.getUploadFileSize();
                    this.taskTrackingService.submitTracking('submit-metric', 'success submitting ' + this.task.type);
                    this.logger.info('Submitting metric/task success ');
                    this.showMetricSubmitNotification(this.translate.instant('GENERIC_METRIC_SUBMIT_SUCCESS'), this.getMetricText());
                },
                error: (error) => {
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.VITAL_SUBMISSION,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: this.task.type,
                            [HRSFirebaseParams.STATE]: 'FAILURE', [HRSFirebaseParams.RSSI]: peripheralRssi});
                    // failure scenario - task didn't save
                    this.handleSaveError(error);
                    this.showMetricSubmitNotification(this.translate.instant('GENERIC_METRIC_SUBMIT_FAILURE'), this.translate.instant('GENERIC_METRIC_SUBMIT_FAILURE_TEXT'));
                    if (this.isHRSTablet) this.deviceProfile.getUploadFileSize(data);
                    this.logger.info('Submitting metric/task failed ' + error);
                    this.taskTrackingService.submitTracking('submit-metric', 'failed submitting ' + this.task.type);
                }
            }
        );
    }

    private showMetricSubmitNotification(textTitle: string, textMsg: string) {
        this.logger.debug(`showMetricSubmitNotification()`);
        const date = new Date(Date.now() + 2000);
        let notification: ILocalNotification = {
            id: 1,
            title: textTitle,
            text: textMsg,
            trigger: {
                at: date
            },
            foreground: true,
            silent: false,
            priority: 2
        };
        if (this.platform.is('android')) {
            notification.smallIcon = 'res://ic_stat_access_alarm';
            // starting Android 8.0+, all notifications are assigned a channel: https://developer.android.com/training/notify-user/channels
            // 'default-channel-id' is the name of channel created by the Local Notifications plugin
            notification.channel = 'default-channel-id';
        }
        this.localNotifications.schedule(notification);
    }

    public async trySave(): Promise<void> {
        const {canSave} = this;
        this.logger.debug(`trySave() canSave = ${canSave}`);
        if (canSave) {
            await this.save();
        }
    }

    public async save(): Promise<void> {
        let peripheralDisplayName = '';
        let peripheralRssi = '';
        if (this.peripheral) {
            peripheralDisplayName = this.fbAnalytics.getDisplayName(this.peripheral, this.task.type);
            peripheralRssi = this.peripheral.rssi;
        }
        this.logger.debug(`save()`);
        this.showLoading();
        if (this.autosubmitTimeout) this.clearAutosubmitTimeout();
        if (this.isNativePlatform) {
            this.logger.debug(`subscribePollingForBluetoothDevices`);
            this.deviceService.subscribePollingForBluetoothDevices();
        }

        const data = {
            ...this.getDataToSave(), // get the data recorded that we want to save
        };
        const metadata: TaskMetaData = {
            isBluetoothMetric: !this.manualMetricEntry, // set 'isBluetoothMetric' to false when a manual entry and true when a bluetooth entry
            recordedDate: moment().locale('en').format(), // get the date this metric was recorded in case the user is offline when they upload. // see note on language locale
        };

        this.logger.debug(`save() -> taskService.submitTask`);

        // [language locale] for non-western languages moment converts the date string to a different character set that
        // the server doesn't like so setting locale() to english to ensure we get a date string in western characters
        // (only an issue for non-western languages like arabic and hindi). This fixed an issue where the iOS app was crashing in arabic and hindi.
        this.logger.info('Submitting metric to CC for task - ' + this.task.type);
        this.taskService.submitTask(this.task, data, metadata).pipe(
            finalize(() => {
                this.logger.debug(`save() -> taskService.submitTask -> finalize`);
                // dismiss the loading button no matter what whether we succeed or error.
                this.hideLoading();
            })
        ).subscribe(
            {
                next: (res) => {
                    this.logger.debug(`save() -> taskService.submitTask success!`, res);
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.VITAL_SUBMISSION,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: this.task.type,
                            [HRSFirebaseParams.STATE]: 'SUCCESS', [HRSFirebaseParams.RSSI]: peripheralRssi});
                    // success scenario - task saved successfully
                    this.returnToHomePage();
                    // Submit Upload File Size of 0 to display in Admin Firebase Server Section.
                    // This tells Admins that metric data has been uploading successfully.
                    if (this.isHRSTablet) this.deviceProfile.getUploadFileSize();
                    this.taskTrackingService.submitTracking('submit-metric', 'success submitting ' + this.task.type);
                    this.logger.info('Submitting metric/task success ');
                },
                error: (error) => {
                    this.logger.phic.error(`save() -> taskService.submitTask error`, error);
                    this.fbAnalytics.logEvent(HRSFirebaseEvents.VITAL_SUBMISSION,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: this.task.type,
                            [HRSFirebaseParams.STATE]: 'FAILURE', [HRSFirebaseParams.RSSI]: peripheralRssi});
                    // failure scenario - task didn't save
                    // note: some error handling is happening directly in the taskService's submitTask() function
                    // show an alert that the metric did not save
                    this.handleSaveError(error);
                    // Since the metrics has failed to upload, we want to display the size of the data in Admin Firebase Server Section.
                    // This shows Admins the amount of data that is backed up.
                    if (this.isHRSTablet) this.deviceProfile.getUploadFileSize(data);
                    this.taskTrackingService.submitTracking('submit-metric', 'failed submitting ' + this.task.type);
                    this.logger.info('Submitting metric/task failed ' + error);
                }
            }
        );
    }

    private convertNonWesternNumberCharsToNumbers(str: string): string {
        //                               0      1     2     3     4     5     6     7     8     9
        let arabicNumbers: RegExp[] = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
        let hindiNumbers: RegExp[] = [/०/g, /१/g, /२/g, /३/g, /४/g, /५/g, /६/g, /७/g, /८/g, /९/g];
        if (typeof str === 'string') {
            for (var i = 0; i < 10; i++) {
                str = str
                    .replace(arabicNumbers[i], i.toString()) // arabic
                    .replace(hindiNumbers[i], i.toString()); // hindi
            }
            // if it finds an arabic or hindi number character it replaces that character with the loop's index which corresponds to the respective western number character.
        }
        return str;
    }

    private getDataToSave(): Object {
        this.logger.debug(`getDataToSave()`);
        let data: any = {};

        Object.keys(this.formGroup.controls).forEach((key) => {
            const control = this.formGroup.controls[key];
            let value = control.value;
            // TODO: JIR-8448: Need UI for user to select temperature unit.
            //  Until then hardcoding "F" as the default.
            if ((this.task.id === 'temperature') && key === 'unit') value = 'F';
            data[key] = this.convertNonWesternNumberCharsToNumbers(value);
        });

        this.logger.debug(`getDataToSave() result ->`, data);

        return data;
    }

    private returnToHomePage(): void {
        this.logger.debug(`returnToHomePage()`);
        // close the metric page/modal
        if (this.isModal) this.overlayRef.dismiss();
        // route back to the Home page
        this.navCtrl.navigateRoot(['home']);
    }

    private handleSaveError(err: Error): void {
        this.logger.phic.error('handleSaveError() Error submitting task to server', err);
        let taskTitle: string = this.task && this.task.title ? this.task.title.toLowerCase() : '';
        const hasServerPublicKey: boolean = !!this.encryptionService.serverPublicKey;
        this.logger.debug(`hasServerPublicKey = ${hasServerPublicKey}`);
        if (hasServerPublicKey) {
            // we've got a serverPublicKey, which means we encrypted and saved the metric on the patient's device.
            // provide an alert explaining that we'll be uploading their metric shortly.
            this.notSavedWillRetryAlert(taskTitle);
        } else {
            // no serverPublicKey, which means we couldn't encrypt their data, which means we couldn't store the data on their device's storage
            // thus, provide a generic error toast explaining their data was not saved.
            this.returnToHomePage();
            this.notSavedCantRetryToast(taskTitle);
        }
    }

    private async notSavedWillRetryAlert(taskTitle: string): Promise<void> {
        this.logger.debug(`notSavedWillRetryAlert()`, {taskTitle});
        await this.overlay.openAlert({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.HEADER'),
            message: [
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.1', {metric: taskTitle}),
                this.translate.instant('TASK_SUBMIT_FAILURE.BODY.2')
            ],
            buttons: [
                {
                    text: this.translate.instant('OK_BUTTON'),
                    // Dismiss the GenericMetricPage. (the AlertCtrl will also be dismissed implicitly since we don't return false here)
                    handler: () => this.returnToHomePage()
                }
            ],
            qa: 'metric_save_retry_alert'
        });
    }

    private async notSavedCantRetryToast(taskTitle: string): Promise<OverlayRef> {
        this.logger.debug(`notSavedCantRetryToast()`, {taskTitle});
        return await this.overlay.openToast({
            header: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.HEADER'),
            message: this.translate.instant('TASK_SUBMIT_FAILURE.CANT_RETRY.BODY', {metric: taskTitle}),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'generic_metric_toast'
        });
    }

    private showLoading(): void {
        this.logger.debug(`showLoading()`);
        this.saving = true;
        this.updateUIState();
    }

    private hideLoading(): void {
        this.logger.debug(`hideLoading()`);
        this.saving = false;
        this.updateUIState();
    }

    private updateUIState(): void {
        this.changeRef.detectChanges();
    }

    private getHistoricalData(refresher = null): void {
        const shouldRequestHistoricalData = !!this.task && !this.gettingHistoricalData;
        this.logger.debug(`getHistoricalData()`, {shouldRequestHistoricalData});
        if (shouldRequestHistoricalData) {
            this.gettingHistoricalData = true;
            this.historicalDataService.getHistoricalData(this.task.id).pipe(
                finalize(() => {
                    this.logger.debug(`getHistoricalData() -> finalize`);
                    this.gettingHistoricalData = false;
                    this.listInitialLoad = false;
                    this.updateUIState();
                    if (refresher) {
                        refresher.target.complete();
                    }
                })
            ).subscribe({
                next: (res: HistoricalData[]) => {
                    this.logger.debug(`getHistoricalData() success!`, res);
                    this.configureHistoricalData(res);
                },
                error: (err) => {
                    this.handleGetHistoricalDataError();
                    this.configureHistoricalData([]);
                    this.logger.phic.error(err);
                }
            });
        } else {
            if (refresher) {
                refresher.target.complete();
            }
        }
    }

    private async handleGetHistoricalDataError(): Promise<void> {
        if (this.toast) {
            this.toast.dismiss();
            this.toast = null;
        }
        this.toast = await this.overlay.openToast({
            header: this.translate.instant('DAILY_METRICS.ERROR.UNAVAILABLE'),
            variant: 'error',
            duration: 5000,
            qa: 'generic_metric_page--historical_data-error'
        });
    }

    public handlePullToRefresh(refresher): void {
        this.logger.debug(`handlePullToRefresh()`);
        this.getHistoricalData(refresher);
    }

    public async readContent(): Promise<void> {
        this.logger.debug(`readContent() isReading = ${this.isReading}`);
        if (!this.isNativePlatform) {
            this.logger.debug(`skipping on non-native platform`);
            return;
        }
        if (this.isReading) {
            await this.stopReading();
        }

        this.isReading = true;

        let metricText = this.getMetricText();

        this.logger.debug(`readContent() -> speak`, {metricText});
        this.tts.speak(metricText)
            .finally(() => {
                this.logger.debug(`readContent() -> speak done!`, {metricText});
                this.isReading = false;
                this.updateUIState();
            });
    }

    private getMetricText(): string {
        let metricText: string = '';
        const getText = {
            [TaskType.BloodPressure]: 'getBPReadout',
            [TaskType.PulseOx]: 'getPulseOxReadout',
            [TaskType.Temperature]: 'getTempReadout',
            [TaskType.Glucose]: 'getGlucoseReadout'
        }[this.task.id];

        if (getText) {
            metricText = this[getText]();
        } else {
            this.schema.forEach((control, i) => {
                const reading: string = this.formGroup.controls[control.id].value;
                if (reading) {
                    metricText += this.translate.instant('GENERIC_METRIC_SPEAK_READING', {
                        metric: control.uiText.label,
                        reading: reading,
                        unit: control.uiText.placeholder
                    });
                }
                if (i < GenericMetricSchema[this.task.type].length - 1) {
                    metricText += '. ';
                }
            });
        }

        // if metric is blank at least read the metric modal title
        if (metricText === '') metricText = this.task.title;

        return metricText;
    }

    public getGlucoseReadout(): string {
        let metricText = '';
        let placeholder = 'GENERIC_METRIC_UNITS_LABEL_GLUCOSE_MGDL';
        if (this.shouldUseMillimoles()) {
            placeholder = 'GENERIC_METRIC_UNITS_LABEL_GLUCOSE_MMOLL';
        }

        const reading: string = this.formGroup.controls[this.schema[0].id].value;
        if (reading) {
            metricText += this.translate.instant('GENERIC_METRIC_SPEAK_READING', {
                metric: this.schema[0].uiText.label,
                reading: reading,
                unit: this.translate.instant(placeholder)
            });
        }

        return metricText;
    }

    public getBPReadout(): string {
        let systolic: string;
        let diastolic: string;
        let heartRate: string;

        this.schema.forEach((control) => {
            let reading = this.formGroup.controls[control.id].value;
            let units = control.uiText.placeholder;

            if (reading) {
                if (control.id === 'systolic') {
                    systolic = `${reading} ${units}`;
                } else if (control.id == 'diastolic') {
                    diastolic = `${reading} ${units}`;
                } else if (control.id == 'heartrate') {
                    heartRate = `${reading} ${units}`;
                }
            }
        });

        if (!systolic || !diastolic || !heartRate) return '';

        const text = this.translate.instant('GENERIC_METRIC_SPEAK_READING_BP', {
            systolic: systolic,
            diastolic: diastolic,
            heartrate: heartRate
        });

        return text;
    }

    public getPulseOxReadout(): string {
        let oxygenLevel: string;
        let heartRate: string;

        this.schema.forEach((control) => {
            let reading = this.formGroup.controls[control.id].value;
            let units = control.uiText.placeholder;

            if (reading) {
                if (control.id === 'spo2') {
                    oxygenLevel = `${reading} ${units}`;
                } else if (control.id == 'hr') {
                    heartRate = `${reading} ${units}`;
                }
            }
        });

        if (!oxygenLevel || !heartRate) return '';

        const text = this.translate.instant('GENERIC_METRIC_SPEAK_READING_PULSEOX', {
            oxygenlevel: oxygenLevel,
            heartrate: heartRate
        });

        return text;
    }

    public getTempReadout(): string {
        let temperature: string;

        this.schema.forEach((control) => {
            let reading = this.formGroup.controls[control.id].value;
            let units = control.uiText.placeholder;

            if (reading && control.id === 'temperature') {
                temperature = `${reading} ${units}`;
            }
        });

        if (!temperature) return '';

        const text = this.translate.instant('GENERIC_METRIC_SPEAK_READING_TEMP', {
            temperature: temperature
        });

        return text;
    }

    private shouldUseMillimoles(): boolean {
        return this.task.type === TaskType.Glucose && this.environmentService.hasEnvironmentSetting('SYSTEM_GLUCOSEUNITMMOLL');
    }

    private initDeviceInfo(): void {
        const task = this.task;
        this.logger.debug(`initDeviceInfo()`, {task});

        if (task.type === TaskType.PulseOx) {
            this.peripheralType = 'pulseox';
        } else if (task.type === TaskType.Temperature) {
            this.peripheralType = 'temperature';
        } else if (task.type === TaskType.BloodPressure) {
            this.peripheralType = 'bloodpressure';
        } else if (task.type === TaskType.Glucose) {
            this.peripheralType = 'glucose';
        } else if (task.type === TaskType.Weight) {
            this.peripheralType = 'weight';
        }
        this.imageUrl = this.deviceService.getDeviceImageUrl(this.peripheralType, this.deviceDetails.name);

        // initDeviceInfo() can be called several times from route changes,
        // so make sure we remove the existing footer event subscription if needed.
        if (this.entrySubscription) {
            try {
                this.entrySubscription.unsubscribe();
            } catch {}
        }

        // Handle the footer Manual Entry button
        this.entrySubscription = FooterEvent.clicked.pipe(
            filter((details: FooterClickDetails) => details.page === MainPageTypes['GENERIC_METRIC']),
            throttleClick() // throttle UI inputs to prevent "double-firing" from this subject
        ).subscribe(() => {
            this.logger.debug(`FooterEvent.clicked`);
            if (!(task.type === TaskType.Weight) || this.allowWeightEntry()) {
                this.launchGenericMetricModal();
            }
        });
    }

    private configureHistoricalData(metricReadings: (HistoricalData | OfflineHistoricalData)[]): void {
        let UIlists = [];
        let RawDataLists = [];

        const isOfflineTask = this.task.type === TaskType.Activity ? this.task.isCompletedButOffline() : this.task.isCompleted() && this.task.isCompletedButOffline();
        let offlineSubmitTS: Date;
        if (isOfflineTask) {
            offlineSubmitTS = this.task.lastCompletedButOffline;
        }
        let offlineReading: OfflineHistoricalData;
        if (offlineSubmitTS) {
            offlineReading = {
                attributes: {
                    takenAt: moment(offlineSubmitTS).format('MM/DD/YYYY'),
                    metric: 'offline',
                    facet: {
                        script: this.translate.instant('METRIC.OFFLINE_READING.SCRIPT'),
                        type: 'offline'
                    }
                }
            };
        }

        if (metricReadings) {
            if (offlineReading) metricReadings.unshift(offlineReading);
            metricReadings.forEach((reading, index) => {
                let readingDate: string;
                if (reading.attributes.takenAt) {
                    readingDate = moment(reading.attributes.takenAt).format('MM/DD/YYYY');
                } else if (reading.attributes.metric === TaskType.Activity) {
                    return; // when historical activity readings are returned without `takenAt`, no activity was submitted for the day
                }

                if (offlineReading && readingDate !== moment(offlineSubmitTS).format('MM/DD/YYYY') && index === 0) {
                    metricReadings.shift();
                }

                const matchingList = UIlists.length > 0 ? UIlists.filter((list) => {
                    return list.legend.title === readingDate;
                }) : [];

                if (UIlists.length === 0 || matchingList.length < 1) {
                    const listDetail = {
                        variant: 'metrics',
                        legend: {
                            title: readingDate
                        },
                        metrics: []
                    };

                    if (this.isHRSTablet) {
                        (listDetail.legend as ListLegend).rightIconButton = {
                            icon: 'audio',
                            align: 'right',
                            handler: () => this.readHistoricalMetrics(readingDate)
                        };
                    }

                    UIlists.push(listDetail);
                    RawDataLists.push([]);
                }

                UIlists[UIlists.length - 1].metrics.push(this.formatMetricReading(reading));
                RawDataLists[RawDataLists.length - 1].push(reading.attributes.facet);
            });

            this.listConfig = UIlists;
            this.historicalData = RawDataLists;
            this.changeRef.detectChanges();
        } else if (offlineSubmitTS) {
            const listDetail = {
                variant: 'metrics',
                legend: {
                    title: moment(offlineSubmitTS).format('MM/DD/YYYY')
                },
                metrics: []
            };
            listDetail.metrics.push(this.formatMetricReading(offlineReading));
            UIlists.push(listDetail);
            this.listConfig = UIlists;
            this.historicalData = [[{
                script: this.translate.instant('METRIC.OFFLINE_READING.SCRIPT'),
                type: 'offline'
            }
            ]];
        }
    }

    private formatMetricReading(reading: HistoricalData | OfflineHistoricalData): MetricReadingDetail {
        const tz = moment.tz.guess();
        let takenAt: string;
        if (reading.attributes.takenAt) {
            takenAt = reading.attributes.takenAt ? moment.tz(reading.attributes.takenAt, 'YYYY-MM-DDTHH:mm:ssZ', tz).format('hh:mm A') : '';
        }
        switch (reading.attributes.metric) {
            case 'activity':
                const activityFacet = <ActivityFacet>reading.attributes.facet;
                return {
                    reading: `${activityFacet.value} of ${activityFacet.goal}`,
                    subText: this.translate.instant('METRIC.ACTIVITY.SUBTEXT'),
                    time: takenAt
                };
            case 'bloodpressure':
                const bpFacet = <BloodPressureFacet>reading.attributes.facet;
                return {
                    reading: `${bpFacet.systolic}/${bpFacet.diastolic}`,
                    subText: bpFacet.heartrate.toString(),
                    subTextIcon: 'heartrate',
                    subTextIconColor: 'risk-high',
                    time: takenAt
                };
            case 'glucose':
                const glucoseFacet = <GlucoseFacet>reading.attributes.facet;
                return {
                    reading: `${parseFloat(glucoseFacet.bloodsugar.toString())}`,
                    time: takenAt
                };
            case 'pulseox':
                const pulseoxFacet = <PulseOxFacet>reading.attributes.facet;
                return {
                    reading: `${pulseoxFacet.spo2}`,
                    readingIcon: 'percent',
                    readingIconColor: 'black',
                    subText: pulseoxFacet.heartrate.toString(),
                    subTextIcon: 'heartrate',
                    subTextIconColor: 'risk-high',
                    time: takenAt
                };
            case 'weight':
                const weightFacet = <WeightFacet>reading.attributes.facet;
                return {
                    reading: `${parseFloat(weightFacet.weight.toString())}`,
                    time: takenAt
                };
            case 'temperature':
                const tempFacet = <TemperatureFacet>reading.attributes.facet;
                return {
                    reading: `${parseFloat(tempFacet.temperature.toString())}`,
                    time: takenAt
                };
            case 'offline':
                return {
                    reading: this.translate.instant('METRIC.OFFLINE_READING'),
                    status: 'submitted',
                    readingItalicize: true,
                    readingColor: 'gray-7'
                };
        }
    }

    private getHistoricalDataListIndex(readingDate: string): number {
        return this.listConfig ? this.listConfig.findIndex((list) => (list.legend as ListLegend).title === readingDate) : -1;
    }

    private async buttonAnimation(startOrStop: 'start' | 'stop', readingDate: string): Promise<void> {
        const isStart = startOrStop === 'start';
        let button;

        if (readingDate === 'all') {
            button = document.querySelector('.historical_data--btn-speak');
        } else {
            const listIndex: number = this.getHistoricalDataListIndex(readingDate);
            if (listIndex >= 0) {
                const cards = document.querySelector('app-generic-metric hrs-content').shadowRoot.querySelector('slot').assignedNodes();
                const historicalDataCard = cards ? cards.filter((card) => (card as Element).classList.contains('historical_data')) : undefined;
                const historicalDataLists = historicalDataCard ? ((historicalDataCard[0] as Element).shadowRoot.querySelector('.card--outer slot') as HTMLSlotElement).assignedNodes().filter((content) => (content as Element).nodeName === 'HRS-LIST') : undefined;
                button = historicalDataLists ? (historicalDataLists[listIndex] as Element).shadowRoot.querySelector('hrs-button') : undefined;
            }
        }

        if (button) {
            if (isStart) {
                await button.startAnimation();
            } else {
                await button.stopAnimation();
            }
            this.changeRef.detectChanges();
        }
    }

    private getMetricScriptUnits(metric: string = ''): string {
        let units: string;

        if (this.schema) {
            let metricEntry;

            if (metric) {
                metricEntry = this.schema.find((entry) => entry.id === metric);
            } else { // there is only 1 metric (Activity, Temperature, etc.)
                metricEntry = this.schema[0];
            }

            if (metricEntry) units = metricEntry.placeholder;
        }

        if (units) {
            let key = 'GENERIC_METRIC_FIELD_PLACEHOLDER_' + units.toUpperCase();
            if (this.task.type === TaskType.Glucose) key = 'GENERIC_METRIC_UNITS_LABEL_GLUCOSE_MGDL';
            if (this.shouldUseMillimoles()) key = 'GENERIC_METRIC_UNITS_LABEL_GLUCOSE_MMOLL';
            const translation = this.translate.instant(key);
            if (translation && translation !== key) {
                units = translation;
            }
        }

        return units;
    }

    private formatMetricScript(listIndex: number, index: number): string {
        const metric = this.historicalData[listIndex][index];

        let offlineMetric = <OfflineFacet>metric;
        if (offlineMetric.type === 'offline') {
            return offlineMetric.script;
        }

        const time = this.listConfig[listIndex].metrics[index].time;
        const readingKey: string = `METRIC.HISTORICAL.${this.task.type.toUpperCase()}.READING`;
        let script: string = '';
        if (index > 0) script = this.translate.instant('METRIC.HISTORICAL.NEXT_READING');

        switch (this.task.type) {
            case TaskType.Activity:
                const activityData: ActivityFacet = metric as ActivityFacet;
                script += this.translate.instant(readingKey, {duration: activityData.value, goal: activityData.goal, units: this.getMetricScriptUnits()});
                break;

            case TaskType.BloodPressure:
                const bpData: BloodPressureFacet = metric as BloodPressureFacet;
                const systolic: string = `${bpData.systolic} ${this.getMetricScriptUnits('systolic')}`;
                const diastolic: string = `${bpData.diastolic} ${this.getMetricScriptUnits('diastolic')}`;
                const heartrate: string = `${bpData.heartrate} ${this.getMetricScriptUnits('heartrate')}`;
                script += this.translate.instant(readingKey, {systolic: systolic, diastolic: diastolic, heartrate: heartrate});
                break;

            case TaskType.PulseOx:
                const poxData: PulseOxFacet = metric as PulseOxFacet;
                const spo2: string = `${poxData.spo2} ${this.getMetricScriptUnits('spo2')}`;
                const hr: string = `${poxData.heartrate} ${this.getMetricScriptUnits('hr')}`;
                script += this.translate.instant(readingKey, {level: spo2, heartrate: hr});
                break;

            case TaskType.Glucose:
                const glucoseData: GlucoseFacet = metric as GlucoseFacet;
                script += `${parseFloat(glucoseData.bloodsugar.toString())} ${this.getMetricScriptUnits()}`;
                break;

            case TaskType.Temperature:
                const tempData: TemperatureFacet = metric as TemperatureFacet;
                script += `${parseFloat(tempData.temperature.toString())} ${this.getMetricScriptUnits('temperature')}`;
                break;

            case TaskType.Weight:
                const weightData: WeightFacet = metric as WeightFacet;
                script += `${parseFloat(weightData.weight.toString())} ${this.getMetricScriptUnits()}`;
                break;

            default:
                return '';
        }

        return script + ', ' + this.translate.instant('METRIC.HISTORICAL.TIME', {time: time}) + ', ';
    }

    private async getScript(readingDate: string, skipTitle: boolean = true): Promise<string[]> {
        const listIndex: number = this.getHistoricalDataListIndex(readingDate);
        if (listIndex >= 0) {
            const configList: ListDetail = this.listConfig[listIndex];
            const forAll: boolean = this.currentlyReading === 'all';
            const key: string = `METRIC.HISTORICAL.${this.task.type.toUpperCase()}`;
            let scripts: string[] = [];

            if (forAll && !skipTitle) {
                scripts.push(`${this.translate.instant(key)} ${this.translate.instant('METRIC.HISTORICAL.TITLE_ALL')}`);
            }

            for (let i = 0; i < configList.metrics.length; i++) {
                if (i === 0) {
                    scripts.push(`${forAll ? '' : this.translate.instant(key)} ${this.translate.instant('METRIC.HISTORICAL.DATE', {date: (configList.legend as ListLegend).title})}`);
                }
                scripts.push(`${this.formatMetricScript(listIndex, i)}`);
            }

            return scripts;
        }
    }

    private async stopReading(): Promise<void> {
        // stop the TTS reading
        // flag to stop reading current script and
        // stop animation on currently animated button
        this.tts.stop();
        this.isReading = false;
        await this.buttonAnimation('stop', this.currentlyReading);
        this.currentlyReading = undefined;
        this.updateUIState();
    }

    private async readHistoricalMetrics(readingDate: string): Promise<void> {
        const startNewReading = readingDate != this.currentlyReading;

        // if we're here & isReading is true,
        // user has either clicked the same read button/row to stop the reading, or
        // clicked another read button/row to start a different reading
        // so stop the current script
        if (this.isReading) {
            await this.stopReading();
        }

        if (startNewReading) {
            this.currentlyReading = readingDate;
            await this.buttonAnimation('start', readingDate);
            const scripts = await this.getScript(readingDate);
            await this.readScript(scripts);
            if (this.isReading) await this.tts.speak(this.translate.instant('METRIC.HISTORICAL.READOUT_COMPLETE'));
            this.isReading = false;
            await this.buttonAnimation('stop', this.currentlyReading);
            this.currentlyReading = undefined;
        }
    }

    public async readAllHistoricalMetrics(): Promise<void> {
        const startNewReading = this.currentlyReading != 'all';

        // if we're here & isReading is true or we're already reading all metrics,
        // user has either clicked the read all button to stop the reading, or
        // clicked another read button/row to start a different reading
        // either way, stop the current script
        if (this.isReading || this.currentlyReading === 'all') {
            await this.stopReading();
        }

        if (startNewReading) {
            this.currentlyReading = 'all';
            await this.buttonAnimation('start', 'all');
            let scripts: string[][] = [];
            for (let i = 0; i < this.listConfig.length; i++) {
                scripts.push(await this.getScript((this.listConfig[i].legend as ListLegend).title, i > 0));
            }
            const flatScripts: string[] = flatten(scripts);
            // if flatScripts is empty, read out {Metric} Latest readings, No Readings Available
            if (flatScripts.length === 0) {
                flatScripts.push(`${this.translate.instant(`METRIC.HISTORICAL.${this.task.type.toUpperCase()}`)} ${this.translate.instant('METRIC.HISTORICAL.TITLE_ALL')}`);
                flatScripts.push(this.translate.instant('GENERIC_METRIC.HISTORICAL.NO_READINGS'));
            }

            await this.readScript(flatScripts);
            if (this.isReading) await this.tts.speak(this.translate.instant('METRIC.HISTORICAL.READOUT_COMPLETE'));
            this.isReading = false;
            await this.buttonAnimation('stop', 'all');
            this.currentlyReading = undefined;
        }
    }

    private async readScript(scripts: string[]) {
        this.isReading = true;
        for (const script of scripts) {
            if (this.isReading) {
                await this.tts.speak(script);
            } else {
                break;
            }
        }
    }

    private allowWeightEntry(): boolean {
        if (!this.task) return;
        const task = this.task;

        let hasMultipleReadingsFlag = this.environmentService.hasSystemMultipleReadingsFlag();
        // per JIR-5566 we will no longer allow multiple weight readings without the `SYSTEM_MULTIPLEREADINGS` flag
        if ((task.isCompleted() || task.isCompletedButOffline()) && !hasMultipleReadingsFlag) {
            this.showMetricAlreadyTakenAlert();
            return false;
        }
        return true;
    }

    private async showMetricAlreadyTakenAlert(): Promise<void> {
        await this.overlay.openAlert({
            header: this.translate.instant('GENERIC_METRIC_ALREADY_TAKEN'),
            message: [this.translate.instant('GENERIC_METRIC_ALREADY_TAKEN_MESSAGE')],
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('OK_BUTTON'),
                    handler: () => {
                        if (!this.environmentService.hasHistoricalData()) this.navCtrl.navigateRoot(['/home']);
                        this.overlay.dismiss();
                    }
                }
            ],
            qa: 'home_metric_alert'
        });
    }

    private async launchGenericMetricModal(): Promise<void> {
        this.logger.debug(`launchGenericMetricModal()`);
        const activeModal = await this.overlay.getTop('modal');
        // Need to ensure that no modals are open ahead of metric manual entry, EXCEPT voice or video call modals
        if (activeModal instanceof OverlayComponent &&
            !(activeModal.childComponentRef.instance instanceof VoicePage) &&
            !(activeModal.childComponentRef.instance instanceof VideoPage)) {
            await activeModal.dismiss();
        }

        const modal = await this.overlay.openModal({
            component: GenericMetricPage,
            title: this.task.title,
            inputs: {
                task: this.task,
                entryPoint: MetricEntryTypeEnum.ManualEntry
            },
            qa: 'generic_metric_modal'
        });
        modal.result$.subscribe((res) => {
            this.modalService.setModalStatus('GenericMetricPage', false);
        });
    }

    private async launchDeviceInstructions(peripheralType: string, device: any): Promise<void> {
        let instructions = [];
        let instruction1;
        let instruction2;
        let instruction3;
        let instruction4;
        let instruction5;
        let instruction6;
        let deviceText: string = this.deviceService.getDeviceText(device.name, peripheralType);
        if (peripheralType === 'pulseox' || peripheralType === 'weight' || peripheralType === 'temperature' || peripheralType === 'bloodpressure') {
            instruction1 = this.translate.instant(`INSTRUCTIONS_1.${peripheralType.toUpperCase()}.${deviceText}`);
            instruction2 = this.translate.instant(`INSTRUCTIONS_2.${peripheralType.toUpperCase()}.${deviceText}`);
            instruction3 = this.translate.instant(`INSTRUCTIONS_3.${peripheralType.toUpperCase()}.${deviceText}`);
            if (peripheralType === 'pulseox') {
                instruction4 = this.translate.instant(`INSTRUCTIONS_4.${peripheralType.toUpperCase()}.${deviceText}`);
                instructions.push(instruction1, instruction2, instruction3, instruction4);
            } else {
                instructions.push(instruction1, instruction2, instruction3);
            }
            if (peripheralType === 'temperature' || peripheralType === 'bloodpressure') {
                instruction4 = this.translate.instant(`INSTRUCTIONS_4.${peripheralType.toUpperCase()}.${deviceText}`);
                instruction5 = this.translate.instant(`INSTRUCTIONS_5.${peripheralType.toUpperCase()}.${deviceText}`);
                instructions.push(instruction4, instruction5);
            }
        } else if (peripheralType === 'glucose') {
            instruction1 = this.translate.instant('INSTRUCTIONS_1.' + peripheralType.toUpperCase());
            instruction2 = this.translate.instant('INSTRUCTIONS_2.' + peripheralType.toUpperCase());
            instruction3 = this.translate.instant('INSTRUCTIONS_3.' + peripheralType.toUpperCase());
            instruction4 = this.translate.instant('INSTRUCTIONS_4.' + peripheralType.toUpperCase());
            instruction5 = this.translate.instant('INSTRUCTIONS_5.' + peripheralType.toUpperCase());
            instruction6 = this.translate.instant('INSTRUCTIONS_6.' + peripheralType.toUpperCase());
            instructions.push(instruction1, instruction2, instruction3, instruction4, instruction5, instruction6);
        }

        const modalTitle = this.translate.instant('BLUETOOTH.DEVICE_INSTRUCTIONS_TITLE');
        await this.overlay.openModal({
            component: DeviceInstructionsUsagePage,
            title: modalTitle,
            inputs: {
                device: device,
                imageUrl: this.deviceService.getDeviceImageUrl(peripheralType, device.name),
                instructions: instructions,
                peripheralType: peripheralType
            },
            qa: 'usage_instructions_modal'
        });
    }

    public showUsage(): void {
        if (this.hasPairedDevice && this.deviceDetails) {
            this.launchDeviceInstructions(this.task.type, this.deviceDetails);
        }
    }
}
