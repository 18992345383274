////////////////////////////////////////////////////////////////
// Generic Cordova Utilities
////////////////////////////////////////////////////////////////
function noop() {
    return;
}
function cordovaExec(plugin, method, successCallback = noop, errorCallback = noop, args = []) {
    if (window.cordova) {
        window.cordova.exec(successCallback, errorCallback, plugin, method, args);
    }
    else {
        console.warn(`${plugin}.${method}(...) :: cordova not available`);
        errorCallback && errorCallback(`cordova_not_available`);
    }
}
function cordovaExecPromise(plugin, method, args) {
    return new Promise((resolve, reject) => {
        cordovaExec(plugin, method, resolve, reject, args);
    });
}
////////////////////////////////////////////////////////////////
// Plugin Interface
////////////////////////////////////////////////////////////////
const PLUGIN_NAME = 'TTS';
function invoke(method, ...args) {
    return cordovaExecPromise(PLUGIN_NAME, method, args);
}
export var TTSEventType;
(function (TTSEventType) {
    TTSEventType["TTS_READY"] = "ttsReady";
})(TTSEventType || (TTSEventType = {}));
export class TTSCordovaInterface {
    constructor() {
    }
    /**
     * This function speaks
     *
     * @param textOrOptions {string | TTSOptions} Text to speak or TTSOptions
     * @returns {Promise<any>} Returns a promise that resolves when the speaking finishes
     */
    speak(textOrOptions) {
        return invoke('speak', textOrOptions);
    }
    /**
     * Stop any current TTS playback
     *
     * @returns {Promise<any>}
     */
    stop() {
        return invoke('stop');
    }
    /**
     * This function returns the installed language packages
     *
     * @returns {Promise<string>} Returns a promise that will resolve to a comma separated string
     */
    checkLanguage() {
        return invoke('checkLanguage');
    }
    /**
     * This function invokes the native TTS installation process
     *
     * @returns {Promise<void>}
     */
    openInstallTts() {
        return invoke('openInstallTts');
    }
    /**
     * Get all voices
     *
     * @returns {Promise<TTSVoice[]>}
     */
    getVoices() {
        return invoke('getVoices');
    }
    /**
     *
     * @returns {Promise<string>} the default TTS engine set on the device
     */
    getDefaultEngine() {
        return invoke('getDefaultEngine');
    }
    setSharedEventDelegate(success, error) {
        cordovaExec(PLUGIN_NAME, 'setSharedEventDelegate', success, error, [false]);
    }
    removeSharedEventDelegate() {
        cordovaExec(PLUGIN_NAME, 'setSharedEventDelegate', undefined, undefined, [true]);
    }
}
/**
 * Singleton reference to interact with this cordova plugin
 */
export const TTS = new TTSCordovaInterface();
