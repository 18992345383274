import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {TaskType} from '../tasks/task-type.enum';
import {Task} from '../tasks/task.model';
import {MedicationTask} from '../tasks/medication-task.model';
import {ILocalNotification, ILocalNotificationTrigger, LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {NotificationData} from '../firebase/firebase-data-interface';
import {Schedule} from './schedule';
import {SurveyTask} from '../tasks/survey-task.model';
import {MULTIPLE_REMINDER_TYPES, Reminder} from '../tasks/multiple-reminders.interface';
import Timer = NodeJS.Timer;
import {FirebaseNotifications} from '../firebase/firebase';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class SchedulerService {
    private readonly logger = getLogger('SchedulerService');
    private midnightTimer: Timer;
    private hasMultipleReminders: boolean = false;
    private delayedNotifications: ILocalNotification[] = [];
    private platformPauseHandler: Subscription;
    private platformResumeHandler: Subscription;

    constructor(private platform: Platform,
                private translate: TranslateService,
                private localNotifications: LocalNotifications,
                protected firebase: FirebaseNotifications,
    ) {}

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    public initialize(): void {
        this.setMidnightTimer();
        this.subscribePlatformEvents();
    }

    onDestroy() {
        this.clearMidnightTimer();
        this.unsubscribePlatformEvents();
    }

    private setMidnightTimer(): void {
        const today: moment.Moment = moment();
        const midnight: moment.Moment = moment().add(1, 'days').set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const msToMidnight: number = midnight.diff(today);

        this.midnightTimer = setTimeout(() => {
            this.processAtMidnight();
        }, msToMidnight);
    }

    private processAtMidnight(): void {
        this.scheduleDelayedNotifications();
        this.setMidnightTimer();
    }

    private clearMidnightTimer(): void {
        if (this.midnightTimer) {
            clearTimeout(this.midnightTimer);
            this.midnightTimer = null;
        }
    }

    private subscribePlatformEvents(): void {
        // platform pause pauses JS timer countdowns, so we need to reset our "midnight" timer on resume and
        // we'll need to track whether the pause-reset delay crosses a date, in which case we need to kick off the
        // midnight processing
        let pausedAt: moment.Moment;
        if (!this.platformPauseHandler) {
            this.platformPauseHandler = this.platform.pause.subscribe(() => {
                pausedAt = moment();
            });
        }
        if (!this.platformResumeHandler) {
            this.platformResumeHandler = this.platform.resume.subscribe(() => {
                const resumedAt: moment.Moment = moment();
                // resumed on same day?
                if (resumedAt.date() === pausedAt.date()) {
                    // reset the midnight timer
                    this.clearMidnightTimer();
                    this.setMidnightTimer();
                } else {
                    // clear the midnight timer & execute the midnight process (will reset the timer)
                    this.clearMidnightTimer();
                    this.processAtMidnight();
                }
            });
        }
    }

    private unsubscribePlatformEvents(): void {
        if (this.platformPauseHandler) {
            this.platformPauseHandler.unsubscribe();
            this.platformPauseHandler = null;
        }
        if (this.platformResumeHandler) {
            this.platformResumeHandler.unsubscribe();
            this.platformResumeHandler = null;
        }
    }

    private scheduleDelayedNotifications(): void {
        if (!this.isNativePlatform) {
            return;
        }
        if (this.delayedNotifications.length) {
            this.scheduleNotifications(this.delayedNotifications);
        }
    }

    private delayNotification(notificationToAdd: ILocalNotification): void {
        let delayedNotificationToClear: number;

        this.delayedNotifications.forEach((delayedNotification, index) => {
            // if the notification to be added matches an existing notification, we need to remove the notification
            // that is already in the queue
            if (delayedNotification.data.id === notificationToAdd.data.id) {
                delayedNotificationToClear = index;
            }
        });
        if (delayedNotificationToClear !== undefined) {
            this.delayedNotifications.splice(delayedNotificationToClear, 1);
        }
        this.delayedNotifications.push(notificationToAdd);
    }

    public scheduleTasks(tasks: Task[], multipleReminders?: Reminder[]): void {
        if (!this.isNativePlatform) {
            return;
        }
        this.hasMultipleReminders = !!(multipleReminders && multipleReminders.length);
        let notifications = this.buildAllNotifications(tasks, multipleReminders);

        if (!notifications.length) {
            this.unscheduleTasks();
            this.logger.phic.debug('Cancelling notifications (none to schedule)');
            return;
        }

        this.scheduleNotifications(notifications);
    }

    public clearNotifications(): void {
        this.unscheduleTasks();
        this.delayedNotifications = [];
    }

    public unscheduleTasks(): void {
        if (!this.isNativePlatform) {
            return;
        }

        // LocalNotifications.cancelAll() is not working, so pulling all notifications so we can cancel by ID ... which does work
        this.localNotifications.getAll().then((existingNotifications) => {
            let idsToCancel = [];
            if (existingNotifications.length) {
                existingNotifications.forEach((notification) => {
                    idsToCancel.push(notification.id);
                });
                this.localNotifications.cancel(idsToCancel).then(() => {
                    this.logger.phic.debug(idsToCancel.length + ' notifications cancelled');
                });
            } else {
                this.logger.phic.debug('Did not find any notifications to cancel');
            }
        });
    }

    private scheduledTimesMatch(scheduleA: {[key: string]: any}, scheduleB: {[key: string]: any}): boolean {
        if (!scheduleA || !scheduleB) return false;

        const convertToHourAndMinute = (schedule: {[key: string]: any}): {hour: number, minute: number} => {
            if (schedule.every) {
                return schedule.every;
            } else {
                return {hour: moment(schedule.at, 'HH:mm:ss').hours(), minute: moment(schedule.at, 'HH:mm:ss').minutes()};
            }
        };

        scheduleA = convertToHourAndMinute(scheduleA);
        scheduleB = convertToHourAndMinute(scheduleB);

        return scheduleA.hour === scheduleB.hour && scheduleA.minute === scheduleB.minute;
    }

    /**
     *   untapped FCM notifications will populate in LocalNotification ExistingNotifications
     *   these cause exceptions that cascade through out scheduler
     */
    private filterForTaskNotifications(notifications: (NotificationData | ILocalNotification)[]): ILocalNotification[] {
        // @ts-ignore
        return notifications.filter((notification) => !notification['google.c.sender.id']);
    }

    /**
     * Only called for tasks not included in MULTIPLE_REMINDER_TYPES when the EnvironmentService.hasMultipleReminders is true
     * Called for all tasks when EnvironmentService.hasMultipleReminders is false
     * If the task is scheduled for later today, this will skip that scheduled time so that its next scheduled notification will be tomorrow
     */
    public skipScheduledTask(task: Task): void {
        if (!this.isNativePlatform) {
            return;
        }

        let notification = this.buildSingleNotification(task);
        if (!notification) {
            return;
        }

        this.localNotifications.getAll().then((scheduledNotifications) => {
            scheduledNotifications = this.filterForTaskNotifications(scheduledNotifications);
            let existingNotification = scheduledNotifications.find((scheduledNotification) => {
                try {
                    scheduledNotification.data = JSON.parse(scheduledNotification.data);
                } catch (ex) {
                    this.logger.phic.info('Error parsing notification data', ex);
                    return false;
                }

                return this.scheduledTimesMatch(scheduledNotification.trigger, notification.trigger) && this.hasMatchingTask(scheduledNotification, notification);
            });

            if (existingNotification) {
                // Update the id on our new notification object to match the existing one, so that we will be replacing its schedule
                notification.id = existingNotification.id;

                // There's an update() function but it doesn't seem to work correctly, so just cancelling the old notification and then
                // adding the new notification since that does work.
                this.localNotifications.cancel([notification.id]).then(() => {
                    this.logger.phic.debug(task.id + ' notification cancelled for today');
                    this.localNotifications.schedule(notification);
                    this.logger.phic.debug(task.id + ' notification rescheduled starting tomorrow');
                });
            } else {
                this.logger.phic.error('Couldnt find notification to skip for ' + task.id);
            }
        });
    }

    private getUpdatesToScheduledReminders(
        moduleName: string,
        scheduledTime: moment.Moment,
        scheduledNotifications: ILocalNotification[],
        newNotifications: ILocalNotification[]): [number[], ILocalNotification[]] {
        let notificationIdsToRemove: number[] = [];
        let notificationsToAdd: ILocalNotification[] = [];
        let scheduledTrigger = {
            every: {
                hour: scheduledTime.hours(),
                minute: scheduledTime.minutes()
            }
        };
        // notification.trigger.at is described as type Date in the ILocalNotificationTrigger interface definition
        // unforunately, trying to utilize Date methods, such as getHours() or getMinutes() directly on the 'at' property fails
        // generating a runtime error of 'getHours is not defined' - testing showed that typeof notification.trigger.at returns 'number'
        // therefore we are creating a Date from the property to ensure that this method will always work
        let getNotificationTrigger = (notification: ILocalNotification): ILocalNotificationTrigger => {
            if (notification.trigger.at) {
                const testDate = new Date(notification.trigger.at);
                return {
                    every: {
                        hour: testDate.getHours(),
                        minute: testDate.getMinutes()
                    }
                };
            } else {
                return notification.trigger;
            }
        };
        scheduledNotifications.forEach((notification) => {
            if (this.scheduledTimesMatch(getNotificationTrigger(notification), scheduledTrigger) && notification.text.includes(moduleName)) {
                notificationIdsToRemove.push(notification.id);
            }
        });
        notificationsToAdd = newNotifications.filter((notification) => {
            return this.scheduledTimesMatch(getNotificationTrigger(notification), scheduledTrigger);
        });

        return [notificationIdsToRemove, notificationsToAdd];
    }

    /**
     * Only called for tasks included in MULTIPLE_REMINDER_TYPES when the environment has SYSTEM_MULTIPLEREMINDERS set
     * If the task is scheduled for later today, this will skip the scheduled times where the task is considered completed
     */
    public skipScheduledReminders(task: Task, tasks: Task[], reminders: Reminder[]): void {
        if (!this.isNativePlatform) {
            return;
        }

        this.localNotifications.getAll().then((scheduledNotifications) => {
            const moduleName: string = task.title.toLowerCase();
            let highestNotificationId: number = 0;
            let notificationsToCancel: number[] = [];
            let notificationsToSchedule: ILocalNotification[] = [];

            const updatedNotifications = this.buildMultipleRemindersNotifications(tasks, reminders);

            scheduledNotifications = this.filterForTaskNotifications(scheduledNotifications);
            scheduledNotifications.forEach((notification) => {
                if (notification.id > highestNotificationId) highestNotificationId = notification.id;
            });

            if (scheduledNotifications) {
                // if we are in the middle of a reminder window which includes this task, we need to remove this task from the reminder at the end of the window
                const activeSchedule = task.getActiveSchedule();
                if (activeSchedule && activeSchedule.reminder) {
                    const scheduledTime: moment.Moment = moment(activeSchedule.reminder, moment.HTML5_FMT.TIME_SECONDS);
                    const [notificationsToRemove, notificationsToAdd] = this.getUpdatesToScheduledReminders(moduleName, scheduledTime, scheduledNotifications, updatedNotifications);
                    notificationsToCancel = [...notificationsToCancel, ...notificationsToRemove];
                    notificationsToSchedule = [...notificationsToSchedule, ...notificationsToAdd];
                }

                // if this task has an upcoming scheduled time and it was completed within the adherence window for that time,
                // we need to remove the task from the notification and reminder for that time
                const nextSchedule = task.getNextSchedule();
                const taskLastCompleted: moment.Moment = moment(task.lastCompleted, moment.HTML5_FMT.TIME_SECONDS);

                if (nextSchedule && nextSchedule.inAdherenceWindow(taskLastCompleted)) {
                    let scheduledTime: moment.Moment = moment(nextSchedule.at, moment.HTML5_FMT.TIME_SECONDS);
                    let [notificationsToRemove, notificationsToAdd] = this.getUpdatesToScheduledReminders(moduleName, scheduledTime, scheduledNotifications, updatedNotifications);
                    notificationsToCancel = [...notificationsToCancel, ...notificationsToRemove];
                    notificationsToSchedule = [...notificationsToSchedule, ...notificationsToAdd];

                    if (nextSchedule.reminder) {
                        scheduledTime = moment(nextSchedule.reminder, moment.HTML5_FMT.TIME_SECONDS);
                        [notificationsToRemove, notificationsToAdd] = this.getUpdatesToScheduledReminders(moduleName, scheduledTime, scheduledNotifications, updatedNotifications);
                        notificationsToCancel = [...notificationsToCancel, ...notificationsToRemove];
                        notificationsToSchedule = [...notificationsToSchedule, ...notificationsToAdd];
                    }
                }
            }
            this.localNotifications.cancel(notificationsToCancel).then(() => {
                this.logger.phic.debug(notificationsToCancel.length + ' old/invalid notifications cancelled');
                if (notificationsToSchedule.length) {
                    // set unique notification ids at the end of the existing notifications
                    notificationsToSchedule.forEach((notification) => {
                        notification.id = ++highestNotificationId;
                    });
                    this.localNotifications.schedule(notificationsToSchedule);
                    this.logger.phic.debug(notificationsToSchedule.length + ' new/updated notifications scheduled');
                } else {
                    this.logger.phic.debug('did not find any notifications to add for ', task.id);
                }
            });
        });
    }

    /**
     * Build All Notifications AND Their Second Reminder Notifications
     */
    private buildAllNotifications(tasks: Task[], multipleReminders?: Reminder[]): ILocalNotification[] {
        let latestNotifications = [];

        // Even though each survey task has a schedule, they all have the same reminder time per day, so we only set up one notification so
        // that we don't spam the user with a whole bunch of survey notifications all at once. But some surveys may have been answered
        // already today while others haven't. So these are used to determine whether we notify the user today or can defer until tomorrow.
        let surveyAdded: boolean;
        let surveyNotification: ILocalNotification;
        let surveyReminder: ILocalNotification;

        if (!tasks || !tasks.length) {
            return latestNotifications;
        }

        if (this.hasMultipleReminders) {
            latestNotifications = this.buildMultipleRemindersNotifications(tasks, multipleReminders);
        }

        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.type === TaskType.Survey && surveyAdded) {
                continue;
            }

            // these were all handled earlier in buildMultipleReminderNotifications()
            if (this.hasMultipleReminders && MULTIPLE_REMINDER_TYPES.includes(task.type)) {
                continue;
            }

            if (task.schedule) {
                task.schedule.forEach((schedule) => {
                    // It's possible that an expired medication will be returned from the backend, in this event we need to
                    // identify it and make sure that it isn't scheduled
                    if (schedule.end && moment(schedule.end).isBefore(moment())) {
                        return;
                    }

                    let notification = this.buildSingleNotification(task);
                    let reminder;
                    if (!notification) {
                        return;
                    }

                    notification.id = latestNotifications.length;

                    if (schedule.reminder) {
                        const updated = this.buildReminder(task, schedule);
                        reminder = this.buildSingleNotification(updated);
                        reminder.id = latestNotifications.length + 1;
                    }

                    if (task.type === TaskType.Survey) {
                        if (task.isScheduled() && !task.isCompleted()) {
                            latestNotifications.push(notification);
                            if (reminder) {
                                latestNotifications.push(reminder);
                            }
                            surveyAdded = true;
                        }
                        surveyNotification = notification;
                        surveyReminder = reminder;
                    } else {
                        latestNotifications.push(notification);
                        if (reminder) {
                            latestNotifications.push(reminder);
                        }
                    }
                });
            }
        }

        if (surveyNotification && !surveyAdded) {
            // We didn't find a survey that is still due for today, but we still need to add a notification that will trigger tomorrow.
            latestNotifications.push(surveyNotification);
            if (surveyReminder) {
                latestNotifications.push(surveyReminder);
            }
        }

        return latestNotifications;
    }

    private buildSingleNotification(task: Task): ILocalNotification {
        // tasks processed here have a single scheduled time
        const schedule = task.schedule ? task.schedule[0] : null;

        if (!schedule || !schedule.at) {
            return;
        }

        const scheduledTime = moment(schedule.at, moment.HTML5_FMT.TIME_SECONDS);
        if (!scheduledTime.isValid()) {
            this.logger.phic.error('Reminder time invalid for ' + task.type + ': ' + schedule.at);
            return;
        }

        if (schedule.end && moment(schedule.end).isBefore(moment())) {
            return;
        }

        let notificationText;
        if (task.type === TaskType.Medication) {
            notificationText = this.translate.instant('NOTIFICATION_MEDICATION_REMINDER_TEXT', {medication: (task as MedicationTask).name});
        } else {
            notificationText = this.translate.instant('NOTIFICATION_REMINDER_TEXT', {metric: task.title.toLowerCase()});
        }

        let notification: ILocalNotification = {
            // The ID is set to 0 (the default) for now, but a unique integer ID is required, otherwise only the last one having the same ID
            // end up getting scheduled. We'll be updating this value later before actually scheduling it.
            id: 0,
            title: this.translate.instant('NOTIFICATION_REMINDER_TITLE', {metric: task.title}),
            text: notificationText,
            trigger: {
                every: {
                    hour: scheduledTime.hours(),
                    minute: scheduledTime.minutes()
                },
            },
            foreground: true,
            data: {task: task}
        };

        if (this.platform.is('android')) {
            notification.smallIcon = 'res://ic_stat_access_alarm';
            // starting Android 8.0+, all notifications are assigned a channel: https://developer.android.com/training/notify-user/channels
            // 'default-channel-id' is the name of channel created by the Local Notifications plugin
            notification.channel = 'default-channel-id';
        }

        if (!task.isScheduled() || task.isCompleted()) {
            // Task isn't scheduled for today or it has been already completed for today, so we won't show the notification until tomorrow
            if (this.platform.is('ios')) {
                let futureDate = moment().locale('en').add(1, 'days').hours(scheduledTime.hours()).minutes(scheduledTime.minutes()).format('MM/DD/YYYY hh:mm A');
                // for non-western languages moment converts the date string to a different character set that the server doesn't like so setting __locale()__
                // to english to ensure we get a date string in western characters (only an issue for non-western languages like arabic and hindi).
                // This fixed an issue where the iOS app was crashing in arabic and hindi.
                notification.trigger = {at: new Date(futureDate)};
            } else {
                notification.trigger.after = new Date(new Date().setHours(23, 59, 0, 0));
            }
        }

        if (schedule.end && schedule.at) {
            // It's unclear if the `before` field is supported by iOS, but in theory assigning this with the expiry
            // of the medication should stop the notification from firing if the date has passed. If nothing else,
            // this allows us to keep track of the task's end date after the notification has been created.
            notification.trigger.before = new Date(schedule.end + ' ' + schedule.at);
        }

        return notification;
    }

    private buildMultipleRemindersNotifications(tasks: Task[], multipleReminders: Reminder[]): ILocalNotification[] {
        let notifications = [];
        if (!multipleReminders || multipleReminders.length < 1) {
            return notifications;
        }

        const buildModuleText = (moduleList: string[]) => {
            let lastModule = moduleList.pop();
            let modulesText = moduleList.join(', ');
            modulesText += (moduleList.length ? ' and ' : '') + lastModule;
            moduleList.push(lastModule);
            return modulesText;
        };

        const buildNotification = (scheduledTime: moment.Moment, moduleList: string[], id: string, singleTaskTitle: string, task?: Task): ILocalNotification => {
            let notification: ILocalNotification = {
                // The ID is set to 0 (the default) for now, but we'll be updating this value later before actually scheduling it.
                id: 0,
                data: {
                    id: id
                },
                title: this.translate.instant('NOTIFICATION_MULTIPLE_REMINDER_TITLE'),
                text: this.translate.instant('NOTIFICATION_REMINDER_TEXT', {metric: buildModuleText(moduleList)}),
                trigger: {
                    every: {
                        hour: scheduledTime.hours(),
                        minute: scheduledTime.minutes()
                    },
                },
                foreground: true
            };

            // if a task was passed in, this notification is for a single task
            if (task) {
                notification.title = singleTaskTitle;
                notification.data.task = task;
            }

            if (this.platform.is('android')) {
                notification.smallIcon = 'res://ic_stat_access_alarm';
                // starting Android 8.0+, all notifications are assigned a channel: https://developer.android.com/training/notify-user/channels
                // 'default-channel-id' is the name of channel created by the Local Notifications plugin
                notification.channel = 'default-channel-id';
            }
            return notification;
        };

        multipleReminders.forEach((reminder) => {
            if (reminder.schedule && reminder.modules && reminder.modules.length >= 1) {
                let modulesDue: string[] = [];
                let numModulesDue: number = 0;
                let moduleDueTask;
                let allModules: string[] = [];
                let numModules: number = 0;
                let moduleTask;
                let notification;
                let notificationReminder;

                const scheduledTime = moment(reminder.schedule.at, moment.HTML5_FMT.TIME_SECONDS);
                if (!scheduledTime.isValid()) {
                    this.logger.phic.error('Reminder time invalid for multiple reminder:' + reminder.id);
                    return;
                }
                if (reminder.schedule.end && moment(reminder.schedule.end).isBefore(moment())) {
                    return;
                }
                reminder.modules.forEach((module) => {
                    const taskForModule = tasks.find((task) => task.type === module.name);
                    if (taskForModule) {
                        numModules++;
                        moduleTask = taskForModule;
                        allModules.push(moduleTask.title.toLowerCase());
                        const taskLastCompleted: moment.Moment = moment(moduleTask.lastCompleted, moment.HTML5_FMT.TIME_SECONDS);
                        // task is still due if it was not completed or if it was completed but not within the adherence window of this schedule
                        if (!moduleTask.isCompleted() || !reminder.schedule.inAdherenceWindow(taskLastCompleted)) {
                            numModulesDue++;
                            moduleDueTask = moduleTask;
                            modulesDue.push(moduleTask.title.toLowerCase());
                        }
                    }
                });
                // if we have modules, build the notification/reminder pair for all the tasks
                if (numModules >= 1) {
                    const notificationTitle = this.translate.instant('NOTIFICATION_REMINDER_TITLE', {metric: moduleTask.title});
                    let reminderId: string = reminder.id;
                    notification = buildNotification(scheduledTime, allModules, reminderId, notificationTitle, (numModules === 1 ? moduleTask : null));
                    if (reminder.schedule.reminder) {
                        const reminderTime: moment.Moment = moment(reminder.schedule.reminder, moment.HTML5_FMT.TIME_SECONDS);
                        reminderId = reminder.id + '-reminder';
                        notificationReminder = buildNotification(reminderTime, allModules, reminderId, notificationTitle, (numModules === 1 ? moduleTask : null));
                    }
                }
                if (!notification) {
                    return;
                }

                // the notifications generated above include all tasks/modules for this time slot
                // if there are any tasks/modules that were completed, they should not be in the reminder for today, so
                // we will need to build a separate notification/reminder pair for just those tasks/modules that are still due
                // we will do that in a bit; for now, mark the notifications already generated to not trigger until after tomorrow
                if (numModulesDue != numModules) {
                    // trigger.every.hour & trigger.every.minute combined with trigger.after does not work on ios, the notification will still be triggered before the date/time in trigger.after
                    // trigger.at combined with trigger.every (i.e. 'day') does not work on iOS, LocalNotifications.schedule() will reject all notifications passed in
                    // trigger.firstAt combined with trigger.every (i.e. 'day') does not work on iOS or Android, the notifications will not trigger at the correct time the following day
                    // therefore, we will continue to use trigger.every.hour, trigger.every.minute combined with trigger.after on Android
                    // and for iOS we will hold these reminders to be scheduled after midnight (manual, brute force method)
                    if (this.platform.is('ios')) {
                        this.delayNotification(notification);
                        if (notificationReminder) {
                            this.delayNotification(notificationReminder);
                        }
                    } else {
                        const tomorrow: Date = new Date();
                        tomorrow.setHours(0, 0, 0, 0);
                        tomorrow.setDate(tomorrow.getDate() + 1);

                        notification.trigger.after = tomorrow;
                        if (notificationReminder) {
                            notificationReminder.trigger.after = tomorrow;
                        }
                    }
                }

                // for iOS, rescheduled notications will be handled through scheduleDelayedNotifications()
                if (!(this.platform.is('ios') && numModulesDue != numModules)) {
                    notification.id = notifications.length;
                    notifications.push(notification);
                    if (notificationReminder) {
                        notificationReminder.id = notifications.length;
                        notifications.push(notificationReminder);
                    }
                }

                // here we build the separate notification/reminder pair for just those tasks/modules that are still due today
                // and we'll mark these notifications to not trigger after today
                if (numModulesDue && (numModulesDue != numModules)) {
                    const notificationTitle = this.translate.instant('NOTIFICATION_REMINDER_TITLE', {metric: moduleDueTask.title});
                    const scheduledDate = new Date();
                    let reminderId: string = 'A-' + reminder.id;
                    notification = buildNotification(scheduledTime, modulesDue, reminderId, notificationTitle, (modulesDue.length === 1 ? moduleDueTask : null));
                    scheduledDate.setHours(scheduledTime.hour(), scheduledTime.minute(), 0, 0);
                    notification.trigger = {at: scheduledDate};

                    if (reminder.schedule.reminder) {
                        const reminderDate = new Date();
                        const reminderTime: moment.Moment = moment(reminder.schedule.reminder, moment.HTML5_FMT.TIME_SECONDS);
                        reminderId = 'A-' + reminder.id + '-reminder';
                        notificationReminder = buildNotification(reminderTime, modulesDue, reminderId, notificationTitle, (modulesDue.length === 1 ? moduleDueTask : null));
                        reminderDate.setHours(reminderTime.hour(), reminderTime.minute(), 0, 0);
                        notificationReminder.trigger = {at: reminderDate};
                    }

                    notification.id = notifications.length;
                    notifications.push(notification);
                    if (notificationReminder) {
                        notificationReminder.id = notifications.length;
                        notifications.push(notificationReminder);
                    }
                }
            }
        });

        return notifications;
    }

    private scheduleNotifications(latestNotifications: ILocalNotification[]): void {
        this.logger.phic.debug(latestNotifications.length + ' latest notifications');

        this.localNotifications.getAll().then((existingNotifications) => {
            existingNotifications = this.filterForTaskNotifications(existingNotifications);
            this.logger.phic.debug(existingNotifications.length + ' existing notifications');

            // We have to reconcile differences between previously-scheduled notifications and the new list of notifications we just received.
            // We could just cancel all the existing notifications in one fell swoop and then schedule all the new ones, but if any notifications
            // are currently visible then that would remove those and could show the new ones immediately, which is a bad experience.
            // So instead, here we compare the two lists of notifications and cancel/schedule them only if there are differences.

            let idsToCancel = [];
            let notificationsToSchedule = [];

            if (existingNotifications.length) {
                [idsToCancel, notificationsToSchedule] = this.parseExistingNotifications(existingNotifications, latestNotifications);
                notificationsToSchedule = notificationsToSchedule.concat(this.getNewNotifications(existingNotifications, latestNotifications));
            } else {
                notificationsToSchedule = latestNotifications;
            }
            this.setNotificationIDs(existingNotifications, notificationsToSchedule);

            this.localNotifications.cancel(idsToCancel).then(() => {
                this.logger.phic.debug(idsToCancel.length + ' old/invalid notifications cancelled');

                if (notificationsToSchedule.length) {
                    this.localNotifications.schedule(notificationsToSchedule);
                    this.logger.phic.debug(notificationsToSchedule.length + ' new/updated notifications scheduled');
                }
            });
        });
    }

    private isSameNotificationType(existingNotification: ILocalNotification, latestNotification: ILocalNotification): boolean {
        // for multiple reminders Task may not be included, but a notification id will have been assigned
        if (this.hasMultipleReminders && latestNotification.data.hasOwnProperty('id') && existingNotification.data.hasOwnProperty('id')) {
            return latestNotification.data.id === existingNotification.data.id;
        }
        return this.hasMatchingTask(existingNotification, latestNotification);
    }

    /**
     * Get which of the existing notifications need to be canceled or updated
     */
    private parseExistingNotifications(existingNotifications: ILocalNotification[], latestNotifications: ILocalNotification[]): [number[], ILocalNotification[]] {
        let idsToCancel: number[] = [];
        let notificationsToSchedule: ILocalNotification[] = [];

        for (let i = 0; i < existingNotifications.length; i++) {
            let existingNotification = existingNotifications[i];
            try {
                existingNotification.data = JSON.parse(existingNotification.data);
            } catch (ex) {
                this.logger.phic.info('Error parsing notification data', ex);
                idsToCancel.push(existingNotification.id);
                continue;
            }

            if (latestNotifications) {
                let found = false;
                for (let j = 0; j < latestNotifications.length; j++) {
                    let latestNotification = latestNotifications[j];

                    if (this.isSameNotificationType(existingNotification, latestNotification)) {
                        found = true;
                        if (!this.scheduledTimesMatch(latestNotification.trigger, existingNotification.trigger)) {
                            // Update the schedule by canceling the old and scheduling the new
                            idsToCancel.push(existingNotification.id);
                            notificationsToSchedule.push(latestNotification);
                        } else {
                            // The new schedule is the same as the old schedule, so we don't need to do anything.
                        }
                        break;
                    }
                    // If a task with an expired end date has failed to be filtered out, this redundant conditional should
                    // catch the resulting notification and force it to be canceled.
                    if (latestNotification.trigger.before && moment(latestNotification.trigger.before).isBefore(moment())) {
                        idsToCancel.push(latestNotification.id);
                    }
                }
                if (!found) {
                    // There is no longer a new notification corresponding to the old one, so cancel it.
                    idsToCancel.push(existingNotification.id);
                }
            } else {
                // There are no new notifications at all, so we will be canceling all of them by getting each ID individually here.
                idsToCancel.push(existingNotification.id);
            }
        }

        return [idsToCancel, notificationsToSchedule];
    }

    /**
     * Get new notifications that don't have a corresponding existing notification
     */
    public getNewNotifications(existingNotifications: ILocalNotification[], latestNotifications: ILocalNotification[]) {
        let newNotifications: ILocalNotification[] = [];
        if (latestNotifications) {
            for (let i = 0; i < latestNotifications.length; i++) {
                let latestNotification = latestNotifications[i];
                let found = existingNotifications && !!existingNotifications.find((existingNotification) => {
                    return this.scheduledTimesMatch(existingNotification.trigger, latestNotification.trigger) && this.isSameNotificationType(existingNotification, latestNotification);
                });
                if (!found) {
                    newNotifications.push(latestNotification);
                }
            }
        }

        return newNotifications;
    }

    /**
     * Renumber IDs of the new notifications to avoid collisions with IDs of existing notifications
     */
    private setNotificationIDs(existingNotifications: ILocalNotification[], notificationsToSchedule: ILocalNotification[]): void {
        if (notificationsToSchedule) {
            for (let i = 0, j = 0, id = 0; i < notificationsToSchedule.length; i++, id++) {
                for (; j < existingNotifications.length; j++) {
                    if (id === existingNotifications[j].id) {
                        id = existingNotifications[j].id + 1;
                    } else {
                        break;
                    }
                }
                notificationsToSchedule[i].id = id;
            }
        }
    }

    private hasMatchingTask(currentNotification: ILocalNotification, futureNotification: ILocalNotification) {
        if (typeof currentNotification.data === 'string') currentNotification.data = JSON.parse(currentNotification.data);
        if (typeof futureNotification.data === 'string') futureNotification.data = JSON.parse(futureNotification.data);
        // Even though we may have many survey objects, they all have the exact same schedule so we only create a single survey notification
        if (currentNotification.data.task && futureNotification.data.task) {
            const hasSameId = currentNotification.data.task.id === futureNotification.data.task.id;
            const isSurvey = futureNotification.data.task.type === TaskType.Survey && currentNotification.data.task.type === TaskType.Survey;
            // Check if both notifications are second reminders or not
            const areSameType = (currentNotification.data.task.id.includes('reminder') === futureNotification.data.task.id.includes('reminder'));

            return (hasSameId || isSurvey) && areSameType;
        }
        return false;
    }

    /**
     * Build Reminder Task by replacing original schedule time with reminder time
     */
    public buildReminder(task: Task, schedule: Schedule) {
        let updated;

        switch (task.type) {
            case 'medication':
                updated = new MedicationTask({
                    ...task,
                    id: task.id + '-reminder',
                    schedule: [new Schedule({
                        ...schedule,
                        at: schedule.reminder,
                        reminder: undefined
                    })]
                });
                break;
            case 'survey':
                updated = new SurveyTask({
                    ...task,
                    id: task.id + '-reminder',
                    schedule: [new Schedule({
                        ...schedule,
                        at: schedule.reminder,
                        reminder: undefined
                    })]
                });
                break;
            default:
                updated = new Task({
                    ...task,
                    id: task.id + '-reminder',
                    schedule: [new Schedule({
                        ...schedule,
                        at: schedule.reminder,
                        reminder: undefined
                    })]
                });
        }

        return updated;
    }
}
