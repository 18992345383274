import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';
import {ILocalNotification, LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {BuildUtility} from '@hrs/utility';
import {
    TTS,
    TTSCordovaInterface,
    TTSEvent,
    TTSEventType,
    TTSOptions,
    TTSVoice
} from 'cordova-plugin-tts-advanced';
import {AudioService} from '../audio/audio.service';
import {LanguageService} from '../language/language.service';
import {ISOValuesWithCountry} from '../../enums/supported-languages.enum';
import {EventService} from '../events/event.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {overlayType} from '../../hrs-overlay';
import {getLogger} from '@hrs/logging';

declare var navigator: any;

@Injectable({
    providedIn: 'root',
})
export class TextToSpeechService {
    public canBeForceStopped: boolean = false;
    public ttsForceStopped$: Subject<void> = new Subject<void>();
    private isHRSTablet: boolean = BuildUtility.isHRSTab();
    private speechRate: number;
    private userLanguageSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private userLanguageSet$: Observable<any>;
    private userLanguage: string;
    private readonly localLogger = getLogger('TextToSpeechService');
    private readonly TTS: TTSCordovaInterface = TTS;
    private readonly TTSEventProxy: (ev: TTSEvent) => void;

    constructor(
        private audio: AudioService,
        private event: EventService,
        private translate: TranslateService,
        private language: LanguageService,
        private localNotifications: LocalNotifications,
        private platform: Platform
    ) {
        this.userLanguageSet$ = this.userLanguageSet.asObservable();
        this.TTSEventProxy = this.onTTSEvent.bind(this);
    }

    public getLanguage(): string {
        return this.userLanguage;
    }

    public getSpeechRate(): number {
        return this.speechRate;
    }

    public initialize(): void {
        this.subscribeToListeners();
        const ISOLanguageCode = this.language.getUserLanguage();
        if (this.isHRSTablet && ISOLanguageCode) {
            this.userLanguage = ISOValuesWithCountry[ISOLanguageCode];
            this.userLanguageSet.next(true);
        } else {
            navigator.globalization.getPreferredLanguage((res: { value: string }) => {
                // Returns ISO 639‑1 Language Code with ISO 3166‑1 Country Code e.g. 'en-US'.
                this.userLanguage = res.value;
                this.userLanguageSet.next(true);
            }, (err: { code: number, message: string }) => {
                this.localLogger.error('Failed getting preferred language', err);
            });
        }

        // There is a known issue with the plugin that speech rate is fast on iOS devices.
        // Compensate for faster speech by defaulting to a lower rate.
        this.speechRate = this.platform.is('ios') ? .5 : 1;
        this.TTS.setSharedEventDelegate(this.TTSEventProxy);
    }

    /**
     * Listen for metric notifications and speak notification titles on trigger.
     */
    private subscribeToListeners(): void {
        this.language.languageChange.subscribe((updatedLang: string) => {
            this.localLogger.debug('languageChange subscription, langauge was ' + this.userLanguage + ', changed to ' + updatedLang);
            this.userLanguage = ISOValuesWithCountry[updatedLang];
            this.userLanguageSet.next(true);
        });

        this.localNotifications.on('trigger').subscribe( async (notification: ILocalNotification) => {
            this.localLogger.debug('localNotifications.on(trigger) subscription', notification);
            // if tablet user had turned off audio alerts then they will be silenced here
            let speakTitle = this.isHRSTablet ? !this.audio.notificationsAreSilent() : false;
            if (speakTitle) {
                await this.speak(notification.title, true);
            }
        });

        this.event.overlayOpened.subscribe((overlayType: overlayType) => {
            this.localLogger.debug('overlayOpened subscription', overlayType);
            if (this.canBeForceStopped) {
                this.forceStop();
            }
        });
    }

    private onTTSEvent(ev: TTSEvent): void {
        if (ev?.type === TTSEventType.TTS_READY) {
            this.localLogger.debug('onTTSEvent - TTS_READY');
            // processing needs to wait for userLanguage to be set in this.initialize()
            this.userLanguageSet$.subscribe(() => {
                if (this.userLanguage) this.checkLanguageInstallation(this.userLanguage);
            });
        }
    }

    /**
     * Take translation key and output speech in device's language.
     */
    public async speak(key: string, isNotification: boolean = false): Promise<void> {
        this.localLogger.debug('speak()');
        if (this.canBeForceStopped) {
            this.forceStop();
        }

        // notification TTS should never be canceled, so if this is a notification canBeForceStopped should be false and should be true for all other TTS
        this.canBeForceStopped = !isNotification;
        let opts: TTSOptions = {
            text: this.translate.instant(key),
            locale: this.userLanguage || 'en-US',
            rate: this.speechRate,
        };

        try {
            await this.TTS.speak(opts);
            this.canBeForceStopped = false;
        } catch (err) {
            this.localLogger.error('TTS.speak() failed', err);
        }
    }

    /**
     * Stop any current speech.
     */
    public stop(): void {
        this.localLogger.debug('stop()');
        this.canBeForceStopped = false;
        this.TTS.stop();
    }

    /**
     * Emit Force Stopped event so other components can update UI accordingly.
     */
    public forceStop(): void {
        this.localLogger.debug('forceStop()');
        this.ttsForceStopped$.next();
        this.stop();
    }

    /**
     * Adjust speech rate.
     */
    public adjustSpeechRate(rate: number): void {
        this.speechRate = rate;
    }

    private async checkLanguageInstallation(userLanguage: string) {
        this.localLogger.debug('checkLanguageInstallation for user language: ', userLanguage);
        if (!userLanguage) return;

        const defaultTTSEngine: String = await this.TTS.getDefaultEngine();

        // 10-2024: Samsung TTS Engine only supports Engish and Spanish and those are pre-installed on Samsung tablets
        // Samsung TTS Engine will handle English/Spanish but any other language it will attempt to pronounce text in the
        // other language with the currently set English/Spanish voice and it is terrible
        // don't bother checking for language support or prompting user to install it if we're working with Samsung TTS,
        // this prompt installs a Google TTS Voice which the Samsung TTS Engine cannot use
        if (!defaultTTSEngine.toLowerCase().includes('samsung')) {
            try {
                const voices: TTSVoice[] = await this.TTS.getVoices();
                this.localLogger.debug('TTS voices supported: ', voices);
                const voiceForLocale: TTSVoice = voices.find((voice) => {
                    return voice.name?.includes(userLanguage);
                });
                if (voiceForLocale) {
                    this.localLogger.info(userLanguage + ' is installed');
                } else {
                    this.localLogger.debug(userLanguage + ' is not installed');
                    this.TTS.openInstallTts();
                }
            } catch (error) {
                this.localLogger.error('Error checking TTS language installation:', error);
            }
        }
    }
}
