import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@hrs/providers';
import {WelchWeightService} from './welch/welch.weight.service';
import {OverlayService} from '../overlay/overlay.service';
import {OverlayRef} from '../../hrs-overlay';
import {AnDClassicScaleService} from './andclassic/andclassic.scales.service';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {HRSFirebaseAnalytics, HRSFirebaseErrorReason, HRSFirebaseEvents, HRSFirebaseParams} from '../analytics/firebaseanalytics.service';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class WeightDeviceService {
    private readonly logger = getLogger('WeightDeviceService');
    weight: number = 0;
    metricChange = new Subject();
    bluetoothMetricChange$: Observable<any>;

    isRecentReading: boolean;
    historicalReadingObtained: boolean;
    readingErrorOccurred: boolean;

    // Services and Characteristics to connect to device
    FORACARE_SERVICE = '00001523-1212-EFDE-1523-785FEABCD123';
    FORACARE_CHARACTERISTIC = '00001524-1212-EFDE-1523-785FEABCD123';
    DATA_MESSAGE_MODE: boolean = false;
    DATA_MESSAGE: any;
    DATA_MESSAGE_POSITION: number = 0;

    // Foracare Byte Commands and Request Messages
    CMD_ACK = 0x51; // 81
    CMD_START = 81;
    CMD_END = 163;
    CMD_COMMS = 0x54; // 84
    CMD_DATA_COUNT = 0x2B; // 43
    CMD_DATA_TIME = 0x25; // 37
    CMD_DATA = 0x26; // 38
    CMD_DATA_SCALE = 0x71; // 113
    CMD_DATA_CLEAR = 0x52; // 82
    CMD_TAKE_MEASUREMENT = 0x41; // 65
    CMD_POWER_OFF = 0x50; // 80
    CMD_DATA_POINTS = 0x2; // 2
    private AND_CHAR_DATETIME: string = '2a08';
    private AND_SERVICE: string = '23434100-1fe4-1eff-80cb-00ff78297d8b';
    private AND_CUSTOM_SERVICE = '233bf000-5a34-1b6d-975c-000d5690abe4';
    private AND_CUSTOM_CHAR = '233bf001-5a34-1b6d-975c-000d5690abe4';
    private maxTimeDifference: number = 60;
    private AND_SCALE_356_SERVICE = 'cf1e9810-d4fc-0ba7-0a9e-cdaf76853000';
    private AND_SCALE_356_CHARACTERISTIC = 'cf1e9811-4fc0-ba70-a9ec-daf768530000';
    private AND_SCALE_356_CURRENT_TIME_CHAR_STANDARD = '2A2B';
    private AND_SCALE_356_CMD_LATEST_STORED_DATA = 0xE5;

    constructor(
        private ble: BLE,
        private modalService: ModalService,
        private ngZone: NgZone,
        private overlay: OverlayService,
        private translateService: TranslateService,
        private welchWeightService: WelchWeightService,
        private fbanalytics: HRSFirebaseAnalytics,
        private andClassicWeightService: AnDClassicScaleService
    ) {
        this.bluetoothMetricChange$ = this.metricChange.asObservable();
    }

    onWeightMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, peripheralType);
        this.logger.phic.debug('onWeightMetricMeasurementChange ' + peripheralName );
        if (
            peripheralType == 'weight' &&
            peripheralName.includes('TNG SCALE') &&
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                let arrayBuffer = new Uint8Array(buffer);
                this.logger.phic.debug('Got data from Weight device ' + peripheralName + ' ' + BluetoothUtils.buf2hex(arrayBuffer));
                if (arrayBuffer.length === 8 && arrayBuffer[3] !== 0 && arrayBuffer[4] === 0 && arrayBuffer[1] !== this.CMD_DATA_SCALE) {
                    let weightCalculation = ((arrayBuffer[2] & 0xFF) * 256) + (arrayBuffer[3] & 0xFF);
                    this.weight = weightCalculation / 10.0;

                    // This is checking validity because in rare situations the timing is off on write characteristic and it gives a high calculation
                    if (this.weight < 1000 && this.weight > 0) {
                        this.logger.phic.debug('Weight recieved for TNG SCALE ' + this.weight);
                        this.metricChange.next(peripheralName);
                        this.isRecentReading = true;
                        this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi});
                    } else {
                        this.logger.phic.debug('Invalid reading for TNG SCALE' + this.weight);
                        this.invalidReadingError();
                        this.readingErrorOccurred = true;
                        this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                            {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.VALIDATION_FAILURE});
                    }
                    this.logger.phic.debug('TNG SCALE : clear memory command issued ');
                    let dataMeasurement = this.addCheckSum([81, 0x52, 0, 0, 0, 0, 163]);
                    this.writeForacareWeightCharacteristic(peripheral, dataMeasurement, false);
                    this.logger.phic.debug('TNG SCALE : request power off command issued ');
                    let requestPowerOff = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                    this.writeForacareWeightCharacteristic(peripheral, requestPowerOff, false);
                } else if (arrayBuffer[1] === this.CMD_DATA_SCALE) {
                    let dataByte = arrayBuffer[2];
                    this.DATA_MESSAGE = new Uint8Array(dataByte);
                    this.DATA_MESSAGE_POSITION = 0;
                    this.updateMessageBuffer(arrayBuffer);
                } else if (arrayBuffer[1] === this.CMD_DATA_COUNT) {
                    this.logger.phic.debug('TNG SCALE : scale data command issued ');
                    let dataDatetime = this.addCheckSum([81, 0x71, 2, 0, 0, 163]);
                    this.writeForacareWeightCharacteristic(peripheral, dataDatetime, false);
                } else if (arrayBuffer[1] == this.CMD_DATA_CLEAR) {
                    this.logger.phic.debug('TNG SCALE : power off command issued ');
                    let requestPowerOff = this.addCheckSum([81, 0x50, 0, 0, 0, 0, 163]);
                    this.writeForacareWeightCharacteristic(peripheral, requestPowerOff, false);
                }
            });
        } else if (peripheralType == 'weight' && peripheralName.includes('UC-352') && !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                this.logger.phic.debug('Will parse AnD UC-352 Scale data obtained');
                this.parseAndSubmitDataForUC352(buffer, peripheral, peripheralType);
            });
        } else if (peripheralType == 'weight' && peripheralName.includes('UC-356') && !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                this.parseAndSubmitDataForUC356(buffer, peripheral, peripheralType);
            });
        }
    }

    public onDeviceDisconnected(peripheral: any, isPairingFlow: boolean): void {
        if (peripheral?.name?.includes('UC-355') || peripheral?.name?.includes('UC-351') ) {
            this.andClassicWeightService.onDeviceDisconnected(peripheral, isPairingFlow);
        } else if (peripheral?.name?.includes('SC100')) {
            this.welchWeightService.onDeviceDisconnected(peripheral, isPairingFlow);
        } else {
            if (!isPairingFlow) {
                let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'weight');
                if (!this.isRecentReading && !this.readingErrorOccurred) {
                    let reason = this.historicalReadingObtained ? HRSFirebaseErrorReason.HISTORICAL_READING_ONLY : HRSFirebaseErrorReason.NO_RECENT_READING;
                    this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                        {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                            [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: reason});
                }
            }
            // cleanup
            this.isRecentReading = false;
            this.historicalReadingObtained = false;
            this.readingErrorOccurred = false;
        }
    }

    /**
      * Helper utility method to convert array buffer to hex
      */
    protected buf2hex(buffer: ArrayBuffer): string {
        const byteArray = new Uint8Array(buffer);
        const hexParts = [];
        for (let i = 0; i < byteArray.length; i++) {
            const hex = byteArray[i].toString(16);
            const paddedHex = ('00' + hex).slice(-2);
            hexParts.push(paddedHex);
        }

        // join all the hex values of the elements into a single string
        return hexParts.join(' ');
    }

    // If the reading is invalid, it asks the user to repeat the measurement
    private async invalidReadingError(): Promise<OverlayRef> {
        return await this.overlay.openToast({
            message: this.translateService.instant('BLUETOOTH.INVALID_READING'),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'weight_toast'
        });
    }

    onACLConnect(peripheral: any): void {
        if (peripheral.name.includes('TNG SCALE')) {
            let dataCount = this.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
            this.writeForacareWeightCharacteristic(peripheral, dataCount);
        }
    }

    writeForacareWeightCharacteristic(peripheral: any, data: any, clear?: boolean): void {
        let peripheralId = peripheral.id;
        let peripheralName = peripheral.name;
        this.ble.write(peripheralId, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, data.buffer).then(
            (res) => {
                if (!clear) {
                    this.onWeightMetricMeasurementChange(data.buffer, 'weight', peripheral);
                }
            },
            (error) => {
                this.logger.phic.error('Failed to write characteristic from device ' + peripheralName, error);
            });
    }

    updateMessageBuffer(arrayBuffer): void {
        for (let i = 0; (i < arrayBuffer.length && this.DATA_MESSAGE_POSITION < this.DATA_MESSAGE.length); i++) {
            this.DATA_MESSAGE[this.DATA_MESSAGE_POSITION] = arrayBuffer[i];
            this.DATA_MESSAGE_POSITION++;
        }
    }

    /**
     * Foracare documentation requires a check sum [1...n-1]. Checksum appends as the last byte of the transmission
     * you get n bytes, sum of the first n-1 bytes, see if the answer is the same as the last byte, etc
     * This is so that we get our full 8 byte check.
     */
    addCheckSum(bytes: number[]): ArrayBuffer {
        let out = new Uint8Array(bytes.length + 1);
        let checksum = 0;
        for (let i = 0; i < bytes.length; i++) {
            checksum += (bytes[i] & 0xFF);
            out[i] = bytes[i];
        }
        out[out.length - 1] = checksum;
        return out;
    }

    /**
    * Scale devices will pass the parsed measurement data to the weight
    * service to process it further and post to server
    * @param weight
    * @param peripheralName
    */
    public onMeasurementReceived(weight: number, peripheral: any): void {
        let peripheralName = peripheral.name;
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, 'weight');

        if (
            !this.modalService.getModalStatus('GenericMetricPage')) {
            this.ngZone.run(() => {
                this.logger.phic.debug('On Metric update callback recieved weight as ' + weight);
                this.weight = weight;
                this.metricChange.next(peripheralName);
                this.isRecentReading = true;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: 'weight',
                        [HRSFirebaseParams.RSSI]: peripheral.rssi});
            });
        } else {
            this.logger.phic.log('On Measurement recieved but unable to open metric page with weight ' + weight);
        }
    }

    /**
    * This will call the scale device onConnected flow
    * specifying a callback method to handle the device vital measurement data
    * @param peripheral
    */
    public onConnected(peripheral: any): void {
        if (peripheral.name.includes('UC-355') || peripheral.name.includes('UC-351') ) {
            this.andClassicWeightService.onConnected(peripheral, this.onMeasurementReceived.bind(this));
        } else if (peripheral.name.includes('SC100')) {
            this.welchWeightService.onConnected(peripheral, this.onMeasurementReceived.bind(this));
        } else if (peripheral.name.includes('UC-356')) {
            this.enableIndicationFor356(peripheral, this.AND_SCALE_356_SERVICE, this.AND_SCALE_356_CHARACTERISTIC);
        }
    }

    /**
     * This method will do any device setting or configuration related work for the
     * peripheral such as setting date/time etc.
     * @param peripheral
     */
    public configureDevice(peripheral: any): void {
        if (peripheral.name.includes('UC-352')) { // setting date and time on UA-651 as early as possible when device is connected
            this.logger.phic.debug('UC-352 trying to set time on device');
            this.setDateTimeForUC352Scale(peripheral);
        } else if (peripheral.name.includes('UC-356')) {
            this.logger.phic.debug('UC-356 trying to set time on device');
            this.setDateTimeForUC356Scale(peripheral);
        }
    }

    // ----************* Methods for UA-352 scale handling *****************---//

    private disconnectUA352Scale(peripheralId: any): void {
        var disconnectCmd = new Uint8Array([0x03, 0x02, 0x03, 0x00]);
        this.ble.write(peripheralId, this.AND_CUSTOM_SERVICE, this.AND_CUSTOM_CHAR, disconnectCmd.buffer).then(
            (data) => {
                this.logger.phic.debug('Success result of disconnecting BT for weight scale 352' + data);
            },
            (error) => {
                this.logger.phic.error('Failed to disconnect BT on AnD 352', error);
            });
    }

    /**
     * Sets the date and time on AnD UC-352 scale. This is needed for
     * discarding the historical data being obtained from device
     * @param peripheral
     */
    public setDateTimeForUC352Scale(peripheral: any): void {
        this.ble.write(peripheral.id, this.AND_SERVICE, this.AND_CHAR_DATETIME, BluetoothUtils.getTimeStampForAndBLEDevice().buffer).then(
            (data) => {
                this.logger.phic.debug('Success result of setting date time on AnD UC-352 scale');
            },
            (error) => {
                this.logger.phic.error('Failed to write date and time on AnD UC-352 scale', error);
            });
    }

    /**
     * This method parses the scale readings data and validates it. Based on the validations,
     * it decides whether it should be submitted further to the server or not. If it is an
     * invalid reading, a toast/pop-up is shown to the user.
     * @param buffer
     * @param peripheralName
     */
    public parseAndSubmitDataForUC352(rxPacket: ArrayBuffer, peripheral: any, peripheralType: string): void {
        let peripheralName = peripheral.name;
        let peripheralId = peripheral.id;
        let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, peripheralType);

        enum ByteOffset { // signifies the length of the data bytes for different reading params
            FLAGS_OFFSET = 1,
            C1_OFFSET = 2, // Scale measurements
            C2_OFFSET = 7, // Date - timestamp
            USER_ID_OFFSET = 1
        }
        let readingTimeStamp;
        let offset = 0; // offset will vary according to the data obtained for reading

        // Read flag value which indicates various parameters are present in the reading or not
        offset += ByteOffset.FLAGS_OFFSET;
        let flagIndexData = new Uint8Array(rxPacket.slice(0, offset));
        const flag = flagIndexData[0];
        this.logger.phic.debug('flag bits ' + flag);
        const isWeightInKg = ((flag & 0xff) & 0x01) == 0;
        const isTimeStampPresent = ((flag & 0xff) & (0x01 << 1)) != 0;
        const isUserIdPresent = ((flag & 0xff) & (0x01 << 2)) != 0;
        const isBMIAndHeightPresent = ((flag & 0xff) & (0x01 << 3)) != 0;

        this.logger.phic.debug('AnD UC-352 Flags present : isWeightInKg : ' + isWeightInKg + ' Timestamp ' + isTimeStampPresent + ' User Id flag ' + isUserIdPresent + ' BMI and Height flag ' + isBMIAndHeightPresent);

        // Read scale value
        offset += ByteOffset.C1_OFFSET;

        /* 352 BLE support reading up to one decimal place while 352 BLE-V support two decimal place */
        const decimalFactor = 10;

        /* Kilograms with resolution 0.005 or Pounds with resolution 0.01 */
        let weightIndexData = new Uint16Array(rxPacket.slice(ByteOffset.FLAGS_OFFSET, offset));
        let receivedWeight = weightIndexData[0];
        this.logger.phic.debug('AnD UC-352 Received weight: ' + receivedWeight);

        if (isWeightInKg) {
            // dev note -> manufacturer documentation says resolution factor for weight in kg is 0.005 but after using that weight calculation is not proper thus using 0.01 as used in lbs
            receivedWeight = receivedWeight * 0.01 * decimalFactor;
            this.weight = BluetoothUtils.convertKgWeightToPound(receivedWeight);
            this.logger.phic.debug('AnD UC-352 Received Weight in KG: ' + receivedWeight + ', Converted weight in Pound: ' + this.weight);
        } else {
            this.weight = receivedWeight * 0.01 * decimalFactor;
        }
        this.weight = BluetoothUtils.round(this.weight, 1);

        // Read date value
        offset += ByteOffset.C2_OFFSET;
        let timestampData = new Uint8Array(rxPacket);
        if (isTimeStampPresent) {
            readingTimeStamp = BluetoothUtils.fetchReadingTimestampForAnDBLEDevice(timestampData, ByteOffset.FLAGS_OFFSET + ByteOffset.C1_OFFSET);
        } else {
            this.logger.phic.debug('AnD UC-352 Time stamp not present with the reading, reading will be discarded');
        }

        if (isUserIdPresent) {
            offset += ByteOffset.USER_ID_OFFSET;
        }

        if (!isTimeStampPresent || BluetoothUtils.isAnOldReadingForAnDDevice(readingTimeStamp, this.maxTimeDifference)) { // discard historical data
            this.logger.phic.log('AnD UC-352: Discarding old reading. IsTimeStampPresent ' + isTimeStampPresent + ' Weight : ' + this.weight);
            this.historicalReadingObtained = true;
        } else { // checking if there is any garbage value captured before saving
            // we want to ignore those readings and let the user know to resubmit
            if (this.weight > 0) {
                this.logger.phic.debug('AnD UC-352: Is time stamp present for the valid reading? ' + isTimeStampPresent);
                this.logger.phic.debug('AnD UC-352: This is Latest Valid reading ' + this.weight);
                this.metricChange.next(peripheralName);
                this.isRecentReading = true;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.READING_OFFSET]: BluetoothUtils.getReadingTimeDifference(readingTimeStamp)});
                this.logger.phic.debug('Will pass readings to metric reading page for UA-352, now disconnect the device');
                // Will send disconnect command as the readings have been obtained and we wish to disconnect the BT connection
                this.disconnectUA352Scale(peripheralId);
            } else {
                this.weight = null;
                this.logger.phic.warn('Invalid reading obtained for AnD UC-352');
                this.invalidReadingError();
                this.readingErrorOccurred = true;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.VALIDATION_FAILURE});
            }
        }
    }
    // ---*********** functions for UC352 scale end ******************----//

    // ----************* Methods for UA-356 scale handling start *****************---//
    private setDateTimeForUC356Scale(peripheral: any): void {
        this.ble.write(peripheral.id, this.AND_SCALE_356_SERVICE, this.AND_SCALE_356_CURRENT_TIME_CHAR_STANDARD, BluetoothUtils.getTimeStampForAndBLEDevice(true).buffer).then(
            (data) => {
                this.logger.phic.debug('Success result of setting date time on AnD UC-356 scale ' + data);
            },
            (error) => {
                this.logger.phic.error('Failed to write date and time on AnD UC-356 scale', error);
            });
    }

    public enableIndicationFor356(peripheral: any, service: string, characteristic: string) {
        var readAllStored = new Uint8Array([this.AND_SCALE_356_CMD_LATEST_STORED_DATA]);
        this.logger.phic.debug('Enable indication for 356 scale char ' + characteristic);
        this.ble.write(peripheral.id, service, characteristic, readAllStored.buffer).then(
            (res) => {
                this.logger.phic.debug('Write Response for char ' + res);
                this.ble.startNotification(peripheral.id, service, characteristic).subscribe(
                    {
                        next: (data) => {
                            this.logger.phic.debug('Started indication for ' + peripheral.name);
                        },
                        error: (error) => {
                            this.logger.phic.error('enableIndicationFor356: Failed to notify characteristic from ' + peripheral.name);
                        }
                    }
                );
            },
            (error) => {
                this.logger.phic.error('enableIndicationFor356: Failed to notify characteristic from ' + peripheral.name, error);
            });
    }

    private parseAndSubmitDataForUC356(buffer: ArrayBuffer, peripheral: any, peripheralType: string) {
        if (buffer && buffer.byteLength >= 12) {
            let peripheralName = peripheral.name;
            let peripheralDisplayName = this.fbanalytics.getDisplayName(peripheral, peripheralType);
            let arrayBuffer2 = new Uint8Array(buffer);
            this.logger.phic.debug('AnD 356 data obtained ' + JSON.stringify(arrayBuffer2));
            enum ByteOffset { // signifies the length of the data bytes for different reading params
                MEASUREMENT_OFFSET_MSB = 9, // Scale measurement
                MEASUREMENT_OFFSET = 10, // Scale measurement
                UNIT_OFFSET = 11 // UNIT
            }

            // Extract the 9th and 10th bytes (index 9 and 10 in the array)
            let msb: number = arrayBuffer2[ByteOffset.MEASUREMENT_OFFSET_MSB]; // 9th byte (index 9)
            let lsb: number = arrayBuffer2[ByteOffset.MEASUREMENT_OFFSET]; // 10th byte (index 10)

            // Combine the MSB and LSB to get the weight value
            let weightValue: number = (lsb << 8) | msb; // (msb << 8) | lsb;
            if (weightValue > 0) {
                const decimalFactor = 10;
                const isWeightInKg = arrayBuffer2[ByteOffset.UNIT_OFFSET] === 1;
                if (isWeightInKg) {
                    weightValue = weightValue * 0.01 * decimalFactor;
                    this.weight = BluetoothUtils.convertKgWeightToPound(weightValue);
                    this.logger.phic.debug('AnD UC-356 Raw Received Weight in KG: ' + weightValue + ', Converted weight in Pound: ' + this.weight);
                } else {
                    this.weight = weightValue * 0.01 * decimalFactor;
                    this.logger.phic.debug('AnD UC-356 Raw Received Weight in pound: ' + this.weight);
                }
                this.weight = BluetoothUtils.round(this.weight, 1);
                this.logger.phic.debug('AnD UC-356 Final Received Weight: ' + this.weight);
                let readingTimeStamp = BluetoothUtils.fetchReadingTimestampForAnDBLEDevice(arrayBuffer2, 2);
                if (!readingTimeStamp || BluetoothUtils.isAnOldReadingForAnDDevice(readingTimeStamp, this.maxTimeDifference)) { // discard historical data
                    this.logger.phic.log('AnD UC-356: This is an old reading probably due to timestamp mismatch IsTimeStampPresent ' + readingTimeStamp + ' Weight : ' + this.weight);
                }
                this.metricChange.next(peripheralName);
                this.isRecentReading = true;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_TRANSMISSION_SUCCESS,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.READING_OFFSET]: BluetoothUtils.getReadingTimeDifference(readingTimeStamp)});
                this.logger.phic.debug('Will pass readings to metric reading page for UA-356');
            } else {
                this.weight = null;
                this.logger.phic.warn('Invalid reading obtained for AnD UC-356');
                this.invalidReadingError();
                this.readingErrorOccurred = true;
                this.fbanalytics.logEvent(HRSFirebaseEvents.BT_READING_ERROR,
                    {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                        [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.VALIDATION_FAILURE});
            }
        }
    }

    // ----************* Methods for UA-356 scale handling end *****************---//
}
