import {Directive, HostListener, Output, EventEmitter} from '@angular/core';
import {Keyboard} from '@capacitor/keyboard';
import {Platform} from '@ionic/angular';
import {throttle} from 'lodash';

@Directive({
    selector: '[appSubmitOnEnterKey]'
})
export class SubmitOnEnterKeyDirective {
    @Output()
    public enterSubmit: EventEmitter<any> = new EventEmitter<any>();

    private readonly submitThrottled: ($event: any) => void;
    private keyboardVisible : boolean;
    private keyboardDidShowListener: any;
    private keyboardDidHideListener: any;

    constructor(
        private readonly platform: Platform,
    ) {
        this.submitThrottled = throttle((ev) => this.submit(ev), 250);
    }

    public ngOnInit(): void {
        // Add listeners for keyboard show and hide
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (info) => {
            this.keyboardVisible = true;
        });
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
            this.keyboardVisible = false;
        });
    }

    public ngOnDestroy(): void {
        // Remove listeners when the directive is destroyed
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    private get shouldDismissKeyboard(): boolean {
        return this.isNativePlatform && this.keyboardVisible;
    }

    private submit($event: any): void {
        this.enterSubmit.emit($event);
        if (this.shouldDismissKeyboard) {
            Keyboard.hide();
        }
    }

    @HostListener('keyup.enter', ['$event'])
    public onEnterKeyPressed($event: any): void {
        this.submitThrottled($event);
    }
}
