import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {CacheModule} from 'ionic-cache';
import {Drivers} from '@ionic/storage';
import {IonicStorageModule} from '@ionic/storage-angular';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './auth.guard';
import {GatewayResourceService, GatewayService} from '@hrs/gateway';
import {
    ApiInterceptor,
    BroadcastService,
    CommunicationService,
    GatewayApi,
    GlobalSettingsService,
    HRSLoggerMonitor,
    TokenService,
    ModalService
} from '@hrs/providers';
import {
    AdminService,
    AudioService,
    BloodPressureDeviceService,
    CareplanChangeService,
    ConsentFormService,
    DeviceService,
    DomService,
    EducationService,
    EnvironmentService,
    FirebaseNotifications,
    FitbitService,
    EventService,
    HRSSecureCache,
    HRSStorage,
    LanguageService,
    MenuService,
    OfflineTaskService,
    OverlayService,
    SchedulerService,
    Settings,
    TabletSettingsService,
    TaskService,
    User,
    UserAgentProvider
} from '@patient/providers';
import {ActivityMonitorsPageModule} from './devices/activity-monitors/activity-monitors.module';
import {CaregiverPinPageModule} from './caregiver-pin/caregiver-pin.module';
import {ConsentFormPageModule} from './consent-form/consent-form.module';
import {DeviceInstructionsUsagePageModule} from './devices/instructions-usage/device-instructions-usage.module';
import {DeviceInstructionsPairingPageModule} from './devices/instructions-pairing/device-instructions-pairing.module';
import {DisconnectDevicePageModule} from './devices/disconnect/disconnect-device.module';
import {EducationQuizzesPageModule} from './education/quizzes/education-quizzes.module';
import {EncryptionService} from './services/encryption/encryption.service';
import {GenericMetricPageModule} from './generic-metric/generic-metric.module';
import {SurveyHistoricalDataModule} from './survey/survey-historical-data/survey-historical-data.module';
// import {IdentityVaultService} from './services/identity-vault/identity-vault.service';
import {MedicationModalModule} from './medication/medication.modal.module';
import {MedicationPageModule} from './medication/medication.module';
import {PinDialogPageModule} from './security/pin-dialog/pin-dialog.module';
// import {SecuritySetupPageModule} from './security/security-setup/security-setup.module';
import {SurveyPageModule} from './survey/survey.module';
import {WoundImagingPageModule} from './wound-imaging/wound-imaging.module';
import {ChatPageModule} from './communication/chat/chat.module';
import {VideoPageModule} from './communication/video/video.module';
import {VoicePageModule} from './communication/voice/voice.module';
import {TabletSettingsPageModule} from './tablet-settings/tablet-settings.module';
import {TabletGuard} from './tablet.guard';
import {HRSTabletModule} from './hrs-tablet/hrs-tablet.module';
import {TabletUserService} from './services/user/tablet-user.service';
import {BuildUtility} from '@hrs/utility';
import {MobileUserService} from './services/user/mobile-user.service';
import {ComponentLibraryModule} from '@hrsui/angular';
import {AdminModule} from './admin/admin.module';
import {TaskTrackingService} from './services/task-tracking/task-tracking.service';
import {OverlayModule} from './hrs-overlay/overlay.module';
import {EducationPageModule} from './education/education.module';
import {DailyMetrics} from './services/daily-metrics/daily-metrics.service';
import {HistoricalDataService} from './services/historical-data/historical-data.service';
import {AppBootstrapService} from './services/app-bootstrap/app-bootstrap.service';
import {NativePluginsModule} from './native-plugins.module';
import {MobilePatientAppErrorHandler} from './services/firebase/firebase-crash.service';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function provideSettings(storage: HRSStorage) {
    /**
     * The Settings provider takes a set of default settings for your app.
     *
     * You can add new settings options at any time. Once the settings are saved,
     * these values will not overwrite the saved values (this can be done manually if desired).
     */
    return new Settings(storage, {});
}

export function initializeApp(appBootstrapService: AppBootstrapService): () => Promise<void> {
    return () => appBootstrapService.initialize();
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AdminModule,
        BrowserModule,
        AppRoutingModule,
        IonicModule.forRoot({mode: 'ios'}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot({
            name: '_ionicstorage',
            storeName: '_ionickv',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        CacheModule.forRoot({
            keyPrefix: HRSSecureCache.CACHE_PREFIX
        }),
        NativePluginsModule,
        ActivityMonitorsPageModule,
        CaregiverPinPageModule,
        ChatPageModule,
        ConsentFormPageModule,
        ComponentLibraryModule,
        DeviceInstructionsUsagePageModule,
        DeviceInstructionsPairingPageModule,
        DisconnectDevicePageModule,
        EducationQuizzesPageModule,
        EducationPageModule,
        GenericMetricPageModule,
        HttpClientModule,
        BuildUtility.isHRSTab() ? HRSTabletModule.forRoot() : [],
        MedicationModalModule,
        MedicationPageModule,
        OverlayModule,
        PinDialogPageModule,
        SurveyHistoricalDataModule,
        SurveyPageModule,
        // SecuritySetupPageModule,
        TabletSettingsPageModule,
        VideoPageModule,
        VoicePageModule,
        WoundImagingPageModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [AppBootstrapService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function(broadcastService: BroadcastService, tokenService: TokenService) {
                return new ApiInterceptor(broadcastService, tokenService);
            },
            multi: true,
            deps: [BroadcastService, TokenService]
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: Settings, useFactory: provideSettings, deps: [HRSStorage]},
        {provide: User, useClass: BuildUtility.isHRSTab() ? TabletUserService : MobileUserService},
        {provide: ErrorHandler, useClass: MobilePatientAppErrorHandler},
        AdminService,
        AudioService,
        AuthGuard,
        BloodPressureDeviceService,
        BroadcastService,
        CareplanChangeService,
        CommunicationService,
        ConsentFormService,
        DailyMetrics,
        DeviceService,
        DomService,
        EducationService,
        EncryptionService,
        EnvironmentService,
        EventService,
        // Keep this to enable Ionic's runtime error handling during development
        FirebaseNotifications,
        FitbitService,
        GatewayApi,
        GatewayResourceService,
        GatewayService,
        GlobalSettingsService,
        HistoricalDataService,
        HRSLoggerMonitor,
        HRSSecureCache,
        HRSStorage,
        LanguageService,
        MenuService,
        ModalService,
        OfflineTaskService,
        OverlayService,
        SchedulerService,
        TabletGuard,
        TabletSettingsService,
        TaskService,
        TokenService,
        UserAgentProvider,
        TaskTrackingService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}
