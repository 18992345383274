import { registerPlugin } from '@capacitor/core';

import type { WakeLockPlugin } from './definitions';

const WakeLock = registerPlugin<WakeLockPlugin>('WakeLock', {
  web: () => import('./web').then(m => new m.WakeLockWeb()),
});

export * from './definitions';
export { WakeLock };
