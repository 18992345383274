import {Injectable, Injector, NgZone, inject} from '@angular/core';
import {NavigationExtras} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ActionSheetController, LoadingController, NavController, Platform} from '@ionic/angular';
import {MobileAccessibility} from '@ionic-native/mobile-accessibility';
import {BuildUtility} from '@hrs/utility';
import {getLogger} from '@hrs/logging';
import {environment} from '@app/env';
import {take} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs';
import {
    BroadcastService,
    CommunicationService,
    GatewayApi,
    GlobalSettingsService,
    HRSLoggerMonitor,
    ModalService,
    TokenService
} from '@hrs/providers';
import {AudioLoopConfig, AudioPlayType, AudioRingType, AudioService} from '../audio/audio.service';
import {ChatPage} from '../../communication/chat/chat.page';
import {VideoPage} from '../../communication/video/video.page';
import {VoicePage} from '../../communication/voice/voice.page';
import {ScreenOrientationService} from '../screen-orientation/screen-orientation.service';
import {BatteryStatusService} from '../battery-status/battery-status.service';
import {OverlayComponent, OverlayRef} from '../../hrs-overlay';
import {TabletDeviceIdService} from '../tablet-device-id/tablet-device-id';
import {AudioCommunicationService} from '../audio/audio-communication.service';
import {CommunicationAnalyticsService} from '../analytics/communication-analytics.service';
import {KnoxManageService} from '../knox/knox-manage.service';
import {PCKSVersionIntentService} from '../../hrs-tablet/knox-service-intents/pcks-version-intent.service';
import {SignalStrengthService} from '../signal-strength/signal-strength.service';
import {HRSSecureCache} from '../storage/cache';
import {DeviceService} from '../device/device.service';
import {EnvironmentService} from '../environment/environment.service';
import {EventService} from '../events/event.service';
import {FirebaseNotifications} from '../firebase/firebase';
import {FitbitService} from '../fitbit/fitbit.service';
import {LanguageService} from '../language/language.service';
import {NativeLoggerService} from '../logs/native-logger.service';
import {LogUploadService} from '../logs/log-upload.service';
import {MenuService} from '../menu/menu.service';
import {OverlayService, OVERLAY_DURATION_INFINITE} from '../overlay/overlay.service';
import {SchedulerService} from '../schedule';
import {Settings} from '../settings/settings.service';
import {HRSStorage} from '../storage/storage';
import {TaskService} from '../tasks/task.service';
import {TextToSpeechService} from '../text-to-speech/text-to-speech.service';
import {User} from '../user/user.service';
import {UserAgentProvider} from '../user-agent/user-agent';
import {DeviceIDIntentService} from '../../hrs-tablet/knox-service-intents/deviceid-intent.service';
import {SPLASH_SCREEN_PLUGIN, STATUS_BAR_PLUGIN, WAKE_LOCK_PLUGIN} from '@app/native-plugins';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Style} from '@capacitor/status-bar';

/**
 * Core startup handler for this app.
 *
 * This service should not be injected into any other service / component,
 * and should only be used in app.module.ts as an APP_INITIALIZER DI token dependency.
 */
@Injectable({
    providedIn: 'root'
})
export class AppBootstrapService {
    private readonly logger = getLogger('AppBootstrapService');
    private static CACHE_TTL = 3600; // 1 hour (in seconds)
    messageData: any;
    modalState: any;
    modal: any;
    isDisplaying: boolean = false; // toast for forced log outs
    ongoingVideoCallId: any;
    private deepLinkRoutingAttempt: number = 0;
    private mOverlay: OverlayService;
    private initialized: boolean = false;

    private readonly statusBar = inject(STATUS_BAR_PLUGIN);
    private readonly splashScreen = inject(SPLASH_SCREEN_PLUGIN);

    private readonly wakeLock = inject(WAKE_LOCK_PLUGIN);

    constructor(
        private readonly injector: Injector,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly audio: AudioService,
        private readonly batteryStatus: BatteryStatusService,
        private readonly broadCastService: BroadcastService,
        private readonly cache: HRSSecureCache,
        private readonly communication: CommunicationService,
        private readonly audioCommunication: AudioCommunicationService,
        private readonly communicationAnalytics: CommunicationAnalyticsService,
        private zone: NgZone,
        private readonly deviceService: DeviceService,
        private readonly environmentService: EnvironmentService,
        private readonly eventService: EventService,
        private readonly firebase: FirebaseNotifications,
        private readonly fitbitService: FitbitService,
        private readonly gatewayApi: GatewayApi,
        private readonly globalSettingsService: GlobalSettingsService,
        private readonly language: LanguageService,
        private readonly loadingCtrl: LoadingController,
        private readonly knoxManageService: KnoxManageService,
        private readonly nativeLoggerService: NativeLoggerService,
        private readonly logUploadService: LogUploadService,
        private readonly loggerMonitor: HRSLoggerMonitor,
        private readonly menuService: MenuService,
        private readonly modalService: ModalService,
        private readonly navCtrl: NavController,
        private readonly platform: Platform,
        private readonly screenOrientation: ScreenOrientationService,
        private readonly scheduler: SchedulerService,
        private readonly settings: Settings,
        private readonly storage: HRSStorage,
        private readonly taskService: TaskService,
        private readonly textToSpeechService: TextToSpeechService,
        private readonly tokenService: TokenService,
        private readonly translate: TranslateService,
        private readonly user: User,
        private readonly userAgentProvider: UserAgentProvider,
        private readonly tabletId: TabletDeviceIdService,
        private readonly pcksVersionIntentService: PCKSVersionIntentService,
        private readonly signalStrengthService: SignalStrengthService,
        private readonly deviceIDIntentService: DeviceIDIntentService,
    ) {
    }

    private get isHRSTab(): boolean {
        return BuildUtility.isHRSTab();
    }

    // Injecting this directly in the constructor may cause
    // circular dependency errors on startup, so lazy-load it instead.
    private get overlay(): OverlayService {
        if (!this.mOverlay) this.mOverlay = this.injector.get(OverlayService);
        return this.mOverlay;
    }

    private get isIOS(): boolean {
        return this.platform.is('ios');
    }

    private get isAndroid(): boolean {
        return this.platform.is('android');
    }

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            this.logger.warn(`initialize() ignoring duplicate call (already initialized)`);
            return;
        }

        // set this immediately to prevent possibility of two
        // calls racing against each other.
        this.initialized = true;

        try {
            await this.initializeUnsafe();
        } catch (err) {
            this.logger.phic.fatal(`initialize() failed!`, err);
        }
    }

    private async initializeUnsafe(): Promise<void> {
        // This needs to be done ASAP so we can capture / view logs as early as possible
        this.nativeLoggerService.initialize();

        this.logger.info(`initialize() BEGIN`);

        if (this.isHRSTab) {
            this.loggerMonitor.init('pcmobiletab');
        } else {
            this.loggerMonitor.init('pcmobile');
        }

        this.enableRootSubscriptions();

        this.audioCommunication.enableListeners();
        this.communicationAnalytics.initialize();
        this.scheduler.initialize();

        await this.platform.ready();

        await this.runUnsafeStartupFlow().catch((err) => {
            this.logger.phic.error(`runUnsafeStartupFlow() failed!`, err);
        });

        await this.runPostInitializeActions().catch((err) => {
            this.logger.phic.error(`runPostInitializeActions() failed!`, err);
        });

        await this.performEntryViewNavigation().catch((err) => {
            this.logger.phic.error(`performEntryViewNavigation() failed!`, err);
        });

        this.logger.info(`initialize() END`);
    }

    private async performEntryViewNavigation(): Promise<void> {
        if (this.user.isLoggedIn()) {
            if (this.user.isDevice()) {
                await this.navCtrl.navigateRoot(['/tablet-white-screen']);
            } else {
                const status = await this.user.getUserData().then(() => this.user.status);
                const isAssigned = await this.user.checkCurrentUser(); // always returns true for PCM
                if (this.isHRSTab && (status === 'deactivated' || !isAssigned)) {
                    await this.user.logout();
                } else {
                    this.environmentService.getEnvironment(this.user.environment);
                    await this.navCtrl.navigateRoot(['home']);
                    this.fitbitService.checkLocalStorage();
                    this.user.afterLogin();
                }
            }
        } else {
            if (this.isHRSTab) {
                await this.navCtrl.navigateRoot(['tablet-white-screen']);
            } else {
                if (!this.deepLinkRoutingAttempt) {
                    await this.navCtrl.navigateRoot(['welcome']);
                }
            }
        }

        if (this.isNativePlatform) {
            this.splashScreen.hide();
        }
    }

    private async runPostInitializeActions(): Promise<void> {
        this.logger.trace(`runPostInitializeActions()`);

        await this.deviceService.getBondedBluetoothDevices().catch((err) => {
            this.logger.warn(`deviceService.getBondedBluetoothDevices() failed`, err);
        });

        if (this.isHRSTab) {
            await this.deviceService.checkCPUAndLog().catch((err) => {
                this.logger.error(`deviceService.checkCPUAndLog() failed`, err);
            });
            this.deviceService.startDeviceDiscovery();
        }

        if (this.isNativePlatform) {
            this.logger.phic.debug('AppComponent Finally : Will start BT polling');
            await this.deviceService.subscribePollingForBluetoothDevices().catch((err) => {
                this.logger.warn(`deviceService.subscribePollingForBluetoothDevices() failed`, err);
            });
        }

        this.textToSpeechService.initialize();
        this.logger.phic.info(`current_environment = `, environment.current_environment);
    }

    private async runUnsafeStartupFlow(): Promise<void> {
        await this.nativeLoggerService.tryRegisterNativeLogging();

        // Don't need to block on this because nothing in startup depends on it
        this.logUploadService.initialize().catch((e) => {
            this.logger.phic.warn(`failed to initialize LogUploadService: ${e}`);
        });

        // DEV-11392 - called to prevent sqlite related out of memory errors
        try {
            await this.clearCache();
        } catch (e) {
            this.logger.phic.error('Failed to clear cache: ', e);
        }

        await this.knoxManageService.initialize().catch((err) => {
            this.logger.error(`knoxManageService.initialize() ERROR:`, err);
        });

        this.deviceIDIntentService.initialize().catch((err) => {
            this.logger.error(`deviceIDIntentService.initialize() ERROR:`, err);
        });

        this.pcksVersionIntentService.initialize().catch((err) => {
            this.logger.error(`pcksVersionIntentService.initialize() ERROR:`, err);
        });

        this.signalStrengthService.initialize().catch((err) => {
            this.logger.error(`signalStrengthService.initialize() ERROR:`, err);
        });

        // Okay, so the platform is ready and our plugins are available.
        // Here you can do any higher level native things you might need.
        if (this.isIOS) {
            // if platform is ios then setting usePreferredTextZoom to true for font scaling from accessibility
            MobileAccessibility.usePreferredTextZoom(true);
        }

        if (this.isAndroid) {
            // If PCMT build, overlay WebView so that the native black top bar does not show.
            // If PCM build, do not overlay so that our header is not sitting behind the native top bar.
            if (this.knoxManageService.enabled) {
                this.statusBar.hide();
            }
        }

        if (this.isNativePlatform && this.isIOS) {
            // On iOS the status bar overlaps our web view, thus it has a light background on this page and we have to call styleDefault
            // to give it dark text.
            // But on Android the status bar is outside our app, so we're fine to let it use the default system colors.
            this.statusBar.setStyle({style: Style.Default});
        }

        if (this.isHRSTab) {
            this.batteryStatus.initBatteryListener();
            await this.deviceService.checkCPUAndLog().catch((err) => {
                this.logger.error(`deviceService.checkCPUAndLog() failed`, err);
            });
        }

        this.audio.initialize();

        await this.userAgentProvider.getUserAgentInfo();

        await this.settings.load().catch((err) => {
            this.logger.error(`settings.load() failed: `, err);
        });

        // Check for any domain changes because app will lose those changes on force kill.
        const updatedDomain = await this.storage.get('updatedDomain').catch((err) => {
            this.logger.warn(`storage.get('updatedDomain') failed: `, err);
            return null;
        });

        if (updatedDomain) {
            BuildUtility.setDomain(updatedDomain).then((domain) => {
                this.gatewayApi.url = domain;
                this.tokenService.url = domain;
            }).catch((err) => {
                this.logger.phic.error('Failed to set domain', err);
            });
        }

        if (this.isNativePlatform) {
            await this.screenOrientation.setDefault();
            // Fixed DEV-16505 The apns token is deleted upon app relaunch, was resulting in missed notifications in the PCM iOS app.
            // We are attempting to retrieve the apns token every time to prevent it from becoming nil, when the app is launched.
            if (this.isIOS) {
                this.firebase.askIOSPushPermission();
            }
            const initialSetupComplete = this.settings.getValue('initialSetupComplete');
            if (!initialSetupComplete) {
                if (this.isAndroid) {
                    this.firebase.createAndroidNotificationChannel();
                }
                await this.settings.setValue('initialSetupComplete', true);
            }
        }

        // API response caching. Only applies where we choose to use CacheService
        this.cache.setDefaultTTL(AppBootstrapService.CACHE_TTL);

        await this.initTranslate();

        if (this.isAndroid) {
            this.requestAndroidPermissions().catch((err) => {
                this.logger.error(`requestAndroidPermissions() failed`, err);
            });
        }

        this.routeWithDeeplinks();

        // We only require the patient to log in once, the first time. If they have previously logged in, then skip the login screen.
        const token = this.settings.getValue('token');
        const refresh = this.settings.getValue('refresh');
        if (token) {
            this.tokenService.storeTokens({
                attributes: {
                    token: token,
                    refresh: refresh
                }
            });
        }
    }

    private enableRootSubscriptions(): void {
        this.platform.pause.subscribe(() => {
            if (this.isHRSTab) {
                const imei = this.tabletId._imei || null;
                this.logger.phic.info('PCMT Paused - IMEI: ' + imei);
            } else {
                this.logger.info(`PCM App Paused`);
            }
            this.eventService.screenPauseChange.next(true);
        });

        this.platform.resume.subscribe(() => {
            if (this.isHRSTab) {
                const imei = this.tabletId._imei || null;
                this.logger.phic.info('PCMT Resumed - IMEI: ' + imei);
            } else {
                this.logger.info(`PCM Resumed`);
            }
            this.eventService.screenPauseChange.next(false);
        });

        this.platform.backButton.subscribeWithPriority(10000, () => {
            navigator['app'].exitApp();
        });

        this.communication.incomingVideoCall$.subscribe({
            next: (data: any) => {
                if (this.user.isLoggedIn()) {
                    let answer = !!data.wasTapped;
                    this.handleIncomingVideoCall(data, answer);
                }
            },
            error: (err) => {
                this.logger.error(`communication.incomingVideoCall$ failed`, err);
            }
        });

        this.communication.incomingVoiceCall$.subscribe({
            next: (data: any) => {
                if (this.user.isLoggedIn()) {
                    let answer = !!data.wasTapped;
                    this.handleIncomingVoiceCall(data, answer);
                }
            },
            error: (err) => {
                this.logger.error(`communication.incomingVoiceCall$ failed`, err);
            }
        });

        this.communication.newChatMessage$.subscribe({
            next: (data: any) => {
                if (this.user.isLoggedIn()) {
                    this.handleNewMessage(data);
                }
            },
            error: (err) => {
                this.logger.error(`communication.newChatMessage$ failed`, err);
            }
        });

        // Fetch tasks and education content after login completion
        this.eventService.loginStateChanged.subscribe({
            next: (hasLoggedIn: boolean) => {
                this.logger.info(`eventService.loginStateChanged hasLoggedIn = ${hasLoggedIn}`);
                if (hasLoggedIn) {
                    this.globalSettingsService.loadGlobalSettings();
                    if (this.user.isPatient() && this.isHRSTab) {
                        this.deviceService.getBondedBluetoothDevices();
                    }
                }
            },
            error: (err) => {
                this.logger.error(`eventService.loginStateChanged failed`, err);
            }
        });

        this.broadCastService.interceptorLogout.asObservable().subscribe({
            next: (values) => {
                this.logger.phic.debug('Observing Interceptor', values);
                if (values) {
                    this.logger.phic.error('Token auth error', values);
                    this.loadingCtrl.getTop().then((element) => {
                        if (element) {
                            this.loadingCtrl.dismiss();
                        }
                    });

                    if (!this.isDisplaying && values.error) {
                        this.isDisplaying = true;
                        setTimeout(() => {
                            this.isDisplaying = false;
                        }, 2000);
                        if (values.error.message === 'LOGIN_ERROR.CONCURRENT_SESSIONS') {
                            this.forcedLogoutToast(values.error.message);
                        } else {
                            this.forcedLogoutToast();
                        }
                    }
                    this.user.logout();
                    this.taskService.tasks = [];
                }
            },
            error: (err) => {
                this.logger.error(`broadCastService.interceptorLogout -> error: ${err}`, err);
            }
        });

        this.broadCastService.miscAuthError.asObservable().subscribe({
            next: (values) => {
                if (values) {
                    this.logger.phic.error('Token misc auth error', values);
                    if (this.isHRSTab) {
                        this.loadingCtrl.getTop().then((element) => {
                            if (element) {
                                this.loadingCtrl.dismiss();
                            }
                        });
                        this.user.logout();
                        this.taskService.tasks = [];
                    }
                }
            },
            error: (err) => {
                this.logger.error(`broadCastService.miscAuthError -> error: ${err}`, err);
            }
        });
    }

    private async requestAndroidPermissions(): Promise<void> {
        await this.deviceService.checkVideoCallPermissions();

        // we want permissions to be asked for one after the other
        await this.deviceService.checkAndroid13Permissions(
            ['android.permission.POST_NOTIFICATIONS', 'android.permission.READ_PHONE_STATE'],
            'ANDROID_NOTIFICATION_PERMISSION_TITLE',
            'ANDROID_NOTIFICATION_PERMISSION_MESSAGE'
        );

        // we want permissions to be asked for one after the other
        await this.deviceService.checkAndroid13Permissions(
            ['android.permission.READ_MEDIA_IMAGES', 'android.permission.READ_MEDIA_VIDEO', 'android.permission.READ_MEDIA_AUDIO'],
            'ANDROID_MEDIA_PERMISSION_TITLE',
            'ANDROID_MEDIA_PERMISSION_MESSAGE'
        );

        if (!this.deviceService.hasAndroidPermission) {
            this.deviceService.checkAndroidPermissions(); // these mainly deal with bluetooth and location related permissions
        }
    }

    private routeWithDeeplinks(): void {
    // deeplinks allow navigation from the system browser to our app
    // (ios) enter pcmobile:// in the browser and it will launch the app if it is installed

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const parsedUrl = new URL(event.url);
                const authValue = parsedUrl.searchParams.get('auth');
                const codeValue = parsedUrl.searchParams.get('code');
                if (parsedUrl && parsedUrl.pathname === '/fitbit') {
                    if (!this.user.isLoggedIn()) {
                        return;
                    }
                    this.translate.get('LOADING_ELLIPSIS').subscribe(async (res: string) => {
                        let load = await this.loadingCtrl.create({message: res});
                        await load.present();
                        this.fitbitService.accessToken(codeValue).subscribe({
                            next: async () => {
                                await this.navCtrl.navigateForward('/activity-monitors', {queryParams: {header: 'ACTIVITY_MONITORS.TITLE'}});
                                await load.dismiss();
                            },
                            error: async (err) => {
                                await load.dismiss();
                                this.logger.phic.error('Error connecting to activity monitor', err);
                                await this.overlay.openAlert({
                                    variant: 'error',
                                    header: this.translate.instant('ACTIVITY_MONITOR_SIGN_IN_ERROR.TITLE'),
                                    message: [this.translate.instant('ACTIVITY_MONITOR_SIGN_IN_ERROR.MESSAGE')],
                                    buttons: [{
                                        text: this.translate.instant('OK_BUTTON')
                                    }],
                                    qa: 'activity_monitors_alert'
                                });
                            }
                        });
                    });
                } else if (parsedUrl && parsedUrl.pathname === '/login') {
                    if (!this.user.isLoggedIn()) {
                        // to generate an error every time a user clicks on a universal link, even if they
                        // are already on the /login page, a value in the queryParams needs to be updated
                        this.deepLinkRoutingAttempt++;
                        this.translate.get('LOADING_ELLIPSIS').subscribe(async (res: string) => {
                            let credentials;
                            let load = await this.loadingCtrl.create({message: res});
                            await load.present();
                            if (authValue) {
                                credentials = authValue;
                            }
                            const navigationExtras: NavigationExtras = {
                                queryParams: {
                                    autoLogin: this.deepLinkRoutingAttempt,
                                    credentials: credentials
                                }
                            };
                            await this.navCtrl.navigateRoot('/login', navigationExtras);
                            await load.dismiss();
                        });
                    }
                }
                // }
                // If no match, do nothing - let regular routing
                // logic take over
            // });
            });
        });
    }

    private async initTranslate(): Promise<any> {
        return this.language.setTranslation(this.language.getUserLanguage());
    }

    /**
     * * Display reason for getting a forced 401 or 302 error from the api-interceptor
     * @param message
     */
    private async forcedLogoutToast(message?: string): Promise<any> {
        let text = message || 'SIGN_IN_FORCED_SIGN_OUT';
        return await this.overlay.openToast({
            message: this.translate.instant(text),
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            buttons: [
                {
                    text: this.translate.instant('CLOSE_BUTTON'),
                    role: 'cancel'
                }
            ],
            qa: 'app_logout_toast'
        });
    }

    private async handleIncomingVideoCall(data, answer?: boolean) {
        let caller;
        let caregiver;
        if (typeof data.caller === 'string') {
            caller = JSON.parse(data.caller);
        } else {
            caller = data.caller;
        }

        caller = caller || {};
        if (caller.userType === 'caregiver') {
            caregiver = this.user.getCaregiver(null, caller.hrsid);
        }
        this.communication.videoParticipantId = data.participantId;
        this.communication.updateParticipantStatus('received');

        // if no call is in the foreground, open incoming call
        if (!(this.modalService.getModalStatus('VideoPage') || this.communication.hasActiveVideoCall()) && !this.modalService.getModalStatus('VoicePage')) {
            this.ongoingVideoCallId = data.callId;
            await this.overlay.openModal({
                component: VideoPage,
                title: this.translate.instant('VIDEO_PANEL'),
                inputs: {
                    callData: {
                        calleeName: caregiver ? caregiver.firstName + ' ' + caregiver.lastName : caller.name,
                        callId: data.callId,
                        answer: answer,
                        participantId: data.participantId
                    }
                },
                qa: 'video_modal'
            });
        } else {
            // if a call is in the foreground, show alert and offer option to switch calls

            // With android zoom sdk 5.14.0, we are getting notification for the same call twice.
            if (this.ongoingVideoCallId !== data.callId) { // not show the popup for the same call
                this.wakeLock.acquireWakeLock({value: environment.RINGER_DURATION});
                const actionSheet = await this.actionSheetCtrl.create({
                    header: this.translate.instant('NEW_INCOMING_CALL') + caller.name,
                    buttons: [
                        {
                            text: this.translate.instant('END_CURRENT_CALL'),
                            handler: () => {
                                // close current video call modal and open new incoming call
                                this.communication.notifyExitVideoCallEnterNew(null);
                                this.communication.notifyExitVoiceCallEnterNew(null);
                                this.handleIncomingVideoCall(data, true);
                                this.wakeLock.releaseWakeLock();
                            }
                        }, {
                            text: this.translate.instant('IGNORE'),
                            handler: () => {
                                this.logger.phic.log('call ignored');
                                // tell comms service we hung up
                                this.communication.updateParticipantStatus('declined');
                                this.wakeLock.releaseWakeLock();
                            }
                        }
                    ]
                });
                return await actionSheet.present();
            } else {
                this.logger.phic.debug('Not handling notification for an already handled call.');
            }
        }
    }

    private async handleIncomingVoiceCall(data, answer?: boolean) {
        let innerData = data.data;
        // Voice and Chat use an older message format, so these assignments just makes them consistent with the newer format used by Video as well as all comms types for Clinician/Caregiver
        data.callid = innerData.callid;
        data.caller = {name: innerData.from};
        data.access = innerData.access;

        if (!(this.modalService.getModalStatus('VideoPage') || this.communication.hasActiveVideoCall()) && !this.modalService.getModalStatus('VoicePage')) {
            await this.overlay.openModal({
                component: VoicePage,
                title: this.translate.instant('VOICE_PANEL'),
                inputs: {
                    callData: {
                        calleeName: data.caller.name,
                        callId: data.callid,
                        answer: answer,
                        access: data.access
                    }
                },
                qa: 'voice_modal'
            });
        } else {
            this.wakeLock.acquireWakeLock({value: environment.RINGER_DURATION});
            const actionSheet = await this.actionSheetCtrl.create({
                header: this.translate.instant('NEW_INCOMING_CALL') + data.caller.name,
                buttons: [
                    {
                        text: this.translate.instant('END_CURRENT_CALL'),
                        handler: () => {
                            // close current call modal and open new incoming call
                            this.communication.notifyExitVideoCallEnterNew(null);
                            this.communication.notifyExitVoiceCallEnterNew(null);
                            this.handleIncomingVoiceCall(data, true);
                            this.wakeLock.releaseWakeLock();
                        }
                    }, {
                        text: this.translate.instant('IGNORE'),
                        handler: () => {
                            this.logger.phic.log('Voice call ignored');
                            this.wakeLock.releaseWakeLock();
                        }
                    }
                ]
            });
            return await actionSheet.present();
        }
    }

    private async handleNewMessage(data) {
        if (data.data) { // parse data.data only for clinician chats
            let innerData = data.data;
            // Voice and Chat use an older message format, so these assignments just makes them consistent with the newer format used by Video as well as all comms types for Clinician/Caregiver
            data.caller = {name: innerData.from};
            data.msg = innerData.msg;
        }

        let modalState = this.modalService.getModalStatus('ChatPage');
        let caregiver;
        let params;
        if (data.chatroomId) { // caregiver
            caregiver = this.user.getCaregiver(data.chatroomId);
            params = caregiver ? {caregiver: caregiver} : {caregiver: {chatroom: data.chatroomId}};
        }
        if (data.wasTapped) {
            const activeModal = await this.overlay.getTop('modal');
            // Close open/minimized modal, EXCEPT voice or video call modals
            if (activeModal instanceof OverlayComponent &&
                !(activeModal.childComponentRef.instance instanceof VoicePage) &&
                !(activeModal.childComponentRef.instance instanceof VideoPage)) {
                await activeModal.dismiss();
                await lastValueFrom(activeModal.onClose$.pipe(take(1))).catch((err) => {
                    this.logger.phic.warn(`caught modal close error`, err);
                });
            }
            this.menuService.launchCommsModal({titleKey: 'MENU_COMMS_CHAT', icon: 'chat'}, {component: ChatPage, componentProps: params});
        } else {
            if (!modalState) {
                this.messageData = data;
                // no chat modal is open
                const toast = await this.launchChatToast(data);
                this.overlay.currentToast = toast;
            } else {
                // chat modal is open, update with incoming message
                this.communication.notifyGetChatNewMessage(data);
            }
        }
    }

    private async launchChatToast(data: any): Promise<OverlayRef> {
        this.messageData = data;
        let name = data.caller && data.caller.name ? data.caller.name : 'Caregiver';
        let toast = await this.overlay.openToast({
            duration: OVERLAY_DURATION_INFINITE,
            message: this.translate.instant('NEW_CHAT_MESSAGE') + name,
            redirectText: this.translate.instant('VIEW'),
            allowRedirect: true,
            handler: () => {
                this.messageData.wasTapped = true;
                this.handleNewMessage(this.messageData);
                this.audio.endPlayback(AudioRingType.BEEP);
                toast.dismiss();
            },
            qa: 'app_chat_toast'
        });

        if (toast) {
            // notify user that toast is up w/ audible beep
            const audioConfig: AudioLoopConfig = {
                repeatCount: 4, // how many times to play the ringtone
                interval: 30000, // how long to wait between ringtone(s), in ms
            };
            if (BuildUtility.isHRSTab()) {
                // Aquiring wake lock for 10 seconds
                this.wakeLock.acquireWakeLock({value: environment.CHAT_WAKE_UP_DURATION});
            }
            this.audio.startPlayback(AudioRingType.BEEP, AudioPlayType.CUSTOM, audioConfig);
        }

        return toast;
    }

    // FIX FOR DEV-11392 - clearing cache in case it includes bad data - resolves app out of memory errors related to sqlite
    // https://healthrecoverysolutions.atlassian.net/wiki/spaces/~5ac36e887ddadd4a9df5d1b7/pages/3683876865/PCMT+-+Out+Of+Memory+Black+Screen
    private async clearCache() {
        const cacheCleared = await this.storage.get(HRSSecureCache.CACHE_CLEARED);
        if (!cacheCleared) {
            await this.cache.fixBlackScreen();
            await this.storage.set(HRSSecureCache.CACHE_CLEARED, true);
        }
    }
}
