import {Injectable} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Device} from '@ionic-native/device/ngx';
import {GatewayApi} from '@hrs/providers';
import {Platform} from '@ionic/angular';
import {ConvertString} from '@hrs/utility';
import {GatewayService} from '@hrs/gateway';
import {getLogger} from '@hrs/logging';

/*
  The main goal of this class is to provider a custom header field for all requests it should look like
  "hrs-user-agent": "ClinicianConnectMobile/1.23.23 UUID/1231201231-123-123123j123lj23 iOS/12.01 Mobile/Apple/iPhone"
*/

@Injectable({
    providedIn: 'root',
})
export class UserAgentProvider {
    private _appName: string;
    public _appVersionNumber: string;
    private _uuid: string;
    private _osName: string;
    private _osVersion: string;
    private _device: string;
    private _hrsUserAgentHeader: string;
    private _fcmVersion = 'FCM/v1';
    private _nativeBridge = 'NativeBridge/Capacitor';
    private readonly logger = getLogger('UserAgentProvider');

    constructor(
        private appVersion: AppVersion,
        private device: Device,
        private gatewayApi: GatewayApi,
        private gatewayService: GatewayService,
        private platform: Platform
    ) {}

    private get isNativePlatform(): boolean {
        return this.platform.is('cordova') ||
            this.platform.is('capacitor');
    }

    public async getUserAgentInfo(): Promise<void> {
        this.logger.debug('getUserAgentInfo()');
        if (this.isNativePlatform) {
            await this.getAppName();
            await this.getAppVersion();
            this.getUUID();
            this.getOSName();
            await this.getOSVersion();
            this.getDevice();
            this._hrsUserAgentHeader = `${this._appName}/${this._appVersionNumber} UUID/${this._uuid} ${this._osName}/${this._osVersion} ${this._device} ${this._fcmVersion} ${this._nativeBridge}`;
            this.gatewayApi.hrsUserAgentHeader = this._hrsUserAgentHeader;
            this.gatewayService.hrsUserAgentHeader = this._hrsUserAgentHeader;
        }
    }

    private async getAppName(): Promise<void> {
        this.logger.debug('getAppName()');
        const name = await this.appVersion.getAppName();
        // remove whitespace
        this._appName = String(name).replace(/\s/g, '');
    }

    private async getAppVersion(): Promise<void> {
        this.logger.debug('getAppVersion()');
        this._appVersionNumber = await this.appVersion.getVersionNumber();
    }

    private getUUID(): void {
        this.logger.debug('getUUID()');
        this._uuid = this.device.uuid;
    }

    private getOSName() {
        this.logger.debug('getOSName()');
        if (this.platform.is('ios')) {
            this._osName = 'iOS';
        } else if (this.platform.is('android')) {
            this._osName = 'Android';
        }
    }

    private async getOSVersion(): Promise<void> {
        this.logger.debug('getOSVersion()');
        // there is a .str on this.platform.version but if there is no minor version it reads '9.undefined'
        // IONIC UPGRADE - this original code was not working with upgrade
        //   this._osVersion = this.platform.versions().num.toString();
        if (!this._appVersionNumber) await this.getAppVersion();
        this._osVersion = this._appVersionNumber;
    }

    private getDevice(): void {
        this.logger.debug('getDevice()');
        const model = (device: any): string => {
            // Android and Apple treat model name slightly different
            let deviceName = device.manufacturer.toLowerCase().includes('apple') ? device.model : device.name;
            // Any apostrophes or curly characters might break setting the headers
            // apple will place a comma between version numbers of model (ie. iPhone10,1 -> iPhone10.1)
            // blank spaces to be concatenated as underscores
            return ConvertString.removeNonUtf8Chars(deviceName).replace(/,/g, '.').replace(/ /g, '_');
        };

        const manufacturer = this.device.manufacturer.charAt(0).toUpperCase() + this.device.manufacturer.slice(1).toLowerCase();
        const deviceFormat = this.platform.is('tablet') ? 'Tablet' : 'Mobile';
        const deviceModel = model((window as any).device);
        this._device = `${deviceFormat}/${manufacturer}/${deviceModel}`;
    }
}
