import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject} from '@angular/core';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {DeviceService} from '../../services/device/device.service';
import {EventService} from '../../services/events/event.service';
import {OverlayService} from '../../services/overlay/overlay.service';
import {TaskTrackingService} from '../../services/task-tracking/task-tracking.service';
import {OpenTokService} from '../../services/opentok/opentok.service';
import {ZoomService} from '../../services/zoom/zoom.service';
import {User} from '../../services/user/user.service';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {Caregiver} from '../../services/user/caregiver.interface';
import {Subscription} from 'rxjs';
import {AudioLoopConfig, AudioPlayType, AudioRingType, AudioService} from '../../services/audio/audio.service';
import {OverlayRef} from '../../hrs-overlay';
import {ContentDetail} from '@hrsui/core/dist/types/components/content/content.interface';
import {CommunicationService, ModalService, VideoCallType, CallActionOrigin} from '@hrs/providers';
import {VideoNotification} from '../../services/firebase/firebase-data-interface';
import {ModalTemplateOptions} from '../../hrs-overlay/overlay-templates/modal-template.component';
import {getLogger} from '@hrs/logging';
import {BuildUtility} from '../../../../../../libs/utility/BuildUtility';
import {environment} from 'src/environments/environment';
import {WAKE_LOCK_PLUGIN} from '@app/native-plugins';

export interface VideoCallLeftData {
    callId: string,
    id: string
}

export interface VideoCallInitializationData {
    calleeName?: string;
    callId?: string;
    answer?: boolean;
    participantId?: string;
    sessionId?: string;
    password?: string;
}

@Component({
    selector: 'app-video',
    templateUrl: './video.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPage {
    private readonly logger = getLogger('VideoPage');
    public contentConfig: ContentDetail[] = [{
        comms: {
            type: 'video',
            status: '',
            participant: ''
        }
    }];
    public primaryButtonText: string = 'CALL_BUTTON';
    private callAccepted: boolean = false;
    public callData: VideoCallInitializationData = {};
    public calling: boolean;
    private apiKey: any;
    private backAction: Subscription;
    private sessionId: string;
    private token: string;
    private userHrsId: string;
    private callId: string;
    public modalClosing: boolean;
    public caregiver: Caregiver;

    private callConnectedEvent: Subscription;
    private callerLeft: Subscription;
    private endCallEvent: Subscription;
    private exitCallEnterNewEvent: Subscription;
    private nativeAudioEventSubscription: Subscription; // listen for LOOP end event to timeout call

    private callTimeout: ReturnType<typeof setTimeout>;
    public contentVariant: string = 'comms';
    public connectedCallUIConfig: ModalTemplateOptions;
    public disconnectedCallUIConfig: ModalTemplateOptions;

    private readonly wakeLock = inject(WAKE_LOCK_PLUGIN);

    constructor(
        private audio: AudioService,
        private communication: CommunicationService,
        private dialogs: Dialogs,
        private deviceService: DeviceService,
        private eventService: EventService,
        private modalService: ModalService,
        private opentok: OpenTokService,
        private overlay: OverlayService,
        private overlayRef: OverlayRef,
        private platform: Platform,
        private ref: ChangeDetectorRef,
        private taskTrackingService: TaskTrackingService,
        private translateService: TranslateService,
        private user: User,
        private zoomService: ZoomService
    ) {
        this.modalService.setModalStatus('VideoPage', true);
    }

    ngOnInit() {
        this.logger.debug(`ngOnInit()`);
        this.logger.debug(`ngOnInit() -> deviceService.checkVideoCallPermissions()`);
        this.deviceService.checkVideoCallPermissions();
        // prevent back button from closing modal without ending call
        this.backAction = this.platform.backButton.subscribeWithPriority(1, () => {
            this.logger.debug(`ngOnInit() platform.backButton fired!`);
            this.dismiss();
        });
        // incoming calls will only have caregiver's hrsid.
        // get caregiver's name from hrsid to display.
        if (this.callData.calleeName === 'Caregiver') {
            this.logger.debug(`ngOnInit() loading caregiver...`);
            let cgLoaded = this.eventService.caregiversLoaded.subscribe(() => {
                const cg = this.user.getCaregiver(this.caregiver.chatroom);
                this.logger.debug(`ngOnInit() loaded caregiver`, cg);
                this.callData.calleeName = cg.firstName + ' ' + cg.lastName;
                cgLoaded.unsubscribe();
            });
        }
        this.callId = this.callData.callId;
        // get user info
        this.userHrsId = this.user.id;
        // incoming call, show ring status & start ring audio
        if (this.callData.callId) {
            this.logger.debug(`ngOnInit() enabling accept button for call`);
            this.primaryButtonText = 'ACCEPT_BUTTON';
            if (!this.callData.answer) {
                this.logger.debug(`ngOnInit() -> audio.startPlayback()`);
                this.callAccepted = false;
                const audioConfig: AudioLoopConfig = {
                    duration: environment.RINGER_DURATION // loop for 90 seconds
                };
                // setup to listen for loop end event and terminate incoming call when it ends
                this.nativeAudioEventSubscription = this.audio.filterLoopEnd(AudioRingType.RING, audioConfig.duration).subscribe({
                    next: () => {
                        if (!this.callAccepted) {
                            this.dismiss();
                            this.nativeAudioEventSubscription.unsubscribe();
                            this.nativeAudioEventSubscription = null;
                        }
                    }
                });
                if (BuildUtility.isHRSTab()) {
                    // Aquiring wake lock for 90 seconds
                    this.wakeLock.acquireWakeLock({value: environment.RINGER_DURATION});
                }
                this.audio.startPlayback(AudioRingType.RING, AudioPlayType.LOOP, audioConfig);
            }
        }
        // if the modal is initialized with a callId we are receiving a call, begin ringing
        const currentStatus = this.callData.callId ? this.translateService.instant('RINGING') : '';
        const currentParticipant = this.callData.calleeName ||
            (this.caregiver ? `${this.caregiver.firstName} ${this.caregiver.lastName}` : this.translateService.instant('GENERIC.CLINICIAN'));
        this.contentConfig = [{
            comms: {
                type: 'video',
                status: currentStatus,
                participant: currentParticipant
            }
        }];
        // if left another call to answer, answer once the modal is open
        if (this.callData.answer) {
            this.logger.debug(`ngOnInit() left another call to answer, answer once the modal is open`);
            this.initializeVideoCall();
        }
        this.initNotificationListeners();

        this.taskTrackingService.startTracking('video-call', 'Opened video call modal');
    }

    ngOnDestroy() {
        this.logger.debug(`ngOnDestroy()`);
        // user may not have 'Accept'ed the call
        this.stopRinging();

        if (this.calling) {
            this.logger.debug(`ngOnDestroy() ending call`);
            this.endCall(false);
        }
        this.modalService.setModalStatus('VideoPage', false);
        this.taskTrackingService.stopTracking();
        // removes hardware back button handler
        if (this.backAction) this.backAction.unsubscribe();
        // unsubscribe from listeners
        if (this.callConnectedEvent) this.callConnectedEvent.unsubscribe();
        if (this.endCallEvent) this.endCallEvent.unsubscribe();
        if (this.exitCallEnterNewEvent) this.exitCallEnterNewEvent.unsubscribe();
        if (this.callerLeft) this.callerLeft.unsubscribe();
        if (this.nativeAudioEventSubscription) this.nativeAudioEventSubscription.unsubscribe();

        if (this.callTimeout) {
            this.logger.debug(`ngOnDestroy() clearing call timeout`);
            clearTimeout(this.callTimeout);
        }
    }

    /**
     * Subscribe to Comms Service Event notifications
     */
    initNotificationListeners() {
        this.logger.debug(`initNotificationListeners()`);
        // needed for iOS: the Vonage video call was connected - used to notify VideoPage from OpenTokService listeners
        this.callConnectedEvent = this.communication.videoCallConnected$.subscribe((data: VideoCallLeftData) => {
            this.logger.debug(`initNotificationListeners() videoCallConnected`);
            if (this.callData.callId === data.callId) {
                this.setCallStatus('CALL_CONNECTED');
            } else {
                this.logger.debug(`initNotificationListeners() callIds do not match: this call (${this.callData.callId}) - connected call (${data.callId})`);
            }
        });

        // the person we're calling missed or ignored the call
        this.endCallEvent = this.communication.endVideoCall$.subscribe((data: VideoNotification) => {
            this.logger.debug(`initNotificationListeners() the person we're calling missed or ignored the call`);
            this.onCallMissed(data);
        });

        this.callerLeft = this.communication.callerLeft$.subscribe((data: VideoCallLeftData) => {
            // if the call has not been answered and the callid's match, end the call - called when the call is still ringing but the caller has hung up
            this.logger.debug(`initNotificationListeners() caller left`);
            if (this.callData.callId === data.callId) {
                this.logger.debug(`initNotificationListeners() call is still ringing but the caller has hung up`);
                this.endCall(true, true, true);
            } else {
                this.logger.debug(`initNotificationListeners() callIds do not match: this call (${this.callData.callId}) - call left (${data.callId})`);
            }
        });

        // End current call and join incoming call
        // event listener, call in progress, call pending in background
        this.exitCallEnterNewEvent = this.communication.exitVideoCallEnterNew$.subscribe(() => {
            this.logger.debug(`initNotificationListeners() End current call and join incoming call`);
            this.onNewCommunication();
        });
    }

    // callback for event listened, call missed or call ignored
    private onCallMissed(data: VideoNotification): void {
        this.logger.debug(`onCallMissed()`, data);
        if (
            data &&
            data.callId == this.callId &&
            (data.action === 'call_unanswered' || data.action === 'call_declined')
        ) {
            const displayStatuses = {
                'call_unanswered': 'CALL_UNAVAILABLE',
                'call_declined': 'CALL_DECLINED'
            };
            if (this.communication.videoCallType() === VideoCallType.OPENTOK) {
                // Allow user to retry call if OpenTok.
                this.logger.debug(`onCallMissed() Allow user to retry call if OpenTok (action = ${data.action})`);
                this.allowRetryCall(displayStatuses[data.action]);
            } else {
                // Because the modal closes after we enter Zoom's call UI, just end the call.
                this.logger.debug(`onCallMissed() modal closed after entering Zoom's call UI`);
                this.endCall();
            }
        }
    }

    // callback for event listener, call in progress, call pending in background -- should be able to remove once native ui is implemented
    onNewCommunication() {
        this.logger.debug(`onNewCommunication()`);
        this.endCall();
    }

    /**
     * Toggle button between start call/ end call
     */
    toggleCall() {
        this.logger.debug(`toggleCall()`);
        if (this.calling) {
            this.logger.debug(`toggleCall() -> calling`);
            this.endCall();
        } else {
            // user 'Accept'ed the call
            this.logger.debug(`toggleCall() -> user accepted the call`);
            this.callAccepted = true;
            this.stopRinging();
            if (!this.modalClosing) {
                this.logger.debug(`toggleCall() -> initializeVideoCall()`);
                this.initializeVideoCall();
            }
        }
    }

    /**
     * Create a call or accept an incoming call
     */
    private initializeVideoCall(): void {
        this.logger.debug(`initializeVideoCall()`);
        this.calling = true;
        this.setCallStatus('CONNECTING');
        this.ref.detectChanges();

        const isIncomingCall = !!this.callId;
        const initiationType = isIncomingCall ? CallActionOrigin.REMOTE : CallActionOrigin.LOCAL;
        this.communication.activeCallInitiationType = initiationType;

        if (isIncomingCall) {
            // incoming call
            this.logger.debug(`initializeVideoCall() incoming call`);
            this.getVideoCallToken();
        } else {
            // starting a call
            this.logger.debug(`initializeVideoCall() starting a call`);
            this.getCallId();
        }
    }

    /**
     * Creates a call by requesting a callId
     * on success starts a request for the call Token
     */
    getCallId() {
        this.logger.debug(`getCallId()`);
        let req = this.caregiver ?
            this.communication.getVideoCallId(this.userHrsId, this.caregiver.id) :
            this.communication.getVideoCallId(this.userHrsId);
        // tablet rings on success
        req.subscribe({
            next: (res: any) => {
                this.logger.debug(`getCallId() response`, res);
                this.callId = res.data.id;
                this.getVideoCallToken();
            },
            error: (err) => {
                this.calling = false;
                this.logger.phic.error('getCallId() Error: ', err);
                this.errorPlacingCall();
            }
        });
    }

    /**
     *  Requests token, api key, and sessionId for entering the call room
     */
    getVideoCallToken() {
        this.logger.debug(`getVideoCallToken()`);
        this.communication.getVideoCallToken(this.callId, this.userHrsId)
            .subscribe(
                {
                    next: (res: any) => {
                        this.logger.debug(`getVideoCallToken() response`, res);
                        const jwtToken = res.data.value;
                        this.apiKey = res.data.projectKey;
                        this.sessionId = res.data.sessionId;
                        this.token = res.data.value;
                        this.callData = {...this.callData, ...res.data};
                        this.communication.videoParticipantId = res.data.id;
                        this.communication.videoCallId = res.data.callId;
                        if (res.data.provider === 'zoom') {
                            this.logger.debug(`getVideoCallToken() zoomService.initWithJWTToken`);
                            this.zoomService.setZoomMeetingId(this.sessionId);
                            this.zoomService.initWithJWTToken(jwtToken).then((message) => {
                                this.logger.phic.log('Successfully initialized zoom, now will join meeting');
                                this.enterZoomVideoCall(this.callData.sessionId, this.callData.password);
                            }).catch((err) => {
                                this.logger.phic.debug('Zoom is still not initialized thus not placing the call');
                            });
                        } else {
                            if (this.deviceService.hasVideoCallPermissions || this.platform.is('ios')) {
                                this.logger.debug(`getVideoCallToken() -> enterOpenTokVideoCall()`);
                                this.enterOpenTokVideoCall();
                            } else {
                                this.logger.phic.debug('getVideoCallToken() Camera or microphone permission not allowed');
                            }
                        }
                    },
                    error: (err) => {
                        this.calling = false;
                        this.logger.phic.error('getVideoCallToken() Error: ', err);
                        this.errorPlacingCall();
                    }
                }
            );
    }

    private enterZoomVideoCall(meetingNumber: string, password: string): void {
        this.logger.debug(`enterZoomVideoCall()`, {meetingNumber});
        // Note the display name is just in english because we don't know what language the recipient would want to see it in
        this.zoomService.joinMeeting(meetingNumber, password, 'Patient').then(
            () => {
                this.logger.debug(`enterZoomVideoCall() meeting joined`);
                this.communication.notifyVideoCallConnected(this.callData);
                // Zoom call was joined successfully. Since the Zoom call opens a separate page, when you end the call
                // there's no reason to still have our video modal still open behind it and have an extra step to close that,
                // so go ahead and close it here.
                setTimeout(() => {
                    this.logger.debug(`enterZoomVideoCall() closing modal after meeting join`);
                    this.setCallStatus('CALL_ENDED');
                    this.calling = false;
                    this.dismiss();
                }, 3000);
            },
            (err) => {
                this.logger.error(`enterZoomVideoCall() ERROR`, err);
                this.setCallStatus('CALL_FAILED');
            }
        );
    }

    /**
     * Enter call and wait for answer event
     * Also handles end call event after the person we are calling accepts
     */
    private enterOpenTokVideoCall(): void {
        this.logger.debug(`enterOpenTokVideoCall()`);
        // Session was subscribed twice in the case when android app was closed and
        // then was launched to recieve the call from push notification.
        // As a result, two views of the subscriber were attached and ending call
        // caused frozen black screen issue on android.
        if (!this.opentok.hasActiveSession()) {
            this.logger.debug(`enterOpenTokVideoCall() initializing opentok session...`);
            this.opentok.initVideoCall(this.apiKey, this.sessionId, this.token, 'videoCallPublisher', 'videoCallSubscriber');
            this.timeoutCall();
        } else {
            this.logger.phic.log('enterOpenTokVideoCall() Not initializing session again as Open tok session already exists');
        }
    }

    private timeoutCall(): void {
        this.logger.debug(`timeoutCall()`);
        // JIR-9499: Match CC2 functionality that ends the outgoing call attempt after 90s.
        this.callTimeout = setTimeout(() => {
            this.logger.debug(`timeoutCall() -> getVideoCallStatus()`);
            this.communication.getVideoCallStatus(this.callId).subscribe({
                next: (res) => {
                    this.logger.debug(`timeoutCall() got video status`, res);
                    const status = res.data.status;
                    const displayStatuses = {
                        'missed': 'CALL_UNAVAILABLE',
                        'declined': 'CALL_DECLINED',
                        'ready': 'CALL_UNAVAILABLE'
                    };
                    // Keep modal up and update UI with new call status and option to retry call.
                    if (displayStatuses[status]) {
                        this.logger.debug(`timeoutCall() -> allowRetryCall()`);
                        this.allowRetryCall(displayStatuses[status]);
                    }
                },
                error: (err) => {
                    this.logger.phic.error(`timeoutCall() -> getVideoCallStatus() ERROR`, err);
                    // End call and dismiss the modal if status can not be verified.
                    if (this.opentok.hasActiveSession()) {
                        this.logger.error(`timeoutCall() ending opentok call`);
                        this.endCall();
                    }
                }
            });
        }, environment.RINGER_DURATION);
    }

    /**
     * End call and update modal to show Unavailable or Declined status and Retry button.
     */
    private allowRetryCall(status: string): void {
        this.logger.debug(`allowRetryCall()`, {status});
        if (this.opentok.hasActiveSession()) {
            this.logger.debug(`allowRetryCall() ending opentok call`);
            this.endCall(false, false);
        }
        this.primaryButtonText = 'RETRY_BUTTON';
        this.setCallStatus(status);
        this.modalClosing = false;
        this.ref.detectChanges();
    }

    private setCallStatus(status: string): void {
        this.logger.debug(`setCallStatus()`, {status});
        const translatedStatus = this.translateService.instant(status);
        // We need to pass a new array to HRSContent for the component to detect the change in its content prop.
        this.contentConfig = [{comms: {...this.contentConfig[0].comms, status: translatedStatus}}];
    }

    /**
     * End call and clean up
     */
    private endCall(dismiss: boolean = true, updateStatus: boolean = true, callerLeft: boolean = false): void {
        this.logger.debug(`endCall()`, {dismiss, updateStatus, callerLeft});
        this.calling = false;
        // disable the call button so we don't accidentally start a new call with the modal closed
        this.modalClosing = true;
        if (this.opentok.hasActiveSession()) {
            this.logger.debug(`endCall() active opentok session detected`);
            // end connected calls
            if (updateStatus) {
                this.logger.debug(`endCall() updating call status`);
                this.setCallStatus('CALL_ENDED');
            }
            this.opentok.closeVideoCall();
            this.callId = null;
            this.apiKey = null;
            this.sessionId = null;
            this.token = null;
        }

        try {
            this.ref.detectChanges();
        } catch (err) {
            this.logger.phic.error(`ref.detectChanges() error`, err);
        }

        if (dismiss) {
            this.logger.debug(`endCall() dismissing modal...`);
            // close the modal/ set timeout to briefly display call ended status
            this.dismiss(callerLeft);
        }
    }

    /**
     * Dismiss modal
     * prompt user first in the case that they are already on a call
     */
    public dismiss(callerLeft: boolean = false): void {
        const calling = this.calling;
        const callStatus = this.contentConfig[0].comms.status;
        const isRinging = typeof callStatus === 'string' && callStatus.toLowerCase().includes('ringing');
        const isZoomCall = this.communication.videoCallType() === VideoCallType.ZOOM;
        this.logger.debug(`dismiss()`, {calling, callerLeft, isRinging, callStatus, isZoomCall});

        if (calling) {
            this.logger.debug(`dismiss() user tried to close the modal during a call`);
            // user tried to close the modal during a call
            this.showCloseModalPrompt();
            return;
        }

        if (!callerLeft && this.callData?.callId) {
            if (isRinging) {
                this.logger.debug(`dismiss() user exited modal while incoming call was ringing (call declined)`);
                if (!isZoomCall) this.communication.notifyVideoCallDeclinedLocally(this.callData);
                // user exited modal while incoming call was ringing
                this.setCallStatus('CALL_DECLINED');
                this.communication.updateParticipantStatus('declined');
            } else if (!isZoomCall) {
                this.logger.debug(`dismiss() user ended call locally`);
                this.communication.notifyVideoCallEndedLocally(this.callData);
            }
        }

        this.logger.debug(`dismiss() call has ended, close modal`);
        // call has ended close modal
        this.callData = null;
        this.modalClosing = true;
        this.overlayRef.dismiss();
    }

    /**
     * Show alert if call fails
     */
    private async errorPlacingCall(): Promise<OverlayRef> {
        this.logger.debug(`errorPlacingCall()`);
        this.setCallStatus('CALL_FAILED');
        return await this.overlay.openAlert({
            header: this.translateService.instant('ERROR_TITLE'),
            message: [this.translateService.instant('VIDEO_CALL_ERROR')],
            variant: 'error',
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    role: 'cancel',
                }, {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.initializeVideoCall();
                    }
                }
            ],
            qa: 'video_call_alert'
        });
    }

    /**
     * Prompt user whether to end call and exit modal or continue call
     */
    showCloseModalPrompt() {
        this.logger.debug(`showCloseModalPrompt()`);
        // native dialog bc ionic alert would not display over video
        this.dialogs.confirm(
            this.translateService.instant('END_CALL_MESSAGE'),
            this.translateService.instant('END_CALL_TITLE'),
            [
                this.translateService.instant('END_CALL'),
                this.translateService.instant('CONTINUE')
            ]
        ).then((e: any) => {
            this.logger.debug(`showCloseModalPrompt() dialog result`, e);
            // if e is 1 the user clicked End Call
            // e refers to the selections index in the buttonLabels array
            // this index in this case starts at 1
            if (e && e === 1) {
                this.endCall();
            }
        });
    }

    private stopRinging(): void {
        this.logger.debug(`stopRinging()`);
        this.wakeLock.releaseWakeLock();
        this.audio.endPlayback(AudioRingType.RING);
    }
}

