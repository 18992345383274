import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MenuController, NavController, Platform} from '@ionic/angular';
import * as jwtDecode from 'jwt-decode';
import {finalize, tap, map, share} from 'rxjs/operators';
import {EventService} from '../events/event.service';
import {FirebaseNotifications} from '../firebase/firebase';
import {OverlayService} from '../overlay/overlay.service';
import {Settings} from '../settings/settings.service';
import {GatewayService, TokenResponse} from '@hrs/gateway';
import {CommunicationService, GatewayApi, TokenService} from '@hrs/providers';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {ChatroomResponse} from '@hrs/interfaces';
import {Caregiver} from './caregiver.interface';
import {HRSStorage} from '../storage/storage';
import {BuildUtility} from '@hrs/utility';
import {AudioService} from '../audio/audio.service';
import {EncryptionService} from '../encryption/encryption.service';
import {HRSSecureCache} from '../storage/cache';
import {DeviceProfileService} from '../../hrs-tablet/device-profile/device-profile.service';
import {WELCH_BP_SCALE_PAIRING_PASS_KEY, WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY} from '../device/welch/welch.service';
import {BLUETOOTH_KEY_BLOODPRESSURE, BLUETOOTH_KEY_GLUCOSE, BLUETOOTH_KEY_PULSEOX, BLUETOOTH_KEY_TEMPERATURE, BLUETOOTH_KEY_WEIGHT} from '../device/device.service';
import {ActivationHistoryResponse} from './activation-history.interface';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})

// to correct issues with cyclic dependency injections, User needs to inject User._data.id directly into HRSSecureCache
// anywhere we update User._data, we need to update this.cache.userID

export abstract class User {
    private readonly userLogger = getLogger('User');
    authenticationState = new BehaviorSubject(false);
    static _token: any; // jwt token
    static _data: any; // decoded jwt token containing things like user id, environment name, session expiration
    static caregivers: Caregiver[];
    // if the camera opens to take a wound imaging picture, we don't want to close the wound imaging modal.
    // On android opening the camera fires the 'platform.pause' event, which there is a function in home.page.ts that closes any open modals when the platform is paused.
    // The wound-imaging.component.ts sets this flag on the user that this patient is currently taking a picture and even
    // though the platform is paused, we will not close the wound-imaging modal.
    takingPicture: boolean;
    sourceApp: string;

    constructor(
        private audio: AudioService,
        private cache: HRSSecureCache,
        private communication: CommunicationService,
        private deviceProfile: DeviceProfileService,
        private encryptionService: EncryptionService,
        protected eventService: EventService,
        protected gatewayApi: GatewayApi,
        protected gatewayService: GatewayService,
        private menuCtrl: MenuController,
        private overlay: OverlayService,
        private platform: Platform,
        protected settings: Settings,
        private storage: HRSStorage,
        protected tokenService: TokenService,
        protected firebase: FirebaseNotifications,
        protected navCtrl: NavController
    ) {
        this.sourceApp = 'PatientConnect Mobile';
        this.tokenService.sourceApp = this.sourceApp;

        this.tokenService.refreshTokenSubject.subscribe((tokenData: TokenResponse) => {
            this.settings.setValue('token', tokenData.attributes.token);
            this.settings.setValue('refresh', tokenData.attributes.refresh);
            User.loadFromSettings(this.settings);
            this.cache.userID = this.id;
        });

        this.settings.settingsUpdated.subscribe((settings: Settings) => {
            User.loadFromSettings(settings);
            this.cache.userID = this.id;
        });
    }

    abstract login(username?: string, code?: string);
    abstract checkCurrentUser(): Promise<boolean>;

    static hasCaregivers(): boolean {
        return !!User.caregivers &&
            User.caregivers.length > 0 &&
            // make sure it's not an array with a single element of `null`
            (User.caregivers.length !== 1 || User.caregivers[0] !== null);
    }

    static loadFromSettings(settings: Settings) {
        User._token = settings.getValue('token');
        if (User._token) {
            try {
                User._data = jwtDecode(User._token);
            } catch (e) {
                console.error('Error decoding token', e);
                return;
            }
        }
    }

    get id(): string {
        if (User._data) {
            return User._data.sub;
        }
    }

    get environment(): string {
        if (User._data) {
            return User._data.environment;
        }
    }

    get token(): string {
        return User._token;
    }

    get status(): string {
        return User._data.status;
    }

    get type(): string {
        if (User._data && User._data.type) {
            return User._data.type;
        }
    }

    static getToken() {
        return User._token;
    }

    isPatient(): boolean {
        return this.type && this.type === 'patient';
    }

    isDevice(): boolean {
        return this.type && this.type === 'device';
    }

    public requestLogin(credentials): Observable<void> {
        if (BuildUtility.isHRSTab()) this.userLogger.debug(`User.requestLogin | Requesting PCMT login for: ${credentials.data.toString()}`);
        let data = this.gatewayApi.login(credentials);
        return data.pipe(tap((res: any) => {
            this.tokenService.sessionId++;

            let resData = res.data.attributes;
            let token = resData.token;
            const decodedToken = jwtDecode(token);
            const tokenType = decodedToken['type'];
            const tokenID = decodedToken['sub'];
            if (token && tokenType === 'patient' || (BuildUtility.isHRSTab() && tokenType === 'device')) {
                this.userLogger.debug(`User.requestLogin | success for type "${tokenType}" and id ${tokenID}`);
                // We want the patient to be able to log in only the first time, so we store the token and will check for it
                // each time the app launches.
                this.settings.setValue('token', token);
                this.settings.setValue('refresh', resData.refresh);
                this.tokenService.storeTokens(res.data);
                this.authenticationState.next(true);
                User.loadFromSettings(this.settings);
                this.cache.userID = this.id;
                this.afterLogin();
            } else {
                this.userLogger.phic.error(`User.requestLogin | No token. Redirecting to login`);
                // Show the login page
                this.redirectToLogin();
                this.authenticationState.next(false);
            }
        }));
    }

    private async retainStorageKeys(): Promise<Map<string, any>> {
        this.userLogger.phic.log('Will retain storage important keys at logout/reassign');
        // We are retaining paired devices, welch pairing keys, imei
        let keysToRetain = [WELCH_BP_SCALE_PAIRING_PASS_KEY, WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY,
            'imei', BLUETOOTH_KEY_GLUCOSE, BLUETOOTH_KEY_BLOODPRESSURE, BLUETOOTH_KEY_TEMPERATURE, BLUETOOTH_KEY_WEIGHT, BLUETOOTH_KEY_PULSEOX];

        let storageEntriesToRetain = new Map<string, any>();
        for (const key of keysToRetain) {
            let value = await this.storage.get(key);
            if (value) {
                storageEntriesToRetain.set(key, value);
            }
        }
        this.userLogger.phic.log('Retained storage keys returning with size ' + storageEntriesToRetain);
        return storageEntriesToRetain;
    }

    public async logout(): Promise<any> {
        if (this.isLoggedIn()) {
            this.tokenService.sessionId++;

            let storageEntriesToRetain = await this.retainStorageKeys();

            // Clear all storage (credentials, settings, cache...)
            await this.storage.clear();

            this.userLogger.phic.log('Retaining values again to storage ' + storageEntriesToRetain.size);
            storageEntriesToRetain.forEach(
                (value, key) => {
                    this.userLogger.phic.log('Got entries to retain ' + key + ' ' + value);
                    if (value) {
                        this.storage.set(key, value);
                        this.userLogger.phic.log('Restored storage entry successfully', JSON.stringify(value));
                    }
                }
            );

            User._token = undefined;
            User._data = undefined;
            this.cache.userID = undefined;
            User.caregivers = undefined;
            await this.gatewayApi.logout();
            this.gatewayService.logout();
            this.settings.clear();
            this.encryptionService.localPrivateKey = undefined;
            if (this.platform.is('cordova') && this.platform.is('ios')) {
                // DEV-20008: This call has been moved into the FCM plugin for Android for .
                this.firebase.deleteInstanceId();
            }
            if (BuildUtility.isHRSTab()) {
                this.audio.resetDefaults();
                this.deviceProfile.unsubscribe();
            }
            this.eventService.loginStateChanged.next(false);
            await this.redirectToLogin();
        }
        this.closeAlert();
        this.authenticationState.next(false);
    }

    closeAlert(): void {
        const dismiss = async () => {
            return await this.overlay.dismiss();
        };

        const checkAlert = async () => {
            return this.overlay.getTop('alert');
        };

        checkAlert().then((alert) => {
            if (alert) dismiss().then(() => {});
        });
    }

    afterLogin(): void {
        // Enabling the side menu button here because when the user changes domain settings and is logged out,
        // the menu button is disabled. Need to enable again once they are logging back into the new domain env.
        if (this.isPatient()) {
            this.menuCtrl.enable(true);
            this.gatewayApi.hrsid = this.id;
            this.gatewayService.hrsid = this.id;
        }

        if (this.platform.is('cordova')) {
            this.firebase.initializeFirebase(this);
        }
        if (BuildUtility.isHRSTab()) {
            this.deviceProfile.subscribe();
        }

        this.eventService.loginStateChanged.next(true);
    }

    isLoggedIn() {
        return !!this.id && this.gatewayApi.isLoggedIn();
    }

    /**
     * Send a GET request for list of patient's caregivers
     */
    public getCaregivers(): Observable<any> {
        const url = 'patient-links/?filter[patient]=' + this.id + '&sideload=caregiver';
        const request = this.gatewayApi.get(url).pipe(share());
        request.subscribe(
            {
                next: (res: any) => {
                    if (res.data.length > 0) {
                        User.caregivers = Object.keys(res.sideload.caregiver).map((key) => {
                            return res.sideload.caregiver[key];
                        }).sort((a, b) => {
                            return a.firstName.localeCompare(b.firstName);
                        });
                    }
                    this.getChatrooms();
                },
                error: (err: HttpErrorResponse) => {
                    this.userLogger.phic.error('Failed to fetch caregivers', err);
                }
            }
        );
        return request;
    }

    private getChatrooms(): void {
        this.communication.getChatrooms([this.id]).pipe(
            finalize(() => {
                this.eventService.caregiversLoaded.next();
            })
        ).subscribe((res: ChatroomResponse) => {
            res.data.forEach((chatroom) => {
                let caregiversIsNotNull = User.caregivers.some((el) => {
                    return el !== null;
                });
                if (User.caregivers && caregiversIsNotNull) {
                    User.caregivers.forEach((caregiver) => {
                        if (chatroom.participants.length === 2 && chatroom.participants.includes(this.id) && chatroom.participants.includes(caregiver.id)) {
                            caregiver.chatroom = chatroom.id;
                        }
                    });
                } else {
                    User.caregivers = undefined;
                }
            });
        });
    }

    /**
     * Find the Caregiver that matches the given Chatroom Id or HRS Id.
     * Update Caregivers if no match is found.
     */
    getCaregiver(chatroom?: string | number, hrsid?: string): Caregiver {
        if (User.caregivers) {
            let match = User.caregivers.filter((caregiver) => {
                if (chatroom) {
                    return caregiver.chatroom == chatroom;
                } else {
                    return caregiver.id === hrsid;
                }
            });
            if (match.length > 0) {
                return match[0];
            } else {
                this.getCaregivers();
            }
        }
    }

    public getUserData(): Promise<boolean> {
        // DEV-11580:
        // There has been an issue where a tablet was able to make GET /user and GET /tasks requests with an IMEI in the path.
        // This overloaded the User service and caused it to crash.
        // Log user out if the token is type Device.
        if (this.isDevice()) {
            this.userLogger.phic.error(`Getting User data. User ${this.environment} ${this.id} does not have an HRSID. Logging out.`);
            this.logout();
        }
        return lastValueFrom(this.gatewayApi.get('users/' + this.id))
            .then((res: any) => {
                if (User._data) {
                    User._data.status = res.data.profile.status;
                    User._data.deactivated_at = res.data.profile.deactivated_at;
                }
                return true;
            }).catch((err) => {
                this.userLogger.phic.error('Failed to get user data', err);
                return false;
            });
    }

    public async redirectToLogin(): Promise<void> {
        await this.overlay.dismiss();
        if (BuildUtility.isHRSTab()) {
            await this.navCtrl.navigateRoot('/tablet-white-screen');
        } else {
            await this.navCtrl.navigateRoot('/login');
        }
    }

    public getActivationHistory(): Observable<ActivationHistoryResponse> {
        return this.gatewayApi.get(`activation-histories/${this.id}`);
    }

    public getStartOfEpisodeOfCare(): Observable<string> {
        return this.getActivationHistory().pipe(map((res: ActivationHistoryResponse) => {
            const activationHistory = res?.data?.attributes;
            let dayCount = activationHistory?.episodesOfCare[0]?.dayCount;
            // if day count is zero, consider today the start of episode of care.
            // Daily metrics shows today's metrics no matter what, we want to match that
            if (dayCount === 0) return new Date().toDateString();
            // if day count is more than zero, return the start of EOC
            return activationHistory?.episodesOfCare[0]?.episodeRange?.start;
        }));
    }
}
