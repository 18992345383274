import {Injectable} from '@angular/core';
import {FirebaseCrashlytics} from '@capacitor-firebase/crashlytics';
import {HRSAppErrorHandler} from '@hrs/providers';
import * as StackTrace from 'stacktrace-js';

@Injectable({
    providedIn: 'root'
})
export class MobilePatientAppErrorHandler extends HRSAppErrorHandler{
    async handleError(error: any): Promise<void> {
        super.handleError(error);
        // Send the error to Firebase logging service
        const stacktrace = await StackTrace.fromError(error);
        await FirebaseCrashlytics.recordException({message: '>>> raw error ' + error, stacktrace});
    }
}
