import {Injectable, NgZone} from '@angular/core';
import {BLE} from '@ionic-native/ble/ngx';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {Device} from '@ionic-native/device/ngx';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {GlucoseDeviceService, TNG_BLE_GLUCOMETER} from './glucose.service';
import {PulseOxDeviceService} from './pulseox.service';
import {BloodPressureDeviceService} from './bloodpressure.service';
import {TemperatureDeviceService} from './temperature.service';
import {WeightDeviceService} from './weight.service';
import {HRSStorage} from '../storage/storage';
import {AndroidPermissionResponse, AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {BuildUtility} from '@hrs/utility';
import {ModalService} from '@hrs/providers';
import {getLogger} from '@hrs/logging';
import {Subject, Subscription, timer} from 'rxjs';
import {
    BPDeviceText,
    OximeterDeviceText,
    ScaleDeviceText,
    ThermometerDeviceText
} from '../../devices/generic-device/types';
import {OverlayService} from '../overlay/overlay.service';
import {OverlayRef} from '../../hrs-overlay';
import {GlucometerDeviceText, GlucometerImageUrl} from '../../devices/generic-device/types/glucometers.types';
import {BPImageUrl} from '../../devices/generic-device/types/bpmonitors.types';
import {ScaleImageUrl} from '../../devices/generic-device/types/scales.types';
import {ThermometerImageUrl} from '../../devices/generic-device/types/thermometers.types';
import {OximeterImageUrl} from '../../devices/generic-device/types/oximeters.types';
import {templateDevices} from '../../devices/generic-device/types/template-devices';
import {EventService} from '../../services/events/event.service';
import BluetoothUtils from 'src/app/bluetooth-utils';
import {WELCH_BP_SCALE_PAIRING_PASS_KEY, WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY} from './welch/welch.service';
import {TabletDeviceIdService} from '../tablet-device-id/tablet-device-id';
import {HRSFirebaseAnalytics, HRSFirebaseEvents, HRSFirebaseParams, HRSFirebaseErrorReason} from '../analytics/firebaseanalytics.service';

export let BLUETOOTH_KEY_PULSEOX: string = 'bluetooth_pulseox';
export let BLUETOOTH_KEY_GLUCOSE: string = 'bluetooth_glucose';
export let BLUETOOTH_KEY_TEMPERATURE: string = 'bluetooth_temperature';
export let BLUETOOTH_KEY_BLOODPRESSURE: string = 'bluetooth_bloodpressure';
export let BLUETOOTH_KEY_WEIGHT: string = 'bluetooth_weight';

export enum BT_DEVICE_TYPE {
    CLASSIC = 'DEVICE_TYPE_CLASSIC',
    BLE = 'DEVICE_TYPE_LE'
}

declare var cordova: any;
declare var bluetoothSerial: any;

@Injectable({
    providedIn: 'root',
})

export class DeviceService {
    private readonly logger = getLogger('DeviceService');

    private pollingSubscription: Subscription;
    private offlineTaskSubscription: Subscription;
    public devicesUpdated: Subject<void> = new Subject();
    availableBLEDevices: any[] = [];
    pairedDevices: any[] = [];
    availableClassicDevices: any[] = [];
    isDeviceConnected: boolean = false;
    pairedGlucometerDevices: any[] = [];
    pairedOximeterDevices: any[] = [];
    pairedBPMonitorDevices: any[] = [];
    pairedScaleDevices: any[] = [];
    pairedThermometerDevices: any[] = [];
    timeAndDate: moment.Moment;
    peripheral: any;
    count: number;
    hasAndroidPermission: boolean = false;
    allPairedDevices: any[] = [];
    isHRSTablet: boolean = BuildUtility.isHRSTab();
    deviceVersion: any;
    deviceIMEI: string = null;
    public selectedPeripheralType: string; // property which is set from generic-device page when user selects device based on peripheral type
    hasVideoCallPermissions: boolean = false; // For android we need camera and audio permissions for opentok video calls

    // Services and Characteristics to connect to device
    NONIN_3230_SERVICE = '46A970E0-0D5F-11E2-8B5E-0002A5D5C51B';
    NONIN_3230_CHARACTERISTIC = '0AAD7EA0-0D60-11E2-8E3C-0002A5D5C51B';
    FORACARE_SERVICE = '00001523-1212-EFDE-1523-785FEABCD123';
    TD8255_DISCOVERY_SERVICE = '180a'; // Taidoc TD8255 is scanning based on this service
    FORACARE_CHARACTERISTIC = '00001524-1212-EFDE-1523-785FEABCD123';
    public WELCH_SCALE_SERVICE: string = '7802';
    public WELCH_SCALE_CHARACTERISTIC: string = '8a21';
    public WELCH_BP_SERVICE: string = '7809';
    public WELCH_BP_MEASUREMENT_CHAR: string = '8A91';
    AD_SERVICE = '1810';
    AD_CHARACTERISTIC = '2A35';
    ADSCALE_SERVICE = '23434100-1FE4-1EFF-80CB-00FF78297D8B';
    ADSCALE_CHARACTERISTIC = '23434101-1FE4-1EFF-80CB-00FF78297D8B';

    public AND_SCALE_356_SERVICE = 'cf1e9810-d4fc-0ba7-0a9e-cdaf76853000';
    public AND_SCALE_356_CHARDER_USER_DATA_CHAR = 'cf1e9812-4fc0-ba70-a9ec-daf768530000';

    NIPROBGM_CONTROL_SERVICE = '00A215BD-9B02-4E5A-9A65-98F1095F4755';
    NIPROBGM_CONTROL_CHARACTERISTIC = '448AF2D8-36A2-42A1-86B5-F51207C36760';
    NIPROBGM_SERVICE = '1808';
    NIPROBGM_CHARACTERISTIC = '2A18';
    NIPROBGM_GLUCOSE_MEASUREMENT_CONTEXT = '2A34';
    NIPROBGM_DATA_CHARACTERISTIC = '2A52';
    FORACARE_IR20_SERVICE = '1809';
    FORACARE_IR20_CHARACTERISTIC = '2A1C';

    // BP Monitor images
    public bpUA651ImageUrl: BPImageUrl = 'assets/img/bp_a&d_ua651ble_3X2.jpg';
    public bpUA767ImageUrl: BPImageUrl = 'assets/img/bp_a&d_ua767_3X2.jpg';
    public bpWelchAllynImageUrl: BPImageUrl = 'assets/img/bp_welchallyn_3X2.jpg';

    // Glucometer images
    public niproGlucometerImageUrl: GlucometerImageUrl = 'assets/img/glucometer_trumix_air_3X2.jpg';
    public testNGoGlucometerImageUrl: GlucometerImageUrl = 'assets/img/glucometer_fora_tngble_3X2.jpg';

    // Oximeter images
    public noninPulseOxImageUrl: OximeterImageUrl = 'assets/img/ox_nonin3230_3X2.jpg';
    public foracarePulseOxImageUrl: OximeterImageUrl = 'assets/img/ox_foratngsp02_3X2.jpg';
    public taidocPulseOxImageUrl: OximeterImageUrl = 'assets/img/ox_taidoctd8255_3X2.jpg';
    public noninMedicalPulseOxImageUrl: OximeterImageUrl = 'assets/img/ox_nonin_onyxII9560_3X2.jpg';

    // Scale images
    public tngScaleImageUrl: ScaleImageUrl = 'assets/img/scale_foratng_550white_3X2.jpg';
    public welchAllynScaleImageUrl: ScaleImageUrl = 'assets/img/scale_welchallyn_3X2.jpg';
    public uc351ScaleImageUrl: ScaleImageUrl = 'assets/img/scale_a&d_uc351pbtci_3X2.jpg';
    public uc352ScaleImageUrl: ScaleImageUrl = 'assets/img/scale_a&d_uc352ble_3X2.jpg';
    public uc355ScaleImageUrl: ScaleImageUrl = 'assets/img/scale_a&d_uc355pbtci_3X2.jpg';
    public uc356ScaleImageUrl: ScaleImageUrl = 'assets/img/scale_a&d_uc356ble_3x2.jpg';

    // Thermometer images
    public foracareImageUrl: ThermometerImageUrl = 'assets/img/thermometer_fora_ir20bv4_3X2.jpg';
    public td1107ImageUrl: ThermometerImageUrl = 'assets/img/thermometer_fora_ir20bv4_3X2.jpg';

    // Foracare Byte Commands and Request Messages
    CMD_ACK = 0x51;
    CMD_START = 81;
    CMD_END = 163;
    CMD_COMMS = 0x54;
    CMD_DATA_COUNT = 0x2B;
    CMD_DATA_TIME = 0x25;
    CMD_DATA = 0x26;
    CMD_DATA_SCALE = 0x71;
    CMD_DATA_CLEAR = 0x52;
    CMD_TAKE_MEASUREMENT = 0x41;
    CMD_POWER_OFF = 0x50;
    CMD_DATA_POINTS = 0x2;

    private mScanning: boolean = false;
    private mScanningTimeout: any = null;

    constructor(
        private androidPermissions: AndroidPermissions,
        private ble: BLE,
        private bluetoothSerial: BluetoothSerial,
        private bpDeviceService: BloodPressureDeviceService,
        public device: Device,
        private eventService: EventService,
        private glucoseDeviceService: GlucoseDeviceService,
        private modalService: ModalService,
        private ngZone: NgZone,
        private overlay: OverlayService,
        private platform: Platform,
        private pulseOxDeviceService: PulseOxDeviceService,
        private storage: HRSStorage,
        private tabletDeviceIDService: TabletDeviceIdService,
        private tempDeviceService: TemperatureDeviceService,
        private translate: TranslateService,
        private weightDeviceService: WeightDeviceService,
        private fbAnalytics: HRSFirebaseAnalytics
    ) {
        this.init();
    }

    public get scanning(): boolean {
        return this.mScanning;
    }

    private resetScanningState(): void {
        this.clearScanningFlagTimeout();
        this.mScanning = false;
    }

    private clearScanningFlagTimeout(): void {
        if (this.mScanningTimeout) {
            clearTimeout(this.mScanningTimeout);
            this.mScanningTimeout = null;
        }
    }

    private raiseScanningFlag(timeoutMs: number): void {
        this.resetScanningState();
        this.mScanning = true;
        this.mScanningTimeout = setTimeout(() => {
            this.mScanning = false;
        }, timeoutMs);
    }

    private init(): void {
        if (!this.bluetoothSerial.isEnabled()) {
            this.bluetoothSerial.enable().then(
                (data) => {
                    this.logger.phic.debug('Bluetooth Classic is enabled' + data);
                }, (error) => {
                    this.logger.phic.error('The user did not enable Bluetooth Classic', error);
                }
            );
        }

        if (!this.ble.isEnabled()) {
            this.ble.enable().then(
                (data) => {
                    this.logger.phic.debug('BLE is enabled', data);
                },
                (error) => {
                    this.logger.phic.error('The user did not enable BLE.', error);
                }
            );
        }

        this.getAllPairedValues();
        this.initDeviceVersion();

        // Polling for BT devices requires accessing paired devices from HRSStorage.
        // If a task submission fails, we store the task in HRSStorage,
        // so we want to block any unnecessary calls to storage at this time.
        this.offlineTaskSubscription = this.eventService.storingOfflineTask.subscribe((isSubmittingTask: boolean) => {
            if (isSubmittingTask && this.pollingSubscription) {
                this.unsubscribePollingForBluetoothDevices();
            } else if (!isSubmittingTask && !this.pollingSubscription) {
                this.subscribePollingForBluetoothDevices();
            }
        });
    }

    onDestroy() {
        if (this.offlineTaskSubscription) this.offlineTaskSubscription.unsubscribe();
    }

    /**
     * Scanning for bonded bluetooth classic and BLE devices if app was paired previously with PC Tablet (legacy code)
     * Not needed for Android 11 and beyond because it is not provisioned with legacy code
     */
    async getBondedBluetoothDevices(): Promise<void> {
        // Get all of the bonded devices only the first time. It creates the stored devices array and then it doesn't try to list them again
        // Using bluetooth serial because we need access to previously paired classic devices
        // We should remove this method once we have completely deployed codebase merge
        this.logger.phic.info('Fetching bonded bluetooth devices');
        await this.getAllPairedValues();
        // Checking for IMEI to add to logging
        if (this.isHRSTablet && this.deviceIMEI === null) {
            await this.tabletDeviceIDService.getImei();
            this.deviceIMEI = this.tabletDeviceIDService._imei;
        }

        this.bluetoothSerial.list().then(
            (bondedDevices) => {
                this.logger.phic.debug('Got bonded devices', JSON.stringify(bondedDevices) + 'IMEI = ' + this.deviceIMEI);
                this.addBondedDevicesToStorage(bondedDevices);
            },
            (error) => {
                this.logger.phic.error('Error getting bonded devices', error + 'IMEI = ' + this.deviceIMEI);
            }
        );
    }

    /**
     * Update device type specific arrays for dynamically generated device page
     */
    private addBondedDevicesToStorage(devices: any): void {
        devices.forEach((device) => {
            let deviceType = this.getPeripheralType(device.name);
            this.addToPairedDeviceArray(deviceType, device);
            this.setStorageValue(device, deviceType);
            if (!this.checkIfPairedDeviceExists(this.pairedDevices, device)) {
                this.pairedDevices.push(device);
            }
        });
    }

    /**
     * Update device type specific arrays for dynamically generated device page
     */
    private addToPairedDeviceArray(deviceType: string, device: any): void {
        if (deviceType === 'pulseox') {
            this.addPairedDevice(this.pairedOximeterDevices, device);
        } else if (deviceType === 'glucose') {
            this.addPairedDevice(this.pairedGlucometerDevices, device);
        } else if (deviceType === 'bloodpressure') {
            this.addPairedDevice(this.pairedBPMonitorDevices, device);
        } else if (deviceType === 'temperature') {
            this.addPairedDevice(this.pairedThermometerDevices, device);
        } else if (deviceType === 'weight') {
            this.addPairedDevice(this.pairedScaleDevices, device);
        }
    }

    public async checkForPermission(permission: string): Promise<AndroidPermissionResponse> {
        return this.androidPermissions.checkPermission(permission);
    }

    /**
     * @returns Quering the device version from Device plugin if it is not initialized yet
     */
    public getDeviceVersion(): number {
        if (!this.deviceVersion) {
            this.initDeviceVersion();
        }
        return this.deviceVersion;
    }

    /**
     * Initializing the device version
     */
    private initDeviceVersion() {
        this.deviceVersion = Number(this.device.version);
    }

    public checkAndroidPermissions(): void {
        if (this.getDeviceVersion() <= 11) {
            this.androidPermissions.checkPermission('android.permission.ACCESS_FINE_LOCATION').then(
                (result) => {
                    if (!result.hasPermission) {
                        this.launchPermissionsAlert(['android.permission.ACCESS_FINE_LOCATION']);
                    } else {
                        this.hasAndroidPermission = true;
                    }
                },
                (error) => this.logger.phic.error('Failed to check Android permissions.' + error)
            );
        } else {
            this.checkAndroidSBluetoothPermissions();
        }
    }

    public async checkAndroid13Permissions(permission: string[], permissionAlertTitle: string, permissionMessage: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.getDeviceVersion() > 12) {
                let requiredPermissions: string[] = permission;
                let missingPermissions: string[] = [];
                for (const requiredPermission of requiredPermissions) {
                    await this.checkForPermission(requiredPermission).then((result) => {
                        if (!result.hasPermission) {
                            missingPermissions.push(requiredPermission);
                        }
                    });
                }
                if (missingPermissions && missingPermissions.length > 0) {
                    {
                        this.overlay.openAlert({
                            header: this.translate.instant(permissionAlertTitle),
                            message: [this.translate.instant(permissionMessage)],
                            buttons: [{
                                text: this.translate.instant('CLOSE_BUTTON'),
                                handler: () => {
                                    this.androidPermissions.requestPermissions(missingPermissions).then(
                                        (result) => {
                                            resolve();
                                        }, (error) => {
                                            this.logger.phic.error('Error requesting permission from Android' + error);
                                            reject(error);
                                        }
                                    );
                                }
                            }],
                            qa: 'device_permissions_alert'
                        });
                    }
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    public async checkAndroidSBluetoothPermissions(): Promise<void> {
        if (this.getDeviceVersion() > 11 ) {
            let requiredPermissions : string [] = ['android.permission.BLUETOOTH_SCAN', 'android.permission.BLUETOOTH_CONNECT'];

            let missingPermissions: string[] = [];

            return await Promise.all(requiredPermissions.map((requiredPermission) => {
                return this.checkForPermission(requiredPermission).then((result) => {
                    if (!result.hasPermission) {
                        this.logger.phic.debug('Missing Android S permission ' + requiredPermission);
                        missingPermissions.push(requiredPermission);
                    }
                });
            })).then(() => {
                if (missingPermissions && missingPermissions.length > 0) {
                    this.launchPermissionsAlert(missingPermissions);
                } else {
                    this.hasAndroidPermission = true;
                }
            });
        }
    }

    /**
     * Opentok SDK 2.26 requires that before initiating video calls,
     * This method helps to inform user and check for camera and microphone permissions.
     */
    public async checkVideoCallPermissions(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.platform.is('android') && this.getDeviceVersion() > 6) {
                let requiredPermissions: string[] = ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'];
                let missingPermissions: string[] = [];

                return await Promise.all(requiredPermissions.map((requiredPermission) => {
                    return this.checkForPermission(requiredPermission).then((result) => {
                        if (!result.hasPermission) {
                            this.logger.phic.debug('Missing permission ' + requiredPermission);
                            missingPermissions.push(requiredPermission);
                        }
                    });
                })).then(() => {
                    if (missingPermissions && missingPermissions.length > 0) {
                        this.overlay.openAlert({
                            header: this.translate.instant('VIDEO.ANDROID_PERMISSIONS_TITLE'),
                            message: [this.translate.instant('VIDEO.ANDROID_PERMISSIONS_DETAIL')],
                            buttons: [{
                                text: this.translate.instant('CLOSE_BUTTON'),
                                handler: () => {
                                    this.androidPermissions.requestPermissions(missingPermissions).then(
                                        (result) => {
                                            resolve();
                                        }, (error) => {
                                            this.logger.phic.error('Error requesting permission from Android' + error);
                                            reject(error);
                                        }
                                    );
                                }
                            }],
                            qa: 'device_permissions_alert'
                        });
                    } else {
                        this.hasVideoCallPermissions = true;
                        resolve();
                    }
                });
            } else if (this.platform.is('ios')) {
                this.hasVideoCallPermissions = true;
                resolve();
            }
        });
    }

    public async launchPermissionsAlert(permissions: string[]): Promise<void> {
        let messageTitle = (this.getDeviceVersion() <= 11) ? this.translate.instant('BLUETOOTH.ANDROID_PERMISSIONS_TITLE') : this.translate.instant('BLUETOOTH.ANDROID_PERMISSIONS_TITLE_API_31');
        let messageDetails = (this.getDeviceVersion() <= 11) ? this.translate.instant('BLUETOOTH.ANDROID_PERMISSIONS_DETAIL') : this.translate.instant('BLUETOOTH.ANDROID_PERMISSIONS_DETAIL_API_31');

        await this.overlay.openAlert({
            header: messageTitle,
            message: [messageDetails],
            buttons: [{
                text: this.translate.instant('CLOSE_BUTTON'),
                handler: () => {
                    this.launchPermissionRequest( permissions );
                }
            }],
            qa: 'device_permissions_alert'
        });
    }

    public launchPermissionRequest(permissions: string[]): void {
        this.androidPermissions.requestPermissions(permissions).then(
            (result) => {
                if (result.hasPermission){
                    this.hasAndroidPermission = true;
                } else {
                    this.hasAndroidPermission = false;
                }
            }, (error) => {
                this.hasAndroidPermission = false;
                this.logger.phic.error('Error requesting permission from Android' + error);
            }
        );
    }

    public scanAvailableBLEDevices(): Promise<void> {
        this.logger.trace(`scanAvailableBLEDevices()`);
        const scanDurationSeconds = 8;

        if (this.scanning) {
            this.logger.warn(`scanAvailableBLEDevices() scan already active! aborting call...`);
            return; //  we want to resolve and start classic scanning in all events apart from when BLE scan is already in progress
        }

        return new Promise((resolve, reject) => {
            let bleServices = [this.NONIN_3230_SERVICE, this.FORACARE_SERVICE, this.WELCH_SCALE_SERVICE,
                this.WELCH_BP_SERVICE, this.AD_SERVICE, this.ADSCALE_SERVICE, this.NIPROBGM_CONTROL_SERVICE,
                this.NIPROBGM_SERVICE, this.FORACARE_IR20_SERVICE, this.TD8255_DISCOVERY_SERVICE, this.AND_SCALE_356_SERVICE];

            // *^1 - Do not raise these logs above trace level.
            // They generate far too much noise in log files,
            // and prevent more useful traces from being captured.

            this.raiseScanningFlag(scanDurationSeconds * 1000);
            this.logger.phic.trace('BLE Scan Started'); // *^1
            this.ble.scan(bleServices, scanDurationSeconds).subscribe(
                {
                    next:
                        (device) => {
                            if ('scanEndSuccess' === device.scanEnd) {
                                this.logger.phic.trace('BLE Scan Stopped'); // *^1
                                resolve();
                            } else {
                                this.onBLEDeviceDiscovered(device);
                            }
                        },
                    error: (error) => {
                        let scanErrorMsg = 'Tried to start scan while already running.';
                        if (scanErrorMsg === error.scanErrorMsg) {
                            this.logger.phic.error('Error in SCAN BLE for IMEI = ', + this.deviceIMEI + error.scanErrorMsg);
                        } else {
                            this.logger.phic.error('Error in SCAN BLE for IMEI = ', + this.deviceIMEI + error);
                            // we want to resolve and start classic scanning in all events apart from when BLE scan is already in progress
                            resolve();
                        }
                    }
                }
            );
            this.getAllPairedValues();
        });
    }

    /**
     * This method uses startDiscovery method (android) to discover all the unpaired devices.
     * We get BLE as well as Classic devices in the discovery. We can identify them based on device type.
     */
    public startDeviceDiscovery(): void {
        const scanDurationSeconds = 12;

        if (this.scanning) {
            this.logger.warn(`startDeviceDiscovery() scan active! aborting call...`);
            return; // dont start another discovery if we are already having an active discovery
        }

        this.raiseScanningFlag(scanDurationSeconds * 1000);

        this.logger.phic.debug('startDeviceDiscovery');
        this.bluetoothSerial.discoverUnpaired().then(
            (devices) => {
                this.logger.phic.trace('Discovered unpaired devices ' + JSON.stringify(devices));
            },
            (error) => {
                this.logger.phic.error('Error discovering unpaired devices ' + error);
            }
        );

        this.bluetoothSerial.setDeviceDiscoveredListener().subscribe(
            {
                next: (device) => {
                    this.logger.phic.trace('Discovered devices ' + JSON.stringify(device));
                    if (this.isDeviceSupported(device)){
                        switch (device?.type) {
                            case BT_DEVICE_TYPE.CLASSIC:
                                this.logger.phic.debug('Classic device found' + device?.name);
                                // if this is already not present in any of the lists then push the device
                                if (!this.checkIfPairedDeviceExists(this.availableClassicDevices, device) &&
                                    !this.checkIfPairedDeviceExists(this.pairedDevices, device)) {
                                    this.availableClassicDevices.push(device);
                                    this.devicesUpdated.next();
                                } else {
                                    this.logger.phic.trace('Not adding device to available devices as it already exists');
                                }
                                break;
                            case BT_DEVICE_TYPE.BLE:
                                this.onBLEDeviceDiscovered(device);
                                break;
                        }
                    } else {
                        this.logger.phic.debug('Found unsupported device ' + device?.name);
                    }
                },
                error: (error) => {
                    this.logger.phic.error('Error in discovery listener for IMEI = ', + this.deviceIMEI + error);
                }
            }
        );
        this.getAllPairedValues();
    }

    public cancelDeviceDiscovery(): void {
        this.logger.phic.debug('Cancelling device discovery');
        this.resetScanningState();
        bluetoothSerial.cancelDiscovery();
    }

    private async addPairedDevice(pairedDevices: any[], device: any): Promise<void> {
        if (device.name.includes('BP100')) {
            let welchBpPassword = await this.storage.get(WELCH_BP_SCALE_PAIRING_PASS_KEY);
            if (!welchBpPassword) {
                this.logger.phic.debug('Welch Allyn BP monitor password is not available');
                return;
            }
        }
        // Check whether the Welch Allyn WS password is available used for communication
        if (device.name.includes('SC100')) {
            let welchWsPassword = await this.storage.get(WELCH_WEIGHT_SCALE_PAIRING_PASS_KEY);
            if (!welchWsPassword) {
                this.logger.phic.debug('Welch Allyn WS password is not available');
                return;
            }
        }
        if (!this.checkIfPairedDeviceExists(pairedDevices, device)) {
            pairedDevices.push(device);
            this.pairedDevices.push(device);
            // as now the device has been added to paired device list, removing it from the other lists
            this.removePeripheralsWithId(this.availableBLEDevices, device.id);
            this.removePeripheralsWithId(this.availableClassicDevices, device.id);
            this.devicesUpdated.next();
        }
    }

    /**
     * This method removes the element in the supplied array. This is mainly used in our code to remove the items
     * from one list when it has been added to other list (for example - from ready to pair list to paired list)
     */
    public removePeripheralsWithId(peripherals: any[], id: string): void {
        if (peripherals && peripherals.length) {
            const peripheralToRemoveIndex = peripherals.findIndex((item): boolean => {
                return item.id === id;
            });

            if (peripheralToRemoveIndex !== -1){
                let splicedItem = peripherals.splice(peripheralToRemoveIndex, 1);
                this.logger.phic.debug('Removed peripheral ' + JSON.stringify(splicedItem));
            }
        }
    }

    /**
     * Auto connect feature might not be required for certian devices in order to
     * avoid multiple callbacks. The devices which do not require these calls
     * will need to be added here in the auto connect not needed flow
     * @param peripheral
     * @param peripheralType
     * @returns
     */
    public isAutoConnectRequired(peripheral: any, peripheralType: string): boolean {
        // welch devices did not require auto connect and multiple callbacks caused issues
        if ((peripheralType === 'bloodpressure' && peripheral.name.includes('BP100')) ||
        (peripheralType === 'weight' && peripheral.name.includes('SC100')) || (peripheralType === 'temperature' && peripheral.name.includes('IR20'))) {
            this.logger.phic.info('Autoconnect Off ' );
            return false;
        } else {
            this.logger.phic.info('Autoconnect On');
            return true;
        }
    }

    /**
     * Connects to device without turning on the autoconnect mode
     * Also, if the device is already connected, it does not attempt to connect it again
     * @param device
     * @param service
     * @param characteristic
     * @param peripheralType
     */
    public connectDeviceWithAutoConnectOff(device: any, service: string, characteristic: string, peripheralType: string, isPairingFlow?:boolean): Promise<any> {
        this.logger.phic.debug('Connect device with auto connect off' + device.id);
        return new Promise((resolve, reject) => {
            this.ble.isConnected(device.id).then(
                () => {
                    this.logger.phic.info('Was already connected to device' + device.id);
                    resolve('connected');
                },
                () => {
                    if (this.isHRSTablet) {
                        this.ble.stopScan()
                            .then(() => {
                                this.resetScanningState();
                            })
                            .catch((err) => {
                                this.logger.warn(`connectDeviceWithAutoConnectOff() -> ble.stopScan() ERROR: ${err}`, err);
                                this.resetScanningState();
                            });
                    }
                    this.ble.connect(device.id).subscribe(
                        {
                            next: (peripheralData) => {
                                this.logger.phic.debug('Peripheral data ' + JSON.stringify(peripheralData));
                                // Welch Allyn device on android phone restart sometime are not providing name and just
                                // provide the mac address. This is problematic as our further code relies on the
                                // name of the connected device.
                                if (device.id === peripheralData.id && !peripheralData.name) {
                                    peripheralData = device;
                                }
                                this.onConnected(peripheralData, service, characteristic, peripheralType, isPairingFlow);
                                this.setStorageValue(peripheralData, peripheralType);
                                this.logger.phic.info('Connected to device', JSON.stringify(peripheralData) + 'IMEI = ' + this.deviceIMEI);
                                resolve('connected');
                            },
                            error: () => {
                                this.logger.phic.debug('Disconnected from device for IMEI = ' + this.deviceIMEI + ' Peripheral Data' + JSON.stringify(device));
                                reject(new Error('not connected'));
                                this.disconnectedFromPeripheral(device, peripheralType, isPairingFlow);
                            }
                        }
                    );
                });
        });
    }

    /**
     * To utilise the disconnect event per peripheral. Currently this is used for logging firebase events
     * @param device
     * @param peripheralType
     */
    public disconnectedFromPeripheral(device: any, peripheralType: string, isPairingFlow = false) : void {
        this.logger.phic.debug('disconnected from device ' + peripheralType + ' device ' + device.name + ' isPairingFlow ' + isPairingFlow);
        switch (peripheralType) {
            case 'bloodpressure': this.bpDeviceService.onDeviceDisconnected(device, isPairingFlow);
                break;
            case 'glucose': this.glucoseDeviceService.onDeviceDisconnected(device, isPairingFlow);
                break;
            case 'pulseox': this.pulseOxDeviceService.onDeviceDisconnected(device, isPairingFlow);
                break;
            case 'weight': this.weightDeviceService.onDeviceDisconnected(device, isPairingFlow);
                break;
            case 'temperature': this.tempDeviceService.onDeviceDisconnected(device, isPairingFlow);
                break;
        }
    }

    public async pairToPeripheral(device: any, service: string, characteristic: string, peripheralType: string, isPairingFlow?: boolean): Promise<any> {
        const pairCallback = () => {
            this.availableBLEDevices = this.availableBLEDevices.filter((available) => available.id !== device.id);
            this.devicesUpdated.next();
        };
        if (this.isAutoConnectRequired(device, peripheralType)) {
            this.logger.phic.debug('pairToPeripheral autoconnect to ' + device.id);
            this.ble.autoConnect(device.id,
                async (connectCallback) => {
                    pairCallback();
                    this.onConnected(device, service, characteristic, peripheralType, isPairingFlow);
                    await this.setStorageValue(device, peripheralType);
                    this.logger.phic.debug('Connecting bluetooth autoconnect', connectCallback + ' IMEI = ' + this.deviceIMEI);
                }, (disconnectCallback) => {
                    this.logger.phic.debug('Disconnecting bluetooth autoconnect', disconnectCallback + ' IMEI = ' + this.deviceIMEI);
                    if (this.isHRSTablet){ // for auto connect on (iOS) multiple disconnect callbacks are recieved leading to false event logging
                        this.disconnectedFromPeripheral(device, peripheralType, isPairingFlow);
                    }
                });
        } else {
            this.logger.phic.debug('pairToPeripheral autoconnect off ' + device.id);
            try {
                await this.connectDeviceWithAutoConnectOff(device, service, characteristic, peripheralType, isPairingFlow);
                pairCallback(); // only if pairing is success, then we update the list
            } catch (Error) {
                this.logger.phic.debug('got error while connecting device with auto off ' + Error);
            }
        }
    }

    public async pairToClassicPeripheral(device: any, peripheralType: string, isPairingFlow?: boolean): Promise<any> {
        const pairCallback = () => {
            this.availableClassicDevices = this.availableClassicDevices.filter((available) => available.id !== device.id);
            this.devicesUpdated.next();
        };
        if (device && device.address != null) {
            this.logger.phic.debug('Will connect to:: ' + device.name + ' type ' + peripheralType + ' IMEI = ' + this.deviceIMEI);
            // while polling of devices is taking place, the earlier polled-paired device tries to connect
            // along with the newly scanned device which is ready to pair
            // thus as these calls are aschronously going on, sometimes peripheral type is wrongly determined thus neeed to recheck it
            return new Promise<void>((resolve, reject) => {
                this.bluetoothSerial.connect(device.address).subscribe(
                    (connectedDevice) => {
                        this.logger.phic.debug('Connnect classic succcess callback');
                        peripheralType = this.getPeripheralType(connectedDevice.name);
                        pairCallback();
                        this.setStorageValue(connectedDevice, peripheralType);
                        this.logger.phic.info('Connected to classic device from Plugin ', JSON.stringify(connectedDevice) + ' IMEI = ' + this.deviceIMEI + ' Peripheral type ' + peripheralType);
                        this.onConnected(connectedDevice, null, null, peripheralType, isPairingFlow);
                        this.onMetricMeasurementChange(null, peripheralType, connectedDevice);
                        resolve();
                    }, (disconnectCallback) => {
                        this.logger.phic.error('Disconnecting from classic device', disconnectCallback + ' IMEI = ' + this.deviceIMEI);
                        reject(disconnectCallback);
                        this.disconnectedFromPeripheral(device, peripheralType, isPairingFlow);
                    }
                );
            });
        }
    }

    public async disconnectBluetoothSerial() : Promise<any> {
        this.logger.phic.debug('Disconnect BT serial connection');
        try {
            const response = await this.bluetoothSerial.isConnected();
            if (response) {
                this.bluetoothSerial.disconnect();
            }
        } catch (e){
            this.logger.phic.error('BT Serial devices' + e);
        }
    }

    checkForBluetoothKey(peripheralType: string): string {
        if (peripheralType === 'pulseox') {
            return BLUETOOTH_KEY_PULSEOX;
        } else if (peripheralType === 'glucose') {
            return BLUETOOTH_KEY_GLUCOSE;
        } else if (peripheralType === 'temperature') {
            return BLUETOOTH_KEY_TEMPERATURE;
        } else if (peripheralType === 'bloodpressure') {
            return BLUETOOTH_KEY_BLOODPRESSURE;
        } else if (peripheralType === 'weight') {
            return BLUETOOTH_KEY_WEIGHT;
        }
    }

    public setStorageValue(device: any, peripheralType: string): Promise<any> {
        let deviceKey: string = this.checkForBluetoothKey(peripheralType);
        this.logger.phic.debug('setStorageValue', deviceKey);
        return this.getStoragePairedValue(peripheralType).then((res) => {
            if (res) {
                // clear the duplicate entries from res (to fix this for older versions)
                this.logger.phic.debug('Stored paired devices: ' + JSON.stringify(res));
                res = res.filter((device, index) => index === res.findIndex((other) => device.id === other.id));
                this.logger.phic.debug('Filtered devices: ' + JSON.stringify(res));
                let devices = res.map(({id}) => id);
                if (!devices.includes(device.id)) {
                    res.push(device);
                    return this.storage.set(deviceKey, res);
                } else {
                    return this.storage.set(deviceKey, res);
                }
            } else {
                return this.storage.set(deviceKey, [device]);
            }
        });
    }

    getStoragePairedValue(peripheralType: string): Promise<any> {
        let deviceKey: string = this.checkForBluetoothKey(peripheralType);
        return this.storage.get(deviceKey).then((res) => {
            return res;
        });
    }

    /**
     * Retrieve all connected device data from local storage.
     * @return a Promise that passes an array of all active connected devices.
     */
    async getAllPairedValues(): Promise<any[]> {
        // *^1 - Do not raise these logs above trace level.
        // They generate far too much noise in log files,
        // and prevent more useful traces from being captured.

        this.logger.phic.trace('Retrieve all connected devices from local storage'); // *^1
        let devices = [];
        const peripherals = [
            'pulseox',
            'glucose',
            'temperature',
            'bloodpressure',
            'weight'
        ];
        return Promise.all(peripherals.map((peripheral) => {
            return this.getStoragePairedValue(peripheral).then((data) => {
                if (data && data.length > 0) {
                    devices = devices.concat(data);
                    this.sortAllDevicesToPairedDeviceArray(peripheral, data);
                }
            });
        })).then(() => {
            this.allPairedDevices = devices;
            return devices;
        });
    }

    /**
     * Returns paired devices for the provided peripheral type.
     */
    public getPairedPeripherals(peripheralType: string): any[] {
        switch (peripheralType) {
            case 'bloodpressure':
                return this.pairedBPMonitorDevices;
            case 'glucose':
                return this.pairedGlucometerDevices;
            case 'pulseox':
                return this.pairedOximeterDevices;
            case 'weight':
                return this.pairedScaleDevices;
            case 'temperature':
                return this.pairedThermometerDevices;
        }
    }

    /**
     * Add provided devices to their respective peripheral type arrays.
     */
    private sortAllDevicesToPairedDeviceArray(deviceType, devices): void {
        if (devices.length > 0) {
            devices.forEach((device) => {
                this.addToPairedDeviceArray(deviceType, device);
            });
            this.devicesUpdated.next();
        }
    }

    removeStorageValue(service: string, peripheralType: string): Promise<any> {
        let deviceKey: string = this.checkForBluetoothKey(peripheralType);
        return this.storage.get(deviceKey).then((value) => {
            if (value) {
                const updatedDeviceList = value.filter((device) => device.id !== service);
                return this.storage.set(deviceKey, updatedDeviceList);
            }
        });
    }

    onConnected(peripheral: any, service: string, characteristic: string, peripheralType: string, isPairingFlow:boolean = false): void {
        this.logger.log('onConnected : isPairingFlow? ' + isPairingFlow);
        // // Only adding the device with the same ID once
        // // Can only connect to the same device once by design
        this.peripheral = peripheral;
        if (peripheral.name) {
            peripheralType = this.getPeripheralType(peripheral.name);
        } else {
            this.logger.phic.debug('onConnected - Did not obtain peripheral name');
        }
        this.addToPairedDeviceArray(peripheralType, peripheral);

        this.logger.phic.debug('Connected to bluetooth ' + (peripheral.name || peripheral.id) + ' IMEI = ' + this.deviceIMEI);

        if (peripheralType === 'bloodpressure') { // configure device and set date and time on bp monitor as early as possible when device is connected
            this.bpDeviceService.configureDevice(peripheral);
        } else if (peripheralType === 'weight') {// configure device and set date and time on scale as early as possible when device is connected
            this.weightDeviceService.configureDevice(peripheral);
        } else if (peripheralType === 'pulseox') {// configure device and set date and time on pulse oximeter as early as possible when device is connected
            this.pulseOxDeviceService.configureDevice(peripheral);
        }

        if (
            !(peripheral.name.includes('Nonin_Medical') ||
                peripheral.name.includes('Taidoc-Device') ||
                peripheral.name.includes('UC-355') ||
                peripheral.name.includes('UC-351') ||
                peripheral.name.includes('UA-767') ||
                peripheral.name.includes('BP100') ||
                peripheral.name.includes('SC100') ||
                peripheral.name.includes('TEST-N-GO') ||
                peripheral.name.includes('UA-651') || // for UA-651 BLE device, we want to start notification once we have written date/time and not parallel to it
                peripheral.name.includes('Nonin3230') // for Nonin 3230, we want to set spot check first and handle other use cases accordingly
            )) {
            this.ble.startNotification(peripheral.id, service, characteristic).subscribe(
                {
                    next: (data) => {
                        // instead of passing data, as per the changes in BLE central plugin, we now pass data[0]
                        // change id - https://github.com/danielsogl/awesome-cordova-plugins/pull/3509
                        this.onMetricMeasurementChange(data[0], peripheralType, peripheral);
                    },
                    error: (error) => {
                        this.logger.phic.error('onConnected: Failed to notify characteristic from ' + peripheral.name, error + ' IMEI = ' + this.deviceIMEI);
                        if (!isPairingFlow){
                            let peripheralDisplayName = this.fbAnalytics.getDisplayName(peripheral, peripheralType);
                            this.fbAnalytics.logEvent(HRSFirebaseEvents.BT_NOTIFICATION_ERROR,
                                {[HRSFirebaseParams.DEVICE_NAME]: peripheralDisplayName, [HRSFirebaseParams.PERIPHERAL_TYPE]: peripheralType,
                                    [HRSFirebaseParams.RSSI]: peripheral.rssi, [HRSFirebaseParams.REASON]: HRSFirebaseErrorReason.NOTIFICATION_ERROR,
                                    [HRSFirebaseParams.META_DATA]: error + ''});
                        }
                    }
                }
            );
        }

        if (peripheral.name.includes('Nipro')) {
            this.subscribeForGlucometerData(peripheral, 0, 1);
        } else if (peripheral.name.includes('TRUEAIR')) {
            this.subscribeForGlucometerData(peripheral, 10, 2);
        } else if (peripheral.name.includes('IR20') || peripheral.name.includes('TD1107')) {
            this.tempDeviceService.onACLConnect(peripheral);
        } else if (peripheralType === 'weight' &&
            (peripheral.name.includes('TNG SCALE')) ) {
            this.weightDeviceService.onACLConnect(peripheral);
        } else if (peripheral.name.includes('BP100') && peripheralType === 'bloodpressure') {
            this.bpDeviceService.onConnected(peripheral);
        } else if (peripheral.name.includes('TAIDOC TD8255') || peripheral.name.includes('TNG SPO2')) {
            this.pulseOxDeviceService.onACLConnect(peripheral);
        } else if (peripheralType === 'glucose' && peripheral.name === TNG_BLE_GLUCOMETER) {
            this.glucoseDeviceService.onACLConnect(peripheral);
        } else if (peripheral.name.includes('TEST-N-GO')) {
            let dataCount = this.glucoseDeviceService.addCheckSum([81, 0x2B, 0, 0, 0, 0, 163]);
            this.glucoseDeviceService.writeForacareDataPacket(dataCount);
        } else if (peripheral.name.includes('UC-355') || peripheral.name.includes('UC-351') || peripheral.name.includes('SC100') || peripheral.name.includes('UC-356')) {
            this.weightDeviceService.onConnected(peripheral);
        } else if (peripheral.name.includes('UA-767')) {
            this.bpDeviceService.onConnected(peripheral);
        }
        this.getAllPairedValues();
    }

    /**
     * This method peforms the various steps needed for subscribing for data from TrueAir and Nipro Glucometers.
     * Based on the parameters supplied related to timeoutStartVal and multipleFactor, time delay between different operations is maintained
     */
    private subscribeForGlucometerData(peripheral: any, timeoutStartVal: number, multipleFactor: number): void {
        // Unfortunately have to force this to be synchronous or the device won't work
        let timeoutCount = timeoutStartVal * multipleFactor;
        setTimeout(() => {
            this.glucoseDeviceService.onNiproDescriptorWrite(peripheral);
        }, timeoutCount);

        timeoutCount += 10 * multipleFactor;
        setTimeout(() => {
            this.glucoseDeviceService.onNiproEnableNotification(peripheral);
        }, timeoutCount);

        timeoutCount += 10 * multipleFactor;
        setTimeout(() => {
            this.glucoseDeviceService.onNiproEnableIndication(peripheral);
        }, timeoutCount);
    }

    public async checkCPUAndLog(): Promise<any> {
        this.logger.phic.debug('Check CPU and Log');
        cordova.plugins['CPUInfo'].getRamAvailableSize().then((ram) => {
            cordova.plugins['CPUInfo'].getCurCpuFreq().then((cpu) => {
                this.logger.phic.info(`PCMT Current CPU for IMEI: ${this.deviceIMEI} CPU: ${JSON.stringify(cpu)}`, `PCMT Available RAM for IMEI: ${JSON.stringify(ram)}`);
            });
        });
    }

    /**
     * Foracare (TNG) is specific in that it needs to be written to in order to continue notifications
     * The addCheckSum ensures that the buffer array includes the correct bytes. We stop writing on clear.
     * This may be used for the other Foracare devices, but currently unsure. I will adjust and cleanup functions
     * if that is the case.
     */
    onMetricMeasurementChange(buffer: ArrayBuffer, peripheralType: string, peripheral: any): void {
        let peripheralName = peripheral.name;
        if (peripheralName.includes('Nonin3230') ||
            peripheralName.includes('TNG SPO2') ||
            peripheralName.includes('TAIDOC TD8255') ||
            peripheralName.includes('Nonin_Medical')
        ) {
            this.pulseOxDeviceService.onPulseOxMetricMeasurementChange(buffer, peripheralType, peripheral);
        } else if (
            peripheralName.includes('TNG SCALE') ||
            peripheralName.includes('UC-352') ||
            peripheralName.includes('UC-356')

        ) {
            // Changed order of TNG scale to get it compared before TNG glucometer as both have TNG common in peripheral name
            this.weightDeviceService.onWeightMetricMeasurementChange(buffer, peripheralType, peripheral);
        } else if (
            peripheralName.includes('Nipro') ||
            peripheralName.includes('TRUEAIR') ||
            peripheralName === TNG_BLE_GLUCOMETER ||
            peripheralName.includes('TEST-N-GO')
        ) {
            this.glucoseDeviceService.onGlucoseMetricMeasurementChange(buffer, peripheralType, peripheral);
        } else if (
            peripheralName.includes('UA-651')
        ) {
            this.bpDeviceService.onBPMetricMeasurementChange(buffer, peripheralType, peripheral);
        } else if (
            peripheralName.includes('IR20') ||
            peripheralName.includes('TD1107') ||
            peripheralName.includes('Taidoc-Device')
        ) {
            this.tempDeviceService.onTemperatureMetricMeasurementChange(buffer, peripheralType, peripheral);
        }
    }

    // The user should see a popup in their bluetooth section asking if Patient Connect can connect to bluetooth
    onBLEDeviceDiscovered(device: any): void {
        this.logger.phic.debug('Discovered BLE ' + JSON.stringify(device, null, 2));
        this.ngZone.run(() => {
            // if the device is a supported device, and
            // if the device does not exists in any of the list then add it
            if (this.isDeviceSupported(device) &&
                    !this.checkIfPairedDeviceExists(this.availableBLEDevices, device) &&
                    !this.checkIfPairedDeviceExists(this.pairedDevices, device)) {
                this.availableBLEDevices.push(device);
                this.devicesUpdated.next();
            }

            const pairBLE = async (peripheral) => {
                if (peripheral.name.includes('Nonin3230')) {
                    await this.pairToPeripheral(peripheral, this.NONIN_3230_SERVICE, this.NONIN_3230_CHARACTERISTIC, 'pulseox');
                } else if (peripheral.name.includes('TNG SPO2')) {
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'pulseox');
                } else if (peripheral.name.includes('TAIDOC TD8255')) {
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'pulseox');
                } else if (peripheral.name.includes('Nipro') || peripheral.name.includes('TRUEAIR')) {
                    await this.pairToPeripheral(peripheral, this.NIPROBGM_SERVICE, this.NIPROBGM_CHARACTERISTIC, 'glucose');
                } else if (peripheral.name.includes('UA-651')) {
                    await this.pairToPeripheral(peripheral, this.AD_SERVICE, this.AD_CHARACTERISTIC, 'bloodpressure');
                } else if (peripheral.name.includes('BP100')) {
                    await this.pairToPeripheral(peripheral, this.WELCH_BP_SERVICE, this.WELCH_BP_MEASUREMENT_CHAR, 'bloodpressure');
                } else if (peripheral.name.includes('IR20')) {
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'temperature');
                } else if (peripheral.name.includes('TD1107')) {
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'temperature');
                } else if (peripheral.name.includes('TNG SCALE')) {
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'weight');
                } else if (peripheral.name === TNG_BLE_GLUCOMETER) {
                    // This is the BLE TNG glucometer replacing the TEST-N-GO Classic Device
                    await this.pairToPeripheral(peripheral, this.FORACARE_SERVICE, this.FORACARE_CHARACTERISTIC, 'glucose');
                } else if (peripheral.name.includes('SC100')) {
                    await this.pairToPeripheral(peripheral, this.WELCH_SCALE_SERVICE, this.WELCH_SCALE_CHARACTERISTIC, 'weight');
                } else if (peripheral.name.includes('UC-352')) {
                    await this.pairToPeripheral(peripheral, this.ADSCALE_SERVICE, this.ADSCALE_CHARACTERISTIC, 'weight');
                } else if (peripheral.name.includes('UC-356')) {
                    await this.pairToPeripheral(peripheral, this.AND_SCALE_356_SERVICE, this.AND_SCALE_356_CHARDER_USER_DATA_CHAR, 'weight');
                }
            };
            const metricPageOpen = this.modalService.getModalStatus('GenericMetricPage');
            let supportedPeripheralTypeFound = this.getPeripheralType(device.name); // scanned device is a supported one
            this.logger.phic.debug('Supported peripheral type found: ' + supportedPeripheralTypeFound);
            if (!metricPageOpen && device && supportedPeripheralTypeFound) {
                this.allPairedDevices.forEach((pairedDevice) => {
                    if (pairedDevice.id === device.id) {
                        this.logger.phic.info('Scanning result found paired device ' + pairedDevice.name + ' id ' + pairedDevice.id + ' to connect to: ' + device.id + ' name ' + device.name);
                        pairBLE(device);
                    } else {
                        this.logger.phic.info('Matching device not found: Paired id ' + pairedDevice.id + ' name ' + pairedDevice.name + ' current device id ' + device.id + ' name ' + device.name);
                    }
                });
            }
        });
    }

    /**
     * This method checks whether the supplied device is supported by the application or not.
     * This will filter out only those devices which are supported and also belong to the
     * selected peripheral type (from device menu peripheral selection)
     * @param device
     * @returns boolean
     */
    public isDeviceSupported(device: any): boolean {
        if (device.name) {
            let deviceType = this.getPeripheralType(device.name);

            // This is a supported device which belongs to the selected peripheral or
            // for tablet admin case when peripheral type is not initialized, still the device is a supported one
            if ((deviceType !== undefined) && (this.selectedPeripheralType === undefined || deviceType === this.selectedPeripheralType )) {
                return true;
            }
        }
        return false;
    }

    // If the user denies access or keeps bluetooth off, then they will see an error on the scan
    public async bluetoothScanError(error: string): Promise<OverlayRef> {
        this.logger.phic.error('BT Scan Error ' + error);
        let message = this.translate.instant('BLUETOOTH.SCAN_ERROR_TITLE');
        return await this.overlay.openToast({
            message: message,
            variant: 'error',
            duration: 5000, // 5 seconds is the standard toast duration
            qa: 'device_scan_toast'
        });
    }

    checkIfPairedDeviceExists(array: any, newObject: any): boolean {
        if (array.findIndex((obj) => obj.id === newObject.id) > -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get the peripheral type by matching the peripheral name to our master list of devices.
     */
    public getPeripheralType(peripheralName: string): string {
        for (let deviceType in templateDevices) {
            if (deviceType) {
                const devices = templateDevices[deviceType];
                for (let i = 0; i < devices.length; i++) {
                    if (peripheralName.includes(devices[i].name)) {
                        if (peripheralName.includes('TNG')){
                            return this.getDeviceTypeForTNGDevices(peripheralName);
                        }
                        return deviceType;
                    }
                }
            }
        }
    }

    /**
     * Get the device type for TNG devices
     * @param peripheralName
     * @returns deviceType string
     */
    private getDeviceTypeForTNGDevices(peripheralName: string): string {
        if (peripheralName.includes('TNG SPO2')){
            return 'pulseox';
        } else if (peripheralName.includes('TNG SCALE')){
            return 'weight';
        } else if (peripheralName === TNG_BLE_GLUCOMETER){
            return 'glucose';
        }
    }

    public async subscribePollingForBluetoothDevices(): Promise<any> {
        this.logger.phic.debug('subscribePollingForBluetoothDevices for IMEI = ' + this.deviceIMEI);

        const isPollingSubscribed = !this.isBTPollingUnsubscribed();
        this.logger.phic.debug('is Polling Subscribed? ' + isPollingSubscribed);

        if (isPollingSubscribed) {
            this.logger.phic.debug('Polling Already Subscribed and is running for IMEI' + this.deviceIMEI);
            return;
        }

        const pair = async (peripheral) => {
            if (peripheral.name.includes('Nonin_Medical')) {
                await this.pairToClassicPeripheral(peripheral, 'pulseox');
            } else if (peripheral.name.includes('Taidoc-Device')) {
                await this.pairToClassicPeripheral(peripheral, 'temperature');
            } else if (peripheral.name.includes('TEST-N-GO')) {
                await this.pairToClassicPeripheral(peripheral, 'glucose');
            } else if (peripheral.name.includes('UC-351')) {
                await this.pairToClassicPeripheral(peripheral, 'weight');
            } else if (peripheral.name.includes('UC-355')) {
                await this.pairToClassicPeripheral(peripheral, 'weight');
            } else if (peripheral.name.includes('UA-767')) {
                await this.pairToClassicPeripheral(peripheral, 'bloodpressure');
            }
        };

        const pollingInterval = 15000;
        this.logger.phic.debug('Subscribe Polling and start timer; pollingInterval = ' + pollingInterval);

        this.pollingSubscription = timer(0, pollingInterval).subscribe(() => {
            const canStartScan = this.hasAndroidPermission || this.platform.is('ios');

            if (!canStartScan) {
                return;
            }

            // Without this catch block, the promise will be forced to
            // propagate the error as a vanilla javascript thrown exception;
            // which, in turn, will cause this timer subscription to silently fail.
            this.scanAvailableBLEDevices()
                ?.catch((err) => {
                    this.logger.error(`BLE polling caught error attemping to scan for devices -> ${err}`, err);
                });

            const metricPageOpen = this.modalService.getModalStatus('GenericMetricPage');

            if (metricPageOpen) {
                return;
            }

            try {
                this.allPairedDevices.forEach((device) => {
                    if (BluetoothUtils.isClassicDevice(device)) {
                        pair(device);
                    }
                });
            } catch (e) {
                this.logger.error(`BLE polling caught error iterating over paired devices -> ${e}`, e);
            }
        });
    }

    public isBTPollingUnsubscribed(): boolean {
        return !!(this.pollingSubscription === null ||
            this.pollingSubscription === undefined ||
            this.pollingSubscription?.closed);
    }

    public unsubscribePollingForBluetoothDevices(): void {
        this.logger.phic.debug('unsubscribePollingForBluetoothDevices');
        // stop existing scan before unsubscribing polling
        if (this.scanning) {
            this.ble.stopScan()
                .then(() => {
                    this.resetScanningState();
                })
                .catch((err) => {
                    this.resetScanningState();
                });
        }

        if (this.pollingSubscription) {
            try {
                // this has potential to blow up if the subscription exists but
                // was already closed due to an exception being thrown in the
                // observable chain.
                this.pollingSubscription.unsubscribe();
            } catch {}
            this.pollingSubscription = null;
        }
    }

    public getDeviceText(deviceName: any, peripheralType: string): OximeterDeviceText | BPDeviceText| ThermometerDeviceText | ScaleDeviceText | GlucometerDeviceText {
        let deviceText: OximeterDeviceText | BPDeviceText| ThermometerDeviceText | ScaleDeviceText | GlucometerDeviceText = '';
        switch (peripheralType) {
            case 'pulseox':
                if (deviceName.includes('Nonin3230')) {
                    deviceText = 'NONIN_3230';
                } else if (deviceName.includes('TNG SPO2')) {
                    deviceText = 'TNG_SPO2';
                } else if (deviceName.includes('TAIDOC TD8255')) {
                    deviceText = 'TAIDOC_TD8255';
                } else if (deviceName.includes('Nonin_Medical')) {
                    deviceText = 'NONIN_9560';
                }
                break;
            case 'temperature':
                if (deviceName.includes('IR20') ||
                    deviceName.includes('Taidoc-Device')
                ) {
                    deviceText = 'FORA';
                } else if (deviceName.includes('TD1107')) {
                    deviceText = 'TD1107';
                }
                break;
            case 'weight':
                if (deviceName.includes('TNG SCALE')) {
                    deviceText = 'TNG_550';
                } else if (
                    deviceName.includes('UC-351')
                ) {
                    deviceText = 'UC_351';
                } else if (
                    deviceName.includes('UC-352')
                ) {
                    deviceText = 'UC_352';
                } else if (
                    deviceName.includes('UC-355')
                ) {
                    deviceText = 'UC_355';
                } else if (
                    deviceName.includes('UC-356')
                ) {
                    deviceText = 'UC_356';
                } else if (deviceName.includes('SC100')) {
                    deviceText = 'WA_SCALE_100';
                }
                break;
            case 'bloodpressure':
                if (deviceName.includes('UA-651')) {
                    deviceText = 'UA_651';
                } else if (deviceName.includes('UA-767')) {
                    deviceText = 'UA_767';
                } else if (deviceName.includes('BP100')) {
                    deviceText = 'WA_BP100';
                }
                break;
            case 'glucose':
                if (deviceName.includes('Nipro')) {
                    deviceText = 'NIPRO';
                } else if (deviceName.includes('TRUEAIR')) {
                    deviceText = 'TRUEAIR';
                } else if (deviceName.includes('TEST-N-GO')) {
                    deviceText = 'TEST_N_GO';
                } else if (deviceName === TNG_BLE_GLUCOMETER) {
                    deviceText = 'TNG';
                }
        }
        return deviceText;
    }

    public getDeviceImageUrl(peripheralType: string, deviceName: string): string | GlucometerImageUrl | BPImageUrl | ScaleImageUrl | ThermometerImageUrl | OximeterImageUrl {
        let image: string | GlucometerImageUrl | BPImageUrl | ScaleImageUrl | ThermometerImageUrl | OximeterImageUrl = '';
        switch (peripheralType) {
            case 'bloodpressure':
                if (deviceName.includes('BP100')) {
                    image = this.bpWelchAllynImageUrl;
                } else if (deviceName.includes('UA-767')) {
                    image = this.bpUA767ImageUrl;
                } else {
                    image = this.bpUA651ImageUrl;
                }
                break;
            case 'glucose':
                if (deviceName.includes('Nipro') || deviceName.includes('TRUEAIR')) {
                    image = this.niproGlucometerImageUrl;
                } else if (deviceName.includes('TEST-N-GO') || deviceName === TNG_BLE_GLUCOMETER) {
                    image = this.testNGoGlucometerImageUrl;
                }
                break;
            case 'pulseox':
                if (deviceName.includes('Nonin3230')) {
                    image = this.noninPulseOxImageUrl;
                } else if (deviceName.includes('TNG SPO2')) {
                    image = this.foracarePulseOxImageUrl;
                } else if (deviceName.includes('TAIDOC TD8255')) {
                    image = this.taidocPulseOxImageUrl;
                } else if (deviceName.includes('Nonin_Medical')) {
                    image = this.noninMedicalPulseOxImageUrl;
                }
                break;
            case 'weight':
                if (deviceName.includes('TNG SCALE')) {
                    image = this.tngScaleImageUrl;
                } else if (deviceName.includes('UC-351')) {
                    image = this.uc351ScaleImageUrl;
                } else if (deviceName.includes('UC-352')) {
                    image = this.uc352ScaleImageUrl;
                } else if (deviceName.includes('UC-355')) {
                    image = this.uc355ScaleImageUrl;
                } else if (deviceName.includes('SC100')){
                    image = this.welchAllynScaleImageUrl;
                } else if (deviceName.includes('UC-356')) {
                    image = this.uc356ScaleImageUrl;
                }
                break;
            case 'temperature':
                if (deviceName.includes('TD1107')) {
                    image = this.td1107ImageUrl;
                } else if (deviceName.includes('IR20') || deviceName.includes('Taidoc-Device')) {
                    image = this.foracareImageUrl;
                }
                break;
        }

        return image;
    }

    public getThumbnail(deviceName: string): string {
        if (deviceName.includes('BP100')) {
            return 'assets/img/bp_welchallyn_1X1.jpg';
        } else if (deviceName.includes('UA-767')) {
            return 'assets/img/bp_a&d_ua767_1X1.jpg';
        } else if (deviceName.includes('Nipro') || deviceName.includes('TRUEAIR')) {
            return 'assets/img/glucometer_trumix_air_1X1.jpg';
        } else if (deviceName.includes('TEST-N-GO') || deviceName === TNG_BLE_GLUCOMETER) {
            return 'assets/img/glucometer_fora_tngble_1X1.jpg';
        } else if (deviceName.includes('Nonin3230')) {
            return 'assets/img/ox_nonin3230_1X1.jpg';
        } else if (deviceName.includes('TNG SPO2')) {
            return 'assets/img/ox_foratngsp02_1X1.jpg';
        } else if (deviceName.includes('TAIDOC TD8255')) {
            return 'assets/img/ox_taidoctd8255_1X1.jpg';
        } else if (deviceName.includes('Nonin_Medical')) {
            return 'assets/img/ox_nonin_onyxII9560_1X1.jpg';
        } else if (deviceName.includes('TNG SCALE')) {
            return 'assets/img/scale_foratng_550white_1X1.jpg';
        } else if (deviceName.includes('UC-351')) {
            return 'assets/img/scale_a&d_uc351pbtci_1X1.jpg';
        } else if (deviceName.includes('UC-352')) {
            return 'assets/img/scale_a&d_uc352ble_1X1.jpg';
        } else if (deviceName.includes('UC-355')) {
            return 'assets/img/scale_a&d_uc355pbtci_1X1.jpg';
        } else if (deviceName.includes('UC-356')) {
            return 'assets/img/scale_a&d_uc356ble_1x1.jpg';
        } else if (deviceName.includes('IR20') || deviceName.includes('TD1107') || deviceName.includes('Taidoc-Device')) {
            return 'assets/img/thermometer_fora_ir20bv4_1X1.jpg';
        } else if (deviceName.includes('SC100')) {
            return 'assets/img/scale_welchallyn_1X1.jpg';
        } else {
            return 'assets/img/bp_a&d_ua651ble_1X1.jpg';
        }
    }
}
