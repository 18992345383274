import {Injectable} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {BatteryStatusResponse} from '@ionic-native/battery-status/ngx';
import {DeviceProfile, DeviceProfilePartial} from './device-profile.interface';
import {GatewayResourceService} from '@hrs/gateway';
import {Geolocation, PositionError} from '@ionic-native/geolocation/ngx';
import {Network} from '@ionic-native/network/ngx';
import {PCKSAPKService} from '../apk/pcks-apk.service';
import {PCKSVersionIntentService} from '../knox-service-intents/pcks-version-intent.service';
import {Subscription, timer} from 'rxjs';
import {TabletDeviceIdService} from '../../services/tablet-device-id/tablet-device-id';
import {Uptime} from '@ionic-native/uptime/ngx';
import {GeolocationOptions, Geoposition} from '@ionic-native/geolocation';
import {take} from 'rxjs/operators';
import {DeviceService} from '../../services/device/device.service';
import {EventService} from '../../services/events/event.service';
import {BatteryStatusService} from '../../services/battery-status/battery-status.service';
import {KioskIntentService} from '../knox-service-intents/kiosk-intent.service';
import {getLogger} from '@hrs/logging';
import {SignalStrengthService} from '../../services/signal-strength/signal-strength.service';

export interface AppNetworkInfo {
    type: string;
    strength: number;
    level: number;
}

/**
 * DeviceProfileService
 *
 * This service is used to collect relevant PCMT device specific information and
 * submit it via Gateway to the NotificationsServer where it is stored in Firebase RTD
 * and is ultimately displayed in Admin FirebaseServer
 *
 * The DeviceProfile should be submitted every hour to ensure a 'connected' state is visible in the admin firebase server portal
 *
 * A little funky but it seems the 'keys' that firebase expects need to be written in dot notation, these are the current possibilities
 * "info.sw.pc": Patient Connect Version
 * "info.sw.pcs": Patient Connect Service Version
 * "info.sw.pcks": Patient Connect Knox Service Version
 * "info.network.type": Network Type
 * "info.swnfo.data.upload": File Upload Size
 * "info.system.devtime": Current Unix timestamp
 * "info.system.uptime": Time that Device is Awake
 * "info.system.tz": Device's Timezone
 * "info.time": Current Unix timestamp
 * "info.network.strength": Network Strength
 * "info.battery.status": Battery Plugged Status
 * "info.battery.level": Battery Level
 * "info.gps.time": Device's GPS Time
 * "info.gps.latitude": Device's Latitude
 * "info.gps.longitude": Device's Longitude
 * "token": Firebase Messaging token
 * "info.bt": List of Connected BT Devices
 */
@Injectable({
    providedIn: 'root',
})
export class DeviceProfileService {
    private readonly logger = getLogger('DeviceProfileService');
    private _profileState: Partial<DeviceProfile> | DeviceProfile = {}; // State of Device Profile that has already been submitted.
    private _profileUpdates: Partial<DeviceProfile> | DeviceProfile = {}; // Updates that have yet to be submitted.
    private batterySubscription: Subscription;
    private pollingSubscription: Subscription;
    private watchingPosition: Subscription;

    constructor(
        private appVersion: AppVersion,
        private batteryStatus: BatteryStatusService,
        private deviceService: DeviceService,
        private eventService: EventService,
        private gateway: GatewayResourceService,
        private geolocation: Geolocation,
        private network: Network,
        private pcksAPK: PCKSAPKService,
        private pcksVersion: PCKSVersionIntentService,
        private tabletDeviceIDService: TabletDeviceIdService,
        private uptime: Uptime,
        private kiosk: KioskIntentService,
        private signalStrength: SignalStrengthService
    ) {}

    public subscribe(): void {
        this.initBatteryListener();
        // Post Device Profile on subscribe and every hour after.
        this.pollingSubscription = timer(0, 3600000).subscribe(() => {
            this.postCompleteDeviceProfile();
        });

        this.eventService.updateDeviceProfile.subscribe((data: DeviceProfilePartial) => {
            this.updateDeviceProfile(data);
        });

        this.eventService.postCompleteDeviceProfileEvent.subscribe(() => {
            this.postCompleteDeviceProfile();
        });

        this.kiosk.isEnabledSubject.subscribe((isEnabled: boolean) => {
            const status = isEnabled ? 'Locked' : 'Unlocked';
            this.updateDeviceProfile({'info.kiosk': status});
        });

        this.pcksVersion.version.subscribe((version: string) => {
            if (version) this.updateDeviceProfile({'info.sw.pcks': version});
        });
    }

    public unsubscribe(): void {
        if (this.batterySubscription) {
            this.batterySubscription.unsubscribe();
            this.batterySubscription = null;
        }
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
            this.pollingSubscription = null;
        }
    }

    public async postCompleteDeviceProfile(): Promise<void> {
        await this.refreshLocalProfile();
        this.submitDeviceProfile();
    }

    private async refreshLocalProfile(): Promise<void> {
        this.getDeviceLocation();
        await this.getPCMTVersion();
        await this.getPCKSVersion();
        await this.getNetworkData();
        await this.getUptime();
        await this.getBTDevices();
        await this.getKioskMode();
        this.getTimezone();
    }

    private initBatteryListener(): void {
        // check to see if status is already stored in the class before waiting for the next onChange from the battery status plugin
        this.addBatteryStatus(this.batteryStatus);
        this.batterySubscription = this.batteryStatus.batteryStatusSubject.subscribe((status: BatteryStatusResponse) => {
            this.addBatteryStatus(status);
        });
    }

    private addBatteryStatus(batteryStatus: BatteryStatusResponse | BatteryStatusService): void {
        if (!batteryStatus.level && batteryStatus.isPlugged === undefined) return;
        const batteryInfo = {
            'info.battery.status': batteryStatus.isPlugged ? 'Plugged In' : 'Unplugged',
            'info.battery.level': batteryStatus.level
        };
        this.updateDeviceProfile(batteryInfo);
    }

    public async getNetworkData(): Promise<AppNetworkInfo> {
        const networkInfo = await this.getNetworkInfo();
        const networkStrength = networkInfo ? networkInfo.strength : undefined;
        const networkLevel = networkInfo ? networkInfo.level : undefined;
        const networkType = this.network.type;
        if (networkStrength) this.updateDeviceProfile({'info.network.strength': networkStrength});
        if (networkType) this.updateDeviceProfile({'info.network.type': networkType});
        return {type: networkType, strength: networkStrength, level: networkLevel};
    }

    public async getNetworkLevel(): Promise<number> {
        const networkInfo = await this.getNetworkInfo();
        // networkInfo example: {dbm: -100, level: 2}
        let networkLevel = networkInfo ? networkInfo.level : undefined;
        return networkLevel;
    }

    private async getNetworkInfo(): Promise<AppNetworkInfo> {
        try {
            // output example: {dbm: -40, level: 3, type: 'wifi'}
            const {dbm, level, type} = await this.signalStrength.getCurrentState();
            return {strength: dbm, level, type};
        } catch (e) {
            this.logger.error(`failed to load cellular state`, e);
            return null;
        }
    }

    private async getPCMTVersion(): Promise<void> {
        const version = await this.appVersion.getVersionNumber();
        if (version) this.updateDeviceProfile({'info.sw.pcmt': version});
    }

    private async getPCKSVersion(): Promise<void> {
        if (!this.pcksAPK.version) await this.pcksVersion.getPCKSVersion();
    }

    private async getKioskMode(): Promise<void> {
        await this.kiosk.isKioskModeEnabled();
    }

    private getUnixTimestamp(): void {
        const unixTimeStamp = Date.now();
        this.updateDeviceProfile({'info.system.devtime': unixTimeStamp});
        this.updateDeviceProfile({'info.time': unixTimeStamp});
    }

    private getTimezone(): void {
        const timezone = DeviceProfileService.timezone();
        this.updateDeviceProfile({'info.system.tz': timezone});
    }

    // added so we can easily mock timezone for testing
    private static timezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    private async getUptime(): Promise<void> {
        const uptime = await this.uptime.getUptime(false);
        const parseUptime = parseInt(uptime);
        if (parseUptime) this.updateDeviceProfile({'info.system.uptime': parseUptime});
    }

    private getDeviceLocation(): void {
        if (this.watchingPosition) {
            return;
        }

        const options: GeolocationOptions = {
            enableHighAccuracy: true
        };

        this.watchingPosition = this.geolocation
            .watchPosition(options)
            .pipe(take(1)).
            subscribe((res: Geoposition) => {
                this.logger.phic.info('Successfully retrieved device position');
                if (res != null && res.coords != null) {
                    this.updateDeviceProfile({
                        'info.gps.longitude': res.coords.longitude,
                        'info.gps.latitude': res.coords.latitude,
                        'info.gps.time': res.timestamp
                    });
                } else {
                    this.logger.phic.error('Received undefined coordinates: ' + res.coords);
                }
                this.watchingPosition = null;
            }, (err: PositionError) => {
                this.logger.phic.error('Error retrieving device position', err);
                this.watchingPosition = null;
            });
    }

    // Acts a setter for _profileUpdates, updating it only when new key/value pairs are added or if one of the existing value changes.
    public updateDeviceProfile(deviceInfo: DeviceProfilePartial): void {
        const mergedDeviceProfile = {...this._profileUpdates, ...deviceInfo};
        if (JSON.stringify(mergedDeviceProfile) === JSON.stringify(this._profileUpdates)) {
            return;
        }
        this._profileUpdates = mergedDeviceProfile;
    }

    /**
     * Upload File Size is the byte size of data that has not been successfully sent.
     * If json data is passed as an argument, the method will calculate the byte size and update Firebase.
     * If there is no argument, we will update Firebase with an Upload File Size of 0,
     * which means that there isn't any data that failed to send.
     */
    public getUploadFileSize(data?: {[key: string]: any}): void {
        const fileSize = data ? new Blob([JSON.stringify(data)]).size : 0;
        this.updateDeviceProfile({'info.swnfo.data.upload': fileSize});
    }

    private async getBTDevices(): Promise<void> {
        await this.deviceService.getAllPairedValues();
        const pairedDevices = this.deviceService.allPairedDevices.map((device) => {
            return {
                'name': device.name,
                'addr': device.id
            };
        });
        this.updateDeviceProfile({'info.bt': pairedDevices});
    }

    private async submitDeviceProfile(): Promise<void> {
        const imei = await this.tabletDeviceIDService.getImei();
        const endpoint = 'v0/environments/HRS/device/' + imei;

        this.getUnixTimestamp();

        const stateDump = JSON.stringify(this._profileState, null, '\t');
        const updatesDump = JSON.stringify(this._profileUpdates, null, '\t');
        this.logger.info(`submitDeviceProfile()\n\nprofileState = ${stateDump}\n\nprofileUpdates = ${updatesDump}\n`);

        // Only submit keys with truthy values and values that have changed since the last request.
        Object.keys(this._profileUpdates)
            .forEach((key) => {
                const updatedProperty = this._profileUpdates[key];
                if (!updatedProperty || updatedProperty === this._profileState[key]) {
                    delete this._profileUpdates[key];
                }
            });

        this.gateway.put({endpoint: endpoint, responseType: 'text'}, this._profileUpdates).subscribe(
            {
                next: () => {
                    this.logger.debug('Successfully submitted device profile');
                    // Merge the submitted fields to Device Profile State.
                    this._profileState = {...this._profileState, ...this._profileUpdates};
                    // Clear the fields that have now been updated.
                    this._profileUpdates = {};
                },
                error: (e) => {
                    this.logger.phic.error('Failed to submit device profile', e);
                }
            }
        );
    }
}

