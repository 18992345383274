import {Injectable} from '@angular/core';
import {Network} from '@ionic-native/network/ngx';
import {getLogger} from '@hrs/logging';
import {BehaviorSubject, Observable, Subject, distinctUntilChanged, shareReplay, tap} from 'rxjs';
import {sleep} from 'src/app/utility/sleep';
import {isNumber} from 'lodash';
type CellState = any; // FIXME: remove when plugin is upgraded to latest
type SignalStrengthEvent = any; // FIXME: remove when plugin is upgraded to latest
const SignalStrengthEventType: any = {}; // FIXME: remove when plugin is upgraded to latest

declare global {
    interface Window {
        SignalStrength: any;
    }
}

interface LegacyCellInfo {
    dbm: number;
    level: number;
}

export interface SignalStrengthState {
    type: string; // one of the options from Network.Connection
    dbm: number;
    level: number;
    metadata?: any;
}

export enum SignalStrengthQuality {
    UNKNOWN = 0,
    POOR = 1,
    WEAK = 2,
    MODERATE = 3,
    GOOD = 4,
    EXCELLENT = 5,
    DISCONNECTED = -1
}

export function cellularStateToStrengthQuality(state: CellState): SignalStrengthQuality {
    if (!state || !state.cellDataLoaded) {
        return SignalStrengthQuality.UNKNOWN;
    }

    return state.level + 1; // plugin level is in range [0, 4]
}

@Injectable({
    providedIn: 'root'
})
export class SignalStrengthService {
    private readonly logger = getLogger('SignalStrengthService');
    private readonly eventsSubject = new Subject<SignalStrengthEvent>();
    private readonly cellularStrengthSubject = new BehaviorSubject<SignalStrengthQuality>(SignalStrengthQuality.UNKNOWN);

    public readonly events$: Observable<SignalStrengthEvent>;
    public readonly cellularStrength$: Observable<SignalStrengthQuality>;
    private mLastCellState: CellState | null = null;

    constructor(
        private readonly network: Network
    ) {
        // FIXME: uncomment when plugin is upgraded to latest
        // this.eventDelegateSuccessProxy = this.onSignalStrengthEvent.bind(this);
        // this.eventDelegateErrorProxy = this.onSignalStrengthDelegateError.bind(this);
        this.events$ = this.eventsSubject.asObservable();
        this.cellularStrength$ = this.cellularStrengthSubject.asObservable().pipe(
            shareReplay(),
            distinctUntilChanged(),
            tap((v) => this.logger.debug(`cellularStrength$ level updated -> ${v}`))
        );
    }

    public get lastCellState(): CellState | null {
        return this.mLastCellState;
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
    }

    /**
     * Manually emit a signal strength event from the JS layer.
     * Mostly used for spec testing, but left here as an optional bypass.
     */
    public emitSignalStrengthEvent(ev: SignalStrengthEvent): void {
        this.onSignalStrengthEvent(ev);
    }

    private onSignalStrengthEvent(ev: SignalStrengthEvent): void {
        this.eventsSubject.next(ev);

        switch (ev?.type) {
            case SignalStrengthEventType.CELL_STATE_UPDATED:
                this.mLastCellState = ev.data;
                this.cellularStrengthSubject.next(cellularStateToStrengthQuality(ev.data));
                break;
            default:
                break;
        }
    }

    private getLegacyCellInfo(): Promise<LegacyCellInfo> {
        return new Promise((resolve) => {
            window.SignalStrength.dbm(resolve);
        });
    }

    private async getLegacyCellInfoWithRetry(): Promise<LegacyCellInfo> {
        let info = await this.getLegacyCellInfo();
        if (!info || !info.dbm || info.dbm === -1) {
            await sleep(3000); // first load might not work, so try again in 3 seconds
            info = await this.getLegacyCellInfo();
        }
        return info;
    }

    /**
     * High level details for current network quality.
     * The "dbm" and "level" properties will be
     * relative to the type of network we are currently using.
     * (i.e. will be wifi data if on wifi, cellular data if on cellular)
     */
    public async getCurrentState(): Promise<SignalStrengthState> {
        this.logger.trace(`getInfo()`);
        const type = this.network.type;
        let dbm: number = -1;
        let level: number = 0;
        let metadata: any = null;

        this.logger.trace(`loading signal strength for connection type = ${type}`);

        // ---- Legacy API Begin (delete when plugin is upgraded)
        const info = await this.getLegacyCellInfoWithRetry();
        this.logger.trace(`signal strength plugin result = `, info);

        if (info) {
            if (isNumber(info.dbm)) {
                dbm = info.dbm;
            }
            if (isNumber(info.level)) {
                level = info.level;
            }
        }

        metadata = info;

        const result = {
            type,
            dbm,
            level,
            metadata
        };

        this.logger.trace(`getCurrentState() result`, result);
        return result;
    }
}
